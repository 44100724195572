import React from 'react';
import pt from 'prop-types';
import styles from './rowsActions.module.scss';
import { useUpdateConsentCategory } from '@ion/api';
import { MrIcon, MrText } from '@ion/components';
import { useConsentCategoriesTableContext } from '../../context/consentCategoriesTableContext';
import buildDisabledMessage from './build-disabled-message';
import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

export const RowsActions = ({ rowInfo }) => {
  const disabledMessage = buildDisabledMessage(rowInfo);
  const { onOpenModal } = useConsentCategoriesTableContext();
  const onEdit = () => {
    onOpenModal('editCategory', rowInfo);
  };
  const onDelete = () => {
    onOpenModal('deleteCategory', rowInfo);
  };
  const [, { isAllowed: updateConsentCategoryPermission }] = useUpdateConsentCategory();

  if (disabledMessage) {
    return (
      <div className={styles.row}>
        <MrText styleNames="tiny" component="span">
          {disabledMessage}
        </MrText>
      </div>
    );
  }

  if (!updateConsentCategoryPermission) {
    return <div className={styles.row} />;
  }

  return (
    <FeatureGate flagName={FEATURE_FLAGS.ecmConfigurableConsent}>
      <div className={styles.row}>
        <div className={styles.tableIcon} onClick={onEdit}>
          <MrIcon id="icon-pencil" testId="editCategory" />
        </div>
        <div className={styles.tableIcon} onClick={onDelete}>
          <MrIcon id="icon-trash" testId="deleteCategory" />
        </div>
      </div>
    </FeatureGate>
  );
};
RowsActions.propTypes = {
  rowInfo: pt.object.isRequired,
};
