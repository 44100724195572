import { useMemo } from 'react';
import buildPipelineJson from './buildPipelineJson';

import { ERRORS } from './ERRORS';

export default function usePipelineJson({
  pipelineIntegrations,
  author,
  writekey,
  pipelineFilter,
  pipelineTransform,
  setErrorMsg,
}) {
  return useMemo(() => {
    try {
      return buildPipelineJson(pipelineIntegrations, author, writekey, pipelineFilter, pipelineTransform);
    } catch (error) {
      setErrorMsg({
        title: ERRORS.DIFF_ERROR,
        message: error.toString(),
      });
      return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineIntegrations, author, writekey, pipelineFilter, pipelineTransform]);
}
