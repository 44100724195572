import React from 'react';

import { routes } from 'app/constants';
import AppHeader from 'app/components/app-header';
import { usePipelinePageContext } from './PipelinePageContextProvider';

export default function PipelineDetailsPageHeader() {
  const {
    pipeline: { name },
    loading,
  } = usePipelinePageContext();

  const appHeaderProps = {
    icon: 'pipelines',
    title: 'Pipelines',
    route: routes.pipelines,
    ...(!loading && { subTitle: name }),
  };

  return <AppHeader {...appHeaderProps} />;
}
