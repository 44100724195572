import PropTypes from 'prop-types';

import { useDeletePipeline, useRemovePipeline, useRemovePipelineIntegrations } from '@ion/api';
import { useModalContext, MODAL_ACTIONS } from '@ion/app/src/pipelines/ModalContext';

export default function useConfirmDeleteModal(pipelineDeprecated) {
  const {
    errorMsg,
    setErrorMsg,
    modalState: { pipeline, activeModal },
    closeModal,
  } = useModalContext();
  const pipelineToDelete = pipelineDeprecated || pipeline;
  const [removePipeline] = useRemovePipeline();
  const [removePipelineIntegrations] = useRemovePipelineIntegrations();
  const [deletePipeline] = useDeletePipeline({
    onCompleted: () => {
      removePipelineIntegrations(pipelineToDelete.pipelineIntegrations);
      removePipeline({ pipelineId: pipelineToDelete.id, name: pipelineToDelete.name });
      closeModal();
    },
    onError: err => {
      setErrorMsg(err?.errorMsg);
    },
  });
  const showDeleteModal = !!(pipelineToDelete && activeModal === MODAL_ACTIONS.DELETE_PIPELINE);
  const onSubmit = () => {
    deletePipeline({ clusterId: pipelineToDelete.cluster.id, writekey: pipelineToDelete.writekey });
  };

  return {
    pipeline: pipelineToDelete,
    showDeleteModal,
    errorMsg,
    setErrorMsg,
    onClose: closeModal,
    onSubmit,
  };
}

useConfirmDeleteModal.propTypes = {
  setErrorMsg: PropTypes.func,
};
