import { useEffect } from 'react';
import { useFormContext } from '@metarouter-private/mr-mui';

import { useLazyQuerySchemaVersion } from '@ion/api';

export default function useUpdateSchemaFieldValue(versionId) {
  const [getSchemaVersion, { schemaVersion, loading }] = useLazyQuerySchemaVersion();
  const { resetField } = useFormContext();

  useEffect(() => {
    if (versionId) {
      getSchemaVersion(versionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId]);

  useEffect(() => {
    if (schemaVersion) {
      resetField('jsonSchema', { defaultValue: schemaVersion.jsonSchema });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaVersion]);

  return loading;
}
