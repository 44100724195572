import { usePipelinesTableContext } from 'app/pipelines/PipelinesTable/PipelinesTableContext';

export default function usePipelinesTable() {
  const { setActivePage, pipelines, pagination, loading, resetPagination, perPage, onRowClicked, setSort } =
    usePipelinesTableContext();

  const onSort = ({ sortField }, sortDirection) => {
    setSort({ field: sortField, direction: sortDirection });
  };

  return {
    data: pipelines,
    pagination,
    onSort,
    loading,
    perPage,
    pointerOnHover: true,
    highlightOnHover: true,
    paginationDefaultPage: 1,
    paginationResetDefaultPage: resetPagination,
    onFetchPage: setActivePage,
    onRowClicked,
  };
}
