import { useState, useEffect } from 'react';

import PIPELINE_STATUSES from './PIPELINE_STATUSES';
import getPipelineStatus from './getPipelineStatus';

export default function useStatus({ pipeline, deployedPipeline, deployedRevisions }) {
  const [status, setStatus] = useState(PIPELINE_STATUSES.NotDeployed);

  useEffect(() => {
    if (pipeline) {
      setStatus(
        getPipelineStatus({
          pipeline,
          deployedPipeline,
          deployedRevisions,
        })
      );
    }
  }, [pipeline, deployedPipeline, deployedRevisions]);

  return status;
}
