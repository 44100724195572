import React from 'react';
import PropTypes from 'prop-types';

import GatedCodeEditor from 'app/components/GatedCodeEditor';

import useYamlCodeEditorProps from './useYamlCodeEditorProps';

export default function YamlCodeEditor(props) {
  const yamlCodeEditorProps = useYamlCodeEditorProps(props);
  return <GatedCodeEditor {...yamlCodeEditorProps} />;
}

YamlCodeEditor.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  customErrorFn: PropTypes.func,
};
