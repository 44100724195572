import React from 'react';
import { Box, Typography } from '@metarouter-private/mr-mui';
import { useEventSchemaViolationsPageContext } from './EventSchemaViolationsPageContextProvider';

export default function EventSchemaViolationsHeader() {
  const { eventName } = useEventSchemaViolationsPageContext();
  return (
    <Box padding={2}>
      <Typography variant="h4">{`${eventName} Violations`}</Typography>
    </Box>
  );
}
