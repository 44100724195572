import React from 'react';
import PropTypes from 'prop-types';

import { cellActions, determineCellAction } from './cell-actions/determine-cell-action';

import RemoveStagedRevision from './cell-actions/RemoveStagedRevision';
import StageForDelete from './cell-actions/StageForDelete';
import UndoStagedForDelete from './cell-actions/UndoStagedForDelete';
import UnmanagedWarning from './cell-actions/UnmanagedWarning';

const ActionCell = row => {
  const cellAction = determineCellAction(row);

  switch (cellAction) {
    case cellActions.STAGE_FOR_DELETE:
      return <StageForDelete {...row} />;
    case cellActions.REMOVE_STAGED_REVISION:
      return <RemoveStagedRevision {...row} />;
    case cellActions.UNDO_STAGED_FOR_DELETE:
      return <UndoStagedForDelete {...row} />;
    case cellActions.UNMANAGED_WARNING:
      return <UnmanagedWarning />;
    default:
      throw new Error(`Unknown delete cell option: ${cellAction}`);
  }
};

ActionCell.propTypes = {
  row: PropTypes.object,
};

export default ActionCell;
