import React from 'react';
import PropTypes from 'prop-types';
import { FormTextField, FormFieldContext, Stack } from '@metarouter-private/mr-mui';

import EventNameField from 'app/components/form/EventNameField';

import useValidateUniqueName from './useValidateUniqueName';

export default function EventForm({ name, description }) {
  const validateEventName = useValidateUniqueName(name);

  return (
    <Stack spacing={2}>
      <EventNameField name="name" customValidateFn={validateEventName} defaultValue={name} />

      <FormFieldContext name="description" label="Description" defaultValue={description}>
        <FormTextField multiline />
      </FormFieldContext>
    </Stack>
  );
}

EventForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};
