import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import { useHistory } from 'react-router-dom';

import { MrCard, generateId, useForm } from '@ion/components';
import { useGetPipeline, parseDeployedIntegrations } from '@ion/api';

import { FormProvider } from 'app/context/form-context';
import DeployModal from 'app/pipeline-details/DeployModal';
import getDeployStatus from 'app/pipeline-details/PipelineIntegrationsTable/DeployChangesButton/getDeployStatus';

import PipelineCardHeader from './header';
import { usePipelineCardContext } from './context/pipeline-card-context';
import BottomSection from './BottomSection';

import s from './index.module.scss';

const PipelineCard = ({ pipeline, cluster, author, integrationsData }) => {
  const { setPipeline } = usePipelineCardContext();
  const [fetchPipeline] = useGetPipeline();

  const { writekey, name, id } = pipeline;
  const { location } = useHistory();
  const formId = generateId();
  const { submitForm, formState, setField } = useForm(formId);
  const [lastDeployedDate, setLastDeployedDate] = useState();
  const [pipelineFilter, setPipelineFilter] = useState();
  const [pipelineTransform, setPipelineTransform] = useState();
  const getDeployedIntegrationRevisions = () => {
    fetchPipeline({
      clusterId: cluster.id,
      writekey,
    })
      .then(r => {
        if (r.errors || !r.pipeline) return;
        setLastDeployedDate(r.pipeline?.createdAt);
        setPipelineFilter(r.pipeline?.filter);
        setPipelineTransform(r.pipeline?.transform);
        setDeployedRevisions(
          parseDeployedIntegrations({
            deployedIntegrations: r.pipeline.integrations,
            pipelineIntegrations: pipeline.pipelineIntegrations,
            integrationsData,
          })
        );
      })
      .catch(err => {
        if (err.toString().includes('action proxy error: control api status: 404')) {
          return [];
        } else console.log('err', err);
      });
  };

  // handle an expanded card via location state
  useEffect(() => {
    if (location.state && pipeline.id === location.state.expandedPipelineId) {
      setIsCardOpen(true);
    }
    setPipeline(pipeline);
  }, [location.state, location.state?.expandedPipelineId, pipeline, setPipeline]);

  const [isCardOpen, setIsCardOpen] = useState();
  const [deployedRevisions, setDeployedRevisions] = useState([]);
  const [deployedRevisionsLoaded, setDeployedRevisionsLoaded] = useState(false);
  const [loadingDiff, setLoadingDiff] = useState(false);
  const [deployModalOpen, setDeployModalOpen] = useState(false);
  const [pipelineVariablesModalOpen, setPipelineVariablesModalOpen] = useState(false);

  if (integrationsData.length !== 0 && deployedRevisions.length === 0 && !deployedRevisionsLoaded) {
    getDeployedIntegrationRevisions();
    setDeployedRevisionsLoaded(true);
  }

  const refetch = () => {
    getDeployedIntegrationRevisions();
  };

  const deployStatus = getDeployStatus({
    cluster,
    deployedRevisions,
    pipelineIntegrations: pipeline.pipelineIntegrations,
    loadingDiff,
  });

  const openPipelineDiffModal = () => {
    setLoadingDiff(true);
    setDeployModalOpen(true);
  };

  return (
    <>
      <FormProvider value={{ formState, setField, submitForm }}>
        <MrCard
          closeBottom={!isCardOpen}
          className={s.card}
          top={
            <PipelineCardHeader
              writekey={writekey}
              pipeline={pipeline}
              name={name}
              isOpen={isCardOpen}
              changeOpen={setIsCardOpen}
              lastDeployedDate={lastDeployedDate}
              deployStatus={deployStatus}
              cluster={cluster}
              pipelineVariablesModalOpen={pipelineVariablesModalOpen}
              setPipelineVariablesModalOpen={setPipelineVariablesModalOpen}
              setDeployedRevisions={setDeployedRevisions}
              openPipelineDiffModal={openPipelineDiffModal}
            />
          }
          bottom={
            <BottomSection deployedRevisions={deployedRevisions} pipelineIntegrations={pipeline.pipelineIntegrations} />
          }
        ></MrCard>
      </FormProvider>

      <DeployModal
        deployModalOpen={deployModalOpen}
        setDeployModalOpen={setDeployModalOpen}
        clusterId={cluster.id}
        writekey={writekey}
        pipelineId={id}
        pipelineName={name}
        author={author}
        pipelineFilter={pipelineFilter}
        pipelineTransform={pipelineTransform}
        setLoadingDiff={setLoadingDiff}
        deployedRevisions={deployedRevisions}
        pipelineIntegrations={pipeline.pipelineIntegrations}
        refetch={refetch}
      />
    </>
  );
};

PipelineCard.propTypes = {
  pipeline: pt.object.isRequired,
  cluster: pt.object,
  author: pt.string,
  integrationsData: pt.array,
};

export default PipelineCard;
