import { useUpdatePipeline } from '@ion/api';

import { useModalContext, MODAL_ACTIONS } from 'app/pipelines/ModalContext';

export default function useEditPipelineNameModal() {
  const {
    modalState: { pipeline, activeModal },
    closeModal,
  } = useModalContext();
  const [updatePipeline] = useUpdatePipeline({ onCompleted: closeModal });
  const showEditModal = !!(pipeline && activeModal === MODAL_ACTIONS.EDIT_PIPELINE_NAME);

  const onSubmit = ({ name }) => {
    updatePipeline({
      pipelineId: pipeline.id,
      name,
    });
  };

  return {
    name: pipeline?.name || '',
    showEditModal,
    onClose: closeModal,
    onSubmit,
  };
}
