import React from 'react';
import formatIfNan from './format-if-nan';
import PipelineCell from '../cells/pipeline';
import { nameColumnProperties, dataColumnProperties, defaultProperties } from './apply-column-defaults';
import DROP_REASONS from '../../drop-reasons';
import { deliveryColumns, filterColumns } from './common-columns';

export default function buildPipelineColumns({ showPipelineFilters }) {
  return [
    {
      name: 'Pipeline Name',
      id: 'name',
      selector: row => row.Name,
      cell: row => <PipelineCell name={row.Name} />,
      ...nameColumnProperties,
      ...defaultProperties,
    },
    ...filterColumns,
    {
      name: 'Pipeline Filter',
      id: DROP_REASONS.PIPELINE_LUA_FILTER,
      selector: row => row[DROP_REASONS.PIPELINE_LUA_FILTER],
      format: row => formatIfNan(row, DROP_REASONS.PIPELINE_LUA_FILTER),
      omit: !showPipelineFilters,
      ...dataColumnProperties,
      ...defaultProperties,
    },
    ...deliveryColumns,
  ];
}
