import actions from 'api/audit-logs/actions';
import { generateTimestamp } from 'api/audit-logs/generate-timestamp';

export function buildAuditLog({ id, name, description: schemaLibraryDescription }) {
  const action = actions.schema_library_created;
  const description = name;
  const debug_data = {
    id,
    name,
    description: schemaLibraryDescription,
  };
  const timestamp = generateTimestamp();

  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
