import actions from 'api/audit-logs/actions';
import { generateTimestamp } from 'api/audit-logs/generate-timestamp';

export function buildAuditLog({ writeKey }) {
  const action = actions.ajs_file_uploaded;
  const description = `File uploaded for writeKey: ${writeKey}`;
  const debug_data = {
    writeKey,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
