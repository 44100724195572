import React from 'react';
import PropTypes from 'prop-types';
import { AvatarGroup } from '@metarouter-private/mr-mui';
import IntegrationAvatar from './IntegrationAvatar';

export default function IntegrationAvatarGroup({ ids, max = 4, size = 'small' }) {
  return (
    <AvatarGroup max={max} size={size}>
      {ids.map(id => (
        <IntegrationAvatar key={id} id={id} />
      ))}
    </AvatarGroup>
  );
}

IntegrationAvatarGroup.propTypes = {
  ids: PropTypes.array,
  max: PropTypes.number,
  size: PropTypes.string,
};
