import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext, Grid, Typography, Switch } from '@metarouter-private/mr-mui';

import FormCodeEditor from 'app/components/form/FormCodeEditor';

import useUpdateSchemaFieldValue from './useUpdateSchemaFieldValue';
import useSchemaValidator from './useSchemaValidator';

const SCHEMA_FIELD_BASE_PROPS = {
  label: 'JSON Schema',
  name: 'jsonSchema',
  required: true,
  defaultValue: '// Use JSON Schema json-schema.org' + '\n'.repeat(20 - 1),
};

export default function SchemaField({ versionId }) {
  const loading = useUpdateSchemaFieldValue(versionId);
  const { validateSchema, onModeChange } = useSchemaValidator();

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Typography>Strict Mode</Typography>
        <Switch name="strictMode" onChange={onModeChange} />
      </Grid>

      <FormFieldContext required rules={{ validate: validateSchema }} {...SCHEMA_FIELD_BASE_PROPS}>
        <FormCodeEditor lang="json" disabled={loading} />
      </FormFieldContext>
    </>
  );
}

SchemaField.propTypes = {
  versionId: PropTypes.string,
};
