import React from 'react';

import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import RedirectFeatureGate from 'app/launch-darkly/gates/redirect-feature-gate';
import { PipelineCardContextProvider } from 'app/pipelines-deprecated/card/context/pipeline-card-context';

import PipelinePageContextProvider from './PipelinePageContextProvider';
import PipelineDetailsContent from './PipelineDetailsContent';

export default function PipelineDetailsPage() {
  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.pipelinesListRevamp}>
      <PipelinePageContextProvider>
        <PipelineCardContextProvider>
          <PipelineDetailsContent />
        </PipelineCardContextProvider>
      </PipelinePageContextProvider>
    </RedirectFeatureGate>
  );
}
