import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import EditEventSchemasButton from './EditEventSchemasButton';
import LibraryActionsKebabMenu from './LibraryActionsKebabMenu';

const STACK_PROPS = {
  alignItems: 'center',
  direction: 'row',
};

export default function ActionsCell({ row }) {
  return (
    <Stack {...STACK_PROPS}>
      <EditEventSchemasButton row={row} />
      <LibraryActionsKebabMenu row={row} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    assignmentsCount: PropTypes.number,
  }).isRequired,
};
