import * as logos from 'app/components/integrations/logos';

const hyphenatedLogos = {
  // These logos need hyphens to match the starterkitId exactly
  'acuity-ads': logos.acuityAdsCopy,
  'adobe-analytics': logos.adobeAnalytics,
  'adobe-id': logos.adobeId,
  'amazon-data-firehose': logos.amazonDataFirehose,
  'amazon-redshift-v2': logos.amazonRedshiftV2,
  'apps-flyer-android': logos.appsFlyerAndroid,
  'apps-flyer-ios': logos.appsFlyerIOS,
  'bing-ads': logos.bingAds,
  'build-your-own': logos.buildYourOwn,
  'commission-junction': logos.commissionJunction2,
  'customer-io': logos.customerioCopy,
  'facebook-pixel': logos.facebookPixel,
  'google-ad-words': logos.googleAdWords,
  'google-ads-audience': logos.googleAdsAudience,
  'google-ads': logos.googleAds2,
  'google-analytics-4-collect': logos.googleAnalytics4Collect,
  'google-analytics-4': logos.googleAnalytics4,
  'google-analytics': logos.googleAnalytics,
  'google-bigquery-streaming': logos.googleBigqueryStreaming,
  'google-cm360': logos.googleCM360,
  'google-firebase': logos.googleFirebase,
  'google-pubsub': logos.googlePubSub,
  'google-tag-manager': logos.googleTagManager,
  'hubspot-event': logos.hubspotEvent,
  'impact-radius': logos.impactRadius,
  'in-market': logos.inMarketCopy,
  'magellan-ai': logos.magellanAi,
  'media-math': logos.mediaMathCopy,
  'microsoft-ads': logos.microsoftAdsCopy,
  'miq-xandr': logos.miqXandr,
  'moloco-commerce-media': logos.molocoCommerceMedia,
  'movable-ink-customer-data': logos.movableInkCustomerDataCopy,
  'movable-ink': logos.movableInkCopy,
  'nextdoor-capi': logos.nextdoorCapi,
  'pinterest-capi': logos.pinterestCapi,
  'reddit-ads': logos.redditAdsCopy,
  'rev-jet': logos.revJet,
  'rtb-house': logos.rtbHouseCopy,
  'salesforce-cdp': logos.salesforceCdp,
  'salesforce-marketing': logos.salesforceMarketing,
  'snapchat-capi': logos.snapchatCapi,
  'thetradedesk-conversion-events': logos.thetradedeskConversionEvents,
  'thetradedesk-data-advertiser-firstparty': logos.thetradedeskDataAdvertiserFirstparty,
  'trade-desk': logos.tradeDesk,
  'treasure-data': logos.treasureData,
  'twitter-conversions': logos.twitterConversions,
  'verizon-media': logos.verizonMedia,
  'x-ads': logos.xAdsCopy,
  'yahoo-enhanced-attribution': logos.yahooEnhancedAttribution,
  'yahoo-offline-conversion': logos.yahooOfflineConversion,
};

const INTEGRATION_AVATAR_LOGOS = {
  ...logos,
  ...hyphenatedLogos,
};

export default INTEGRATION_AVATAR_LOGOS;
