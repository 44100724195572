import React from 'react';

import { SearchBar } from '@metarouter-private/mr-mui';

import usePipelineSearchBarOnChange from './usePipelineSearchBarOnChange';

export default function PipelineSearchBar() {
  const onChange = usePipelineSearchBarOnChange();

  return <SearchBar placeholder="Filter by Name or Writekey..." size="small" onChange={onChange} />;
}
