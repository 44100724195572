import { validate } from 'parameter-validator';

import mergeQueries from 'api/Apollo/merge-queries';
import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import { GET_EVENT_NAMES } from 'api/event-names/event-names-query-builder';
import { GET_ALL_EVENT_NAMES } from 'api/event-names/all-event-names-query-builder';

import {
  buildVariables as buildAuditLogVariables,
  eventNameDeletedMutation,
} from 'api/audit-logs/event-name-deleted/audit-log-mutation-builder';

import {
  removeEventNameMutation,
  buildVariables as buildRemoveEventNameVariables,
} from './remove-event-name-mutation-builder';

import useTrackWithUser from 'api/hooks/useTrackWithUser';

export const combinedMutation = mergeQueries('RemoveEventNameAndAudit', [
  removeEventNameMutation,
  eventNameDeletedMutation,
]);

export default function useRemoveEventName({ ...options } = {}) {
  const [trackWithUser] = useTrackWithUser('event_deleted');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Event Removed.',
    context: { permission: permissions.deleteEventNames },
    refetchQueries: [{ query: GET_ALL_EVENT_NAMES }, GET_EVENT_NAMES],
    ...options,
  });

  const removeEventName = args => {
    const { id, name, description } = validate(args, ['id', 'name', 'description']);
    const variables = buildVariables({ id, name, description });
    return executeMutation(variables).then(response => {
      if (!response.errors) {
        trackWithUser({ id, name, description });
      }
      return response;
    });
  };

  return [removeEventName, response];
}

function buildVariables({ id, ...rest }) {
  return {
    variables: {
      ...buildRemoveEventNameVariables({ id }),
      ...buildAuditLogVariables({ id, ...rest }),
    },
  };
}
