import React from 'react';
import PropTypes from 'prop-types';

import PipelineStatusChip from 'app/shared/components/pipelines/PipelineStatusChip';

export default function StatusChip({ status }) {
  return <PipelineStatusChip data-test-id="pipelineStatus" status={status} />;
}

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};
