import pt from 'prop-types';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import { formValidators } from '@ion/components';
import { MrH, MrInputText } from '@ion/components';
import FeatureGate from '@ion/app/src/launch-darkly/gates/feature-gate';

import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

import styles from './index.module.scss';
import { GatedConsentCategoriesSelect } from '../consent-categories-select/GatedConsentCategoriesSelect';

const { validateCsvUrls, validateRequired } = formValidators;

const AnalyticsBuilderCrossDomain = ({ formState, setField, validateField, resetError }) => {
  const consentCategorySelectName = `sync-crossDomain-consentCategoryId`;
  const isCrossDomainValueEmpty = formState.crossDomain?.value === '';
  useEffect(() => {
    if (isCrossDomainValueEmpty) {
      setField({
        target: {
          name: 'sync-crossDomain-consentCategoryId',
          value: '',
        },
      });
    }
  }, [setField, isCrossDomainValueEmpty]);
  return (
    <FeatureGate flagName="ecmAjsCrossDomainIdentitySync">
      <section id="section" className={styles.section}>
        <MrH h="h5" sans styleNames="noMargin">
          <div className={styles.circle}>4</div> Cross-Domain Identity Sync
        </MrH>
        <p className={styles.headerHelperText}>
          Add the domains that you would like to include your cross-domain identity sync. This will maintain a common
          user identifier (identifier key) among all of the domains you have listed here and that are also configured to
          use cross-domain identity syncing.
        </p>
        <div className={styles.subSection}>
          <MrInputText
            onChange={setField}
            value={formState.crossDomain?.value}
            placeholder={'https://ingest.domain-1.com, https://ingest.domain-2.com'}
            onBlur={validateField}
            onInput={resetError}
            name="crossDomain"
            errorMsg={formState.crossDomain?.errorMsg}
            helperText='Separate domains with a comma (,) and include "https://"'
            validators={[validateCsvUrls()]}
            fullWidth
          />
        </div>
        <FeatureGate flagName={FEATURE_FLAGS.configurableConsentMvp}>
          <div className={classNames([styles.subSection, styles.marginTop])}>
            <MrH h="h5" styleNames="noMargin" sans>
              Consent Type
            </MrH>
            <div className={styles.marginTop}>
              <GatedConsentCategoriesSelect
                errorMsg={formState[consentCategorySelectName]?.errorMsg}
                onChange={setField}
                value={formState[consentCategorySelectName]?.value}
                name={consentCategorySelectName}
                validators={isCrossDomainValueEmpty ? [] : [validateRequired()]}
                onBlur={validateField}
                onInput={resetError}
                disabled={isCrossDomainValueEmpty}
              />
            </div>
          </div>
        </FeatureGate>
      </section>
    </FeatureGate>
  );
};

AnalyticsBuilderCrossDomain.propTypes = {
  formState: pt.object,
  setField: pt.func,
  validateField: pt.func,
  resetError: pt.func,
};

export default AnalyticsBuilderCrossDomain;
