import { gql } from '@apollo/client';
import eventName from 'api/event-names/eventName';

export const ALL_EVENT_NAMES_QUERY = 'AllEventNames';

export const GET_ALL_EVENT_NAMES = gql`
    query ${ALL_EVENT_NAMES_QUERY} {
        eventNames: event_names {
            ...eventName
        }
    }
    ${eventName}  
`;
