import React from 'react';
import propTypes from 'prop-types';

import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

import { ConsentCategorySelect } from './ConsentCategorySelect';
import { ConsentCategoryDisabledMessage } from './ConsentCategoryDisabledMessage';

export const GatedConsentCategoriesSelect = ({ onChange, ...props }) => {
  return (
    <FeatureGate flagName={FEATURE_FLAGS.configurableConsentMvp}>
      <FeatureGate flagName={FEATURE_FLAGS.ecmConfigurableConsent} fallback={<ConsentCategoryDisabledMessage />}>
        <ConsentCategorySelect onChange={onChange} {...props} />
      </FeatureGate>
    </FeatureGate>
  );
};

GatedConsentCategoriesSelect.propTypes = {
  onChange: propTypes.func.isRequired,
};
