// audit-log-mutation-builder for schema-library-created

import auditLogMutation from 'api/audit-logs/insert-audit-log-mutation';

import { buildAuditLog } from './audit-log-builder';

export const mutation = auditLogMutation;

export function buildVariables({ id, name, description }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      id,
      name,
      description,
    }),
  };
}
