import React from 'react';
import PropTypes from 'prop-types';

import { ReactDataTableTitle } from '@metarouter-private/mr-mui';

export default function StatusTableTitle({ writekey = '' }) {
  return <ReactDataTableTitle label={writekey} noWrap />;
}

StatusTableTitle.propTypes = {
  writekey: PropTypes.string,
};
