import { map } from 'lodash';
import { useApolloClient } from '@apollo/client';

import permissions from 'api/Apollo/permissions';

import { GET_SCHEMA_NAMES } from './schema-names-query-builder';

export default function useFetchSchemaNames({ ...options } = {}) {
  const client = useApolloClient();

  return async () => {
    const response = await client.query({
      ...options,
      context: { permission: permissions.readSchemas },
      query: GET_SCHEMA_NAMES,
      fetchPolicy: 'cache-first',
    });

    return transformResponse(response);
  };
}

function transformResponse(response) {
  return {
    ...response,
    data: map(response.data.schemas, 'name'),
  };
}
