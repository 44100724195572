export const DEPLOY_STATE = {
  LOADING: 'Loading...',
  DEPLOY: 'Deploy Pipeline',
  DEPLOYED: 'Deployed',
  RESOLVE: 'Add Missing Variables',
};

export const DEPLOY_STATE_TOOLTIP = {
  LOADING: 'Waiting for pipeline diff response',
  DEPLOY: 'Ready to deploy',
  DEPLOYED: 'Deployed',
  RESOLVE: 'Missing required variables, click to resolve',
  NOT_ASSIGNED_TO_CLUSTER: 'This pipeline is not assigned to a cluster',
  STAGED_FOR_DELETE: 'Deployed integrations staged for deletion',
  NO_STAGED_CHANGES: 'No staged changes to deploy',
  NO_CHANGES: 'No changes from previous deployment',
};

export const DEPLOY_ACTIONS = {
  // This is only for the old pipeline card.
  // It prevents diffing if there are missing pipeline variables.
  // If all the users use new pipeline table, we can remove this case.
  OPEN_PIPELINE_VARIABLES_MODAL: 'resolve',
  // It shows confirm modal to clear pipeline integrations data
  OPEN_CONFIRM_DELETE_PIPELINE_DATA_MODAL: 'delete',
  OPEN_PIPELINE_DIFF_MODAL: 'diff',
};
