import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { MrH } from '@ion/components';
import { useStageRevisionForDelete } from '@ion/api';

import ConfirmDelete from 'app/components/ConfirmDelete';

import styles from './index.module.scss';
import { globalModalStore } from '@ion/global';

const UNSTAGE_DEPLOYED_INTEGRATION_ERROR = 'Error Unstaging Deployed Integration';

export default function ConfirmUnstageDeployedIntegration({ closeModal, integration, stagedRevisionId }) {
  const [stageRevisionForDelete] = useStageRevisionForDelete({
    onError: err => {
      globalModalStore.addNotification({
        title: UNSTAGE_DEPLOYED_INTEGRATION_ERROR,
        message: err?.errorMsg.message,
      });
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stageRevisionForDeleteCallback = useCallback(stageRevisionForDelete, []);

  const deleteFn = () => {
    stageRevisionForDeleteCallback({
      id: stagedRevisionId,
      stagedForDelete: true,
    });
  };

  return (
    <>
      <ConfirmDelete deleteFn={deleteFn} closeModal={closeModal}>
        <div className={styles.header}>
          <MrH h="h2" styleNames="sans">
            Unstage Deployed Integration
          </MrH>
        </div>
        <div className={styles.content}>
          <p>
            Stage <strong>{integration.name}</strong> for deletion? This change will not take effect until the pipeline
            is deployed.
          </p>
        </div>
      </ConfirmDelete>
    </>
  );
}

ConfirmUnstageDeployedIntegration.propTypes = {
  closeModal: PropTypes.func,
  integration: PropTypes.object,
  stagedRevisionId: PropTypes.string,
};
