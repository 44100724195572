import actions from 'api/audit-logs/actions';
import { generateTimestamp } from 'api/audit-logs/generate-timestamp';

export function buildAuditLog({ eventNameId, name, eventNameDescription }) {
  const action = actions.event_name_updated;
  const description = name;
  const debug_data = {
    eventNameId,
    name,
    description: eventNameDescription,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
