import React from 'react';
import propTypes from 'prop-types';

import { MrFormLabel } from '@ion/components';

import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import { GatedConsentCategoriesSelect } from 'app/components/consent-categories-select/GatedConsentCategoriesSelect';
import FeatureGate from 'app/launch-darkly/gates/feature-gate';

import buildConsentCategorySelectProps from 'app/pipeline-details/PipelineIntegrationsTable/pipeline-integration-form/buildConsentCategorySelectProps';

export const ConsentCategorySelect = ({ formState, setField }) => {
  const consentCategorySelectProps = buildConsentCategorySelectProps(formState, setField);

  return (
    <FeatureGate flagName={FEATURE_FLAGS.configurableConsentMvp}>
      <div className="content">
        <MrFormLabel label="Select Consent Category" />
        <GatedConsentCategoriesSelect {...consentCategorySelectProps} />
      </div>
    </FeatureGate>
  );
};

ConsentCategorySelect.propTypes = {
  formState: propTypes.object.isRequired,
  setField: propTypes.func.isRequired,
};
