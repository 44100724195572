const NO_VIOLATIONS_CHIP_COLOR = 'success';
const HAS_VIOLATIONS_CHIP_COLOR = 'warning';

export default function buildViolationsCount({ violations, error }) {
  if (error) {
    return { violationsCount: null };
  }

  const violationsCount = Number(violations.reduce((acc, { count }) => acc + Number(count), 0));
  const chipColor = violationsCount === 0 ? NO_VIOLATIONS_CHIP_COLOR : HAS_VIOLATIONS_CHIP_COLOR;

  return { violationsCount, chipColor };
}
