import { useCallback } from 'react';

import { useGetPipelineSchemaViolations } from 'api/control/useControlProxy';
import useQueryPipelineSchemaLibrary from 'api/pipelines/schema-library/useQueryPipelineSchemaLibrary';

const earliestSeen = new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();

export default function useQuerySchemaEnforcement({ pipelineId, clusterId, writekey, ...options }) {
  const [getPipelineSchemaViolations, { data: violations, loading: violationsLoading }] =
    useGetPipelineSchemaViolations();

  const getPipelineSchemaViolationsCallback = useCallback(
    ({ pipeline: { schemaLibrary } }) => {
      if (schemaLibrary && clusterId && writekey) {
        getPipelineSchemaViolations({
          clusterId,
          writekey,
          earliestSeen,
        });
      }
    },
    [clusterId, writekey, getPipelineSchemaViolations]
  );

  const { data, loading: pipelineSchemaLibraryLoading } = useQueryPipelineSchemaLibrary({
    pipelineId,
    ...options,
    onCompleted: getPipelineSchemaViolationsCallback,
  });

  return {
    pipelineSchemaLibrary: data,
    violationsCount: violations[0]?.count || 0,
    loading: violationsLoading || pipelineSchemaLibraryLoading,
  };
}
