import isEmpty from 'lodash/isEmpty';

export default function getDeployedStagedRevision({ deployedIntegration }) {
  if (isEmpty(deployedIntegration)) {
    return {};
  }

  const {
    revision: { id },
    transformStages,
    connection,
    endpointSchema,
  } = deployedIntegration;
  const { playbook } = transformStages[0].data;
  const { version: revision } = playbook;

  return {
    id,
    revision,
    playbook: {
      connectionJson: {
        ...(endpointSchema
          ? {
              endpointSchema: endpointSchema,
            }
          : {
              connectionTemplate: connection,
            }),
      },
      playbookJson: playbook,
    },
  };
}
