import { useGetViolationEvents } from '@ion/api';
import { useEventSchemaViolationsPageContext } from 'app/event-schema-violations/EventSchemaViolationsPage/EventSchemaViolationsPageContextProvider';
import { useMutationAsQuery } from '@ion/api';
import { useDrawerContext } from 'app/shared/contexts/drawer';

export default function useViolationEvents() {
  const {
    pipeline: { writekey, clusterId },
    eventName,
    schemaId,
    earliestSeen,
  } = useEventSchemaViolationsPageContext();
  const {
    drawer: { data: queryParams },
  } = useDrawerContext();

  const args = [
    clusterId,
    earliestSeen,
    writekey,
    eventName,
    queryParams.type,
    queryParams.field,
    queryParams.details,
    schemaId,
  ];

  const { loading, data: violationEvents } = useMutationAsQuery({ mutation: useGetViolationEvents, args });

  return {
    loading,
    violationEvents,
  };
}
