import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../../../launch-darkly/is-feature-enabled';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

// TODO: ND: Refactor directory structure so this has somewhere to live and refactor to reuse.
export default function useHasConsentModule() {
  const featureFlags = useFlags();
  return isFeatureEnabled({
    featureFlags,
    flagName: FEATURE_FLAGS.ecmConfigurableConsent,
  });
}
