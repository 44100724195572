import determinePipelineIntegrationStatus from './determinePipelineIntegrationStatus';
import mergePipelineVariables from './mergePipelineVariables';
import hasAnyChanges from './hasAnyChanges';
import mergeConsentCategories from './mergeConsentCategories';
import isPipelineVariablesChanged from './isPipelineVariablesChanged';
import { PIPELINE_INTEGRATION_TYPES } from './PIPELINE_INTEGRATION_TYPES';
import mergeStagedRevision from './mergeStagedRevision';

export default function mergePipelineIntegrations({ pipelineIntegrations, deployedIntegrations }) {
  return pipelineIntegrations.map(pipelineIntegration => {
    const pipelineIntegrationStagedRevision = pipelineIntegration.stagedRevision;
    const { id: stagedRevisionId, stagedForDelete } = pipelineIntegrationStagedRevision;

    const deployedIntegration = deployedIntegrations?.find(integration => integration.revision.id === stagedRevisionId);

    const stagedRevision = mergeStagedRevision({
      stagedRevision: pipelineIntegrationStagedRevision,
      deployedIntegration,
    });
    const { isChanged: revisionHasChanges } = stagedRevision;

    const pipelineVariables = mergePipelineVariables({
      deployedIntegration,
      stagedRevision: pipelineIntegrationStagedRevision,
    });
    const pipelineVariablesHasChanges = isPipelineVariablesChanged(pipelineVariables);

    const consentCategory = mergeConsentCategories({
      pipelineIntegrationConsent: pipelineIntegration.pipelineIntegrationConsent,
      deployedIntegrationConsent: deployedIntegration?.consent?.category,
    });
    const { isChanged: consentCategoryHasChanges } = consentCategory;

    const status = determinePipelineIntegrationStatus({
      deployedIntegration,
      revisionHasChanges,
      pipelineVariablesHasChanges,
      consentCategoryHasChanges,
      stagedForDelete,
    });

    return {
      id: pipelineIntegration.id,
      integration: pipelineIntegration.integration,
      status,
      isChanged: hasAnyChanges(
        revisionHasChanges,
        pipelineVariablesHasChanges,
        consentCategoryHasChanges,
        stagedForDelete
      ),
      pipelineVariables,
      consentCategory,
      stagedRevision: stagedRevision,
      type: PIPELINE_INTEGRATION_TYPES.MANAGED,
    };
  });
}
