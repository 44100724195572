import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmDelete } from '@metarouter-private/mr-mui';

import { MrErrorModal } from '@ion/components';
import useConfirmDeleteModal from './useConfirmDeleteModal';
import ConfirmDeleteMessage from './ConfirmDeleteMessage';

export default function ConfirmDeleteModal({ pipelineDeprecated, openDeprecated, onCloseDeprecated }) {
  const { errorMsg, setErrorMsg, pipeline, showDeleteModal, onClose, onSubmit } =
    useConfirmDeleteModal(pipelineDeprecated);

  if (errorMsg) {
    return (
      <MrErrorModal header="Error Deleting Pipeline" errorMessage={errorMsg} closeModal={() => setErrorMsg(false)} />
    );
  }

  // TODO (Chris 2025.02.25): Remove this conditional once we deprecate the old pipeline UI
  if (pipelineDeprecated) {
    return (
      <ConfirmDelete
        open={openDeprecated}
        onClose={onCloseDeprecated}
        title={`Delete Pipeline ${pipelineDeprecated.name}?`}
        message={<ConfirmDeleteMessage name={pipelineDeprecated.cluster.name} />}
        onConfirm={onSubmit}
        confirmLabel="Remove"
      />
    );
  }

  return (
    <ConfirmDelete
      open={showDeleteModal}
      onClose={onClose}
      title={`Delete Pipeline ${pipeline.name}?`}
      message={<ConfirmDeleteMessage name={pipeline.cluster.name} />}
      onConfirm={onSubmit}
      confirmLabel="Remove"
    />
  );
}

// TODO (Chris 2025.02.25): Remove all props once we deprecate the old pipeline UI
ConfirmDeleteModal.propTypes = {
  pipelineDeprecated: PropTypes.shape({
    cluster: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    id: PropTypes.string,
    name: PropTypes.string,
    pipelineIntegrations: PropTypes.arrayOf(PropTypes.object),
    writekey: PropTypes.string,
  }),
  openDeprecated: PropTypes.bool,
  onCloseDeprecated: PropTypes.func,
};
