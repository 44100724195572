import React from 'react';
import PropTypes from 'prop-types';

import { MrIcon } from '@ion/components';
import { useRemovePipelineIntegration } from '@ion/api';

export default function RemoveStagedRevision(row) {
  const [removePipelineIntegration] = useRemovePipelineIntegration();

  const { pipelineIntegration } = row;

  return (
    <MrIcon
      testId="removeStagedRevision"
      id="icon-trash"
      onClick={() => {
        removePipelineIntegration(pipelineIntegration);
      }}
      title="Remove staged revision"
    />
  );
}

RemoveStagedRevision.propTypes = {
  row: PropTypes.object,
};
