import React from 'react';
import { SimpleCarousel } from '@metarouter-private/mr-mui';
import useViolationEventsCarouselProps from './useViolationEventsCarouselProps';
import EventsLoading from './EventsLoading';

export default function ViolationEventsCarousel() {
  const { loading, items } = useViolationEventsCarouselProps();

  if (loading) {
    return <EventsLoading />;
  }

  return <SimpleCarousel items={items} />;
}
