import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { MrIconButton, MrIcon } from '@ion/components';

import { usePipelineIntegrationTableContext } from 'app/pipeline-details/PipelineIntegrationTableContextProvider';
import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

import getDeployStatus from './getDeployStatus';
import determineDeployAction from './determineDeployAction';
import determineDisabled from './determineDisabled';

const DEFAULT_BUTTON_PROPS = {
  size: 'medium',
  Icon: <MrIcon id="icon-deploy" fontSize="small" />,
};

export default function DeployChangesButton() {
  const {
    pipeline: { deployedRevisions, pipelineIntegrations, cluster },
  } = usePipelinePageContext();
  const { setPipelineVariablesModalOpen, setRemoveDeployedIntegrationsModalOpen, openPipelineDiffModal, loadingDiff } =
    usePipelineIntegrationTableContext();

  if (isEmpty(pipelineIntegrations)) {
    return null;
  }

  const deployStatus = getDeployStatus({
    cluster,
    deployedRevisions,
    pipelineIntegrations,
    loadingDiff,
  });

  const disabled = determineDisabled({ deployStatus });
  const onClick = () => {
    determineDeployAction({
      deployStatus,
      setPipelineVariablesModalOpen,
      setRemoveDeployedIntegrationsModalOpen,
      openPipelineDiffModal,
    });
  };

  return (
    <MrIconButton
      {...DEFAULT_BUTTON_PROPS}
      onClick={onClick}
      disabled={disabled}
      text={deployStatus?.buttonText}
      tooltip={deployStatus?.buttonTooltip}
    />
  );
}
