import { map } from 'lodash';
import { useApolloClient } from '@apollo/client';

import permissions from 'api/Apollo/permissions';
import { GET_ALL_EVENT_NAMES } from './all-event-names-query-builder';

export default function useFetchAllEventNames({ ...options } = {}) {
  const client = useApolloClient();

  return async () => {
    const response = await client.query({
      ...options,
      context: { permission: permissions.readSchemaLibrary },
      query: GET_ALL_EVENT_NAMES,
      fetchPolicy: 'cache-first',
    });

    return transformResponse(response);
  };
}

function transformResponse(response) {
  return {
    ...response,
    data: map(response.data.eventNames, 'name'),
  };
}
