import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import useActiveModalState from './useActiveModalState';

const ModalContext = createContext(null);

export default function ModalContextProvider({ children }) {
  const { modalState, editPipelineName, deletePipeline, closeModal } = useActiveModalState();
  const [errorMsg, setErrorMsg] = useState(false);

  return (
    <ModalContext.Provider value={{ modalState, editPipelineName, deletePipeline, closeModal, errorMsg, setErrorMsg }}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModalContext() {
  return useContext(ModalContext);
}

ModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
