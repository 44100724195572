import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useUserContext } from '@ion/user';

import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';
import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

import RemoveDeployedIntegrationsModal from './RemoveDeployedIntegrationsModal';
import PipelineVarsModal from './PipelineVarsModal';
import DeployModal from './DeployModal';

const PipelineIntegrationTableContext = createContext(null);

export default function PipelineIntegrationTableContextProvider({ children }) {
  const { pipeline, refetch } = usePipelinePageContext();
  const { user } = useUserContext();
  const [deployModalOpen, setDeployModalOpen] = useState(false);
  const [loadingDiff, setLoadingDiff] = useState(false);
  const [pipelineVariablesModalOpen, setPipelineVariablesModalOpen] = useState(false);
  const [removeDeployedIntegrationsModalOpen, setRemoveDeployedIntegrationsModalOpen] = useState(false);
  const openPipelineDiffModal = () => {
    setLoadingDiff(true);
    setDeployModalOpen(true);
  };

  // EunHye: To Add/Edit Integration, we need <PipelineCardContextProvider>
  // This needs to be removed when `pipeline-deprecated` are removed.
  // We can replace this using PipelinePageContextProvider
  const { setPipeline } = usePipelineCardContext();

  useEffect(() => {
    if (pipeline) {
      setPipeline(pipeline);
    }
  }, [pipeline, setPipeline]);

  return (
    <PipelineIntegrationTableContext.Provider
      value={{
        setRemoveDeployedIntegrationsModalOpen,
        setPipelineVariablesModalOpen,
        loadingDiff,
        openPipelineDiffModal,
      }}
    >
      {children}
      {removeDeployedIntegrationsModalOpen && (
        <RemoveDeployedIntegrationsModal
          setRemoveDeployedIntegrationsModalOpen={setRemoveDeployedIntegrationsModalOpen}
          refetch={refetch}
        />
      )}
      {pipelineVariablesModalOpen && (
        <PipelineVarsModal pipeline={pipeline} setPipelineVariablesModalOpen={setPipelineVariablesModalOpen} />
      )}
      <DeployModal
        deployModalOpen={deployModalOpen}
        setDeployModalOpen={setDeployModalOpen}
        clusterId={pipeline?.cluster?.id}
        writekey={pipeline?.writekey}
        pipelineId={pipeline?.id}
        pipelineName={pipeline?.name}
        author={user?.email}
        pipelineFilter={pipeline?.filter}
        pipelineTransform={pipeline?.transform}
        setLoadingDiff={setLoadingDiff}
        deployedRevisions={pipeline?.deployedRevisions}
        pipelineIntegrations={pipeline?.pipelineIntegrations}
        refetch={refetch}
      />
    </PipelineIntegrationTableContext.Provider>
  );
}

export function usePipelineIntegrationTableContext() {
  return useContext(PipelineIntegrationTableContext);
}

PipelineIntegrationTableContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
