import React from 'react';
import PropTypes from 'prop-types';

import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import BackToPipelineOverviewButton from 'app/components/BackToPipelineOverviewButton';

import BackToPipelinesButton from './BackToPipelinesButton';

export default function BackButton({ pipelineId, clusterId, name, writekey }) {
  const backToPipelinesButtonProps = {
    pipelineId,
    clusterId,
    name,
    writekey,
  };
  return (
    <FeatureGate
      flagName={FEATURE_FLAGS.pipelinesListRevamp}
      fallback={<BackToPipelinesButton {...backToPipelinesButtonProps} />}
    >
      <BackToPipelineOverviewButton pipelineId={pipelineId} />
    </FeatureGate>
  );
}

BackButton.propTypes = {
  pipelineId: PropTypes.string.isRequired,
  clusterId: PropTypes.string.isRequired,
  writekey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
