import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import isFeatureEnabled from 'app/launch-darkly/is-feature-enabled';

import { ConsentCategoriesContext } from './consent-categories-context';

export const GatedConsentCategoriesContext = ({ children }) => {
  const featureFlags = useFlags();
  const showConsentCategories = isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.configurableConsentMvp });
  const hasConsentModule = isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.ecmConfigurableConsent });

  if (showConsentCategories && hasConsentModule) {
    return <ConsentCategoriesContext>{children}</ConsentCategoriesContext>;
  }

  return <>{children}</>;
};

GatedConsentCategoriesContext.propTypes = {
  children: PropTypes.node.isRequired,
};
