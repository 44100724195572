import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { startCase as _startCase } from 'lodash';

import { MrModal, MrButtonList, MrH, MrButton, MrErrorModal } from '@ion/components';
import { useDiffPipelines } from '@ion/api';
import { globalModalStore } from '@ion/global';

import mergeCurrentAndProposed from './mergeCurrentAndProposed';
import getDisplayNames from './getDisplayNames';

import styles from './index.module.scss';
import useDeploy from './useDeploy';
import usePipelineJson from './usePipelineJson';
import { ERRORS } from './ERRORS';

const diffStyles = {
  variables: {
    light: {
      codeFoldGutterBackground: '#ebebeb',
      codeFoldBackground: '#ebebeb',
      codeFoldContentColor: '#5c6775',
    },
  },
};

export default function DeployModal({
  deployModalOpen,
  setDeployModalOpen,
  clusterId,
  writekey,
  pipelineId,
  pipelineName,
  author,
  pipelineFilter,
  pipelineTransform,
  setLoadingDiff,
  deployedRevisions,
  pipelineIntegrations,
  refetch,
}) {
  const closeModal = () => {
    setErrorMsg(null);
    setDeployModalOpen(false);
  };

  // When you set ErrorMsg, please follow the globalModalStore pattern
  // errorMsg: { title: ERRORS.DIFF_ERROR, message: error }
  const [errorMsg, setErrorMsg] = useState(null);

  const pipelineJson = usePipelineJson({
    pipelineIntegrations,
    author,
    writekey,
    pipelineFilter,
    pipelineTransform,
    setErrorMsg,
  });

  const { deploying, deployPipeline } = useDeploy({
    pipelineIntegrations,
    pipelineId,
    clusterId,
    writekey,
    setErrorMsg,
    pipelineName,
    pipelineJson,
    refetch,
    closeModal,
  });

  const [diffOpen, setDiffOpen] = useState();
  const [diffResponse, setDiffResponse] = useState();
  const [getDiff] = useDiffPipelines();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDiffCallback = useCallback(getDiff, []);

  useEffect(() => {
    if (diffResponse?.errors?.message) {
      setErrorMsg({
        title: ERRORS.DIFF_ERROR,
        message: diffResponse.errors.message,
      });
    }
  }, [diffResponse, setDeployModalOpen]);

  // using useEffect to get diff each time the pipelineJson is changed
  // Will only make a fetch call to getDiff if we have some integrations pipelineJson?.integrations?.length
  useEffect(() => {
    if (pipelineJson?.integrations?.length && deployModalOpen) {
      getDiffCallback({
        clusterId,
        writekey,
        proposedJSON: pipelineJson,
      })
        .then(response => {
          setDiffResponse(response);
          setLoadingDiff(false);
        })
        .catch(e => {
          console.log('e ', e);
          globalModalStore.addNotification({
            title: ERRORS.DIFF_ERROR,
            message: e.message,
          });
          setLoadingDiff(false);
        });
    }
  }, [deployModalOpen, pipelineJson, clusterId, writekey, getDiffCallback, setLoadingDiff]);

  if (!diffResponse) {
    return null;
  }

  let current;
  let proposed;

  if (diffResponse && !diffResponse?.errors) {
    current = diffResponse.current;
    proposed = diffResponse.proposed;
  }

  const displayNames = getDisplayNames({ pipelineIntegrations, deployedRevisions });

  const integrations = mergeCurrentAndProposed({
    current,
    proposed,
    displayNames,
  });

  return (
    <>
      {deployModalOpen && (
        <MrModal closeModal={closeModal} styleNames="extraWide allowOverflow">
          <div className={styles.header}>
            <MrH h="h2" styleNames="sans">
              Pipeline Change Verification
            </MrH>
          </div>
          <div className={styles.content}>
            {integrations.length > 0 && (
              <>
                <p className={styles.summary}>
                  You&rsquo;re about to make changes {integrations.length > 1 ? 'across' : 'to'} {integrations.length}{' '}
                  {pluralize('integration', integrations.length)}.
                </p>

                {integrations.map(i => {
                  return (
                    <div key={i.id} className={styles.integration}>
                      <div className={styles.integrationHeader}>
                        <MrH h="h3" styleNames="sans">
                          <span className={styles[i.changeType]} /> {_startCase(i.changeType)}:{' '}
                          {i.displayName || i.name}
                        </MrH>
                        <button
                          className={styles.diffToggle}
                          onClick={() => {
                            if (diffOpen === i.id) {
                              setDiffOpen();
                            } else {
                              setDiffOpen(i.id);
                            }
                          }}
                        >
                          {diffOpen === i.id ? 'Hide' : 'Show'} details
                        </button>
                      </div>

                      {diffOpen === i.id && (
                        <div className={styles[`diffBorder${_startCase(i.changeType)}`]}>
                          <div className={styles.diffView}>
                            <ReactDiffViewer
                              leftTitle={<span className={styles.diffHeading}>Currently Deployed</span>}
                              rightTitle={<span className={styles.diffHeading}>Staged for Deploy</span>}
                              oldValue={JSON.stringify(i.current, null, 2)}
                              newValue={JSON.stringify(i.proposed, null, 2)}
                              splitView={true}
                              styles={diffStyles}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            )}

            <MrButtonList
              styleNames="marginTop"
              buttons={[
                <MrButton
                  testId="confirmDeploy"
                  text={deploying ? 'Deploying...' : 'Deploy'}
                  type="button"
                  key="deploy"
                  working={deploying}
                  onClick={deployPipeline}
                  styleNames="green"
                  className={styles.deployButton}
                />,
              ]}
            />
          </div>
        </MrModal>
      )}
      {errorMsg && <MrErrorModal header={errorMsg?.title} errorMessage={errorMsg?.message} closeModal={closeModal} />}
    </>
  );
}

DeployModal.propTypes = {
  deployModalOpen: PropTypes.bool.isRequired,
  setDeployModalOpen: PropTypes.func.isRequired,
  clusterId: PropTypes.string,
  writekey: PropTypes.string,
  pipelineId: PropTypes.string,
  pipelineName: PropTypes.string,
  author: PropTypes.string,
  pipelineFilter: PropTypes.object,
  pipelineTransform: PropTypes.object,
  setLoadingDiff: PropTypes.func,
  deployedRevisions: PropTypes.array,
  pipelineIntegrations: PropTypes.array,
  refetch: PropTypes.func,
};
