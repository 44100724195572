import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import PipelineActionsKebabMenu from './PipelineActionsKebabMenu';

const STACK_PROPS = {
  alignItems: 'center',
  direction: 'row',
};

export default function ActionsCell({ row }) {
  return (
    <Stack {...STACK_PROPS}>
      <PipelineActionsKebabMenu row={row} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
