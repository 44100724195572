import { camelCase as _camelCase, toUpper as _toUpper, snakeCase as _snakeCase, forEach as _forEach } from 'lodash';
import castType from './castType';
import { omitOneOfType } from './omitOneOfType';

const getSdkConnection = ({ integrationName, connection, vars, pipelineVars, pipeline_variables }) => {
  const { properties = {}, required = [], params = [] } = connection || { properties: {}, required: [] };
  const c = {};

  const integrationKey = _camelCase(integrationName);
  c[integrationKey] = {};
  c.params = [];

  // throw an error if required value is missing from both vars and pipelineVars
  required?.forEach(property => {
    const paramName = _toUpper(_snakeCase(property));
    const param = params.find(({ name }) => name === paramName || name === property);

    if (!pipelineVars?.[paramName] && !param?.defaultValue && !param?.pipelineVarId && !vars?.[property]) {
      throw new Error(`Integration "${integrationName}" is missing required connection variable "${paramName}"`);
    }
  });

  // hydrate connection for SDKs
  _forEach(properties, ({ type }, property) => {
    const paramName = _toUpper(_snakeCase(property));
    const param = omitOneOfType(property, params, paramName);

    // This enables to work with the old connection
    if (vars?.[property]) {
      if (type === 'string') {
        c[integrationKey][property] = `$$${paramName}$$`;
        c.params.push({ name: paramName, defaultValue: String(vars[property]) });
        return;
      }
      c[integrationKey][property] = castType(vars?.[property]);
      return;
    }

    if (pipelineVars?.[property]) {
      if (type === 'string') {
        c[integrationKey][property] = `$$${paramName}$$`;
        c.params.push({ name: paramName, defaultValue: String(pipelineVars[property]) });
        return;
      }
      c[integrationKey][property] = castType(pipelineVars?.[property]);
      return;
    }

    const playbookPipelineVariables = pipeline_variables.map(pipeline_variable => pipeline_variable.name);
    const isPlaybookPipelineVariable = playbookPipelineVariables.indexOf(paramName) !== -1;

    // This is for new sdk connection
    if (pipelineVars?.[paramName] && type === 'string' && isPlaybookPipelineVariable) {
      c[integrationKey][property] = `$$${paramName}$$`;
      c.params.push({ name: paramName, defaultValue: String(pipelineVars[paramName]) });
      return;
    }

    if (param?.defaultValue) {
      if (type === 'string' && param.name !== 'BROKERS') {
        c[integrationKey][property] = `$$${paramName}$$`;
        c.params.push({ name: paramName, defaultValue: param?.defaultValue });
        return;
      }
      c[integrationKey][property] = castType(param?.defaultValue);
    }

    // If it has pipelineVarId, it has no defaultValue
    // So we will create it and pass it to params so that forge can replace it
    if (type === 'string' && param?.pipelineVarId) {
      c[integrationKey][property] = `$$${paramName}$$`;
      c.params.push({ name: paramName, defaultValue: `my-${property}`, pipelineVarId: param.pipelineVarId });
      return;
    }
  });

  return c;
};

export default getSdkConnection;
