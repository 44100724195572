import { useEffect } from 'react';

import { useFormContext } from '@metarouter-private/mr-mui';

import { useLazyQuerySchemaLibraryVersion } from 'api';
import { useSchemaLibraryPageContext } from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';

import createSchemaEventMap from './createSchemaEventMap';
import { DUPLICATE_EVENT_SCHEMA_ERROR_NAME, DUPLICATE_EVENT_SCHEMA_ERROR_MESSAGE } from './constants';

export default function useValidateEventSchemaCombination() {
  const { activeVersion } = useSchemaLibraryPageContext();
  const [getSchemaLibraryVersion, { data, loading }] = useLazyQuerySchemaLibraryVersion();
  const {
    watch,
    formState: { errors, isDirty },
    setError,
    clearErrors,
  } = useFormContext();

  const event = watch('event');
  const schemaVersion = watch('schemaVersion');

  useEffect(() => {
    if (activeVersion.id) {
      getSchemaLibraryVersion({ schemaLibraryVersionId: activeVersion.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVersion.id]);

  useEffect(() => {
    clearErrors(DUPLICATE_EVENT_SCHEMA_ERROR_NAME);

    if (isDirty && data && !loading) {
      const map = createSchemaEventMap(data);
      const hasEvent = map[schemaVersion]?.has(event) ?? false;

      if (hasEvent) {
        setError(DUPLICATE_EVENT_SCHEMA_ERROR_NAME, {
          type: 'custom',
          message: DUPLICATE_EVENT_SCHEMA_ERROR_MESSAGE,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, event, schemaVersion]);

  return {
    errors,
  };
}
