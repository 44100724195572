import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function ViolationsCountCell({ violationsCount }) {
  const text = `${violationsCount} violations`;

  return <TextCell variant="body2" text={text} />;
}

ViolationsCountCell.propTypes = {
  violationsCount: PropTypes.number,
};
