/* eslint-disable filename-export/match-default-export */

import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './back.module.scss';
import back from './assets/icon-arrow-left.svg';
import MrLink from '../link/link';

const MrBack = ({ children = `Back`, className, ...props }) => {
  const newProps = {
    ...props,
    to: typeof props.to === 'object' ? { ...props.to, state: { ...props.to.state } } : props.to,
  };

  return (
    <MrLink styleNames="gray" {...newProps} className={cx(s.back, className)}>
      <img src={back} alt="" className={s.img} />
      {children}
    </MrLink>
  );
};

MrBack.propTypes = {
  children: pt.node,
  className: pt.string,
  to: pt.oneOfType([pt.string, pt.object]),
};

export default MrBack;
