import isEmpty from 'lodash/isEmpty';
import TextCell from 'app/components/data-table/TextCell';

import SchemaLibraryNameCell from './SchemaLibraryNameCell';
import ActionCell from './ActionCell';
import ViolationsCountCell from './ViolationsCountCell';

export default function buildSchemaEnforcementTableContents({ pipelineSchemaLibrary, violationsCount }) {
  if (isEmpty(pipelineSchemaLibrary)) {
    return [];
  }

  const {
    id,
    schemaLibraryVersion: {
      version,
      id: schemaLibraryVersionId,
      schemaLibrary: { name, id: schemaLibraryId },
    },
  } = pipelineSchemaLibrary;

  return [
    {
      0: { Component: SchemaLibraryNameCell, componentProps: { name, version } },
      2: { Component: ActionCell, componentProps: { id, schemaLibraryVersionId, schemaLibraryId, name, version } },
    },
    {
      0: { Component: TextCell, componentProps: { text: 'Last 7 days', variant: 'body2' } },
      1: { Component: ViolationsCountCell, componentProps: { violationsCount } },
    },
  ];
}
