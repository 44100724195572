import React, { useState } from 'react';
import { cloneDeep as _cloneDeep } from 'lodash';

import { MrAppLayout, MrAppMain, MrIcon, MrLinkButton, MrSearch, MrLink } from '@ion/components';
import { useQueryPipelines, useQueryClusters, useQueryIntegrations, useInsertPipeline } from '@ion/api';
import { useUserContext } from '@ion/user';

import { routes } from 'app/constants';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';

import CreatePipelineModal from 'app/pipelines/modals/CreatePipelineModal';

import PipelineCard from './card';
import s from './index.module.scss';
import PipelineSection from './section';
import { PipelineCardContextProvider } from './card/context/pipeline-card-context';

const PipelinesPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: pipelinesData, loading: loadingPipelines } = useQueryPipelines();
  const { data: clustersData, loading: loadingClusters } = useQueryClusters();
  const { user, currentOrg } = useUserContext();
  const { data: integrationsData } = useQueryIntegrations({
    variables: { limit: null },
  });
  const isLoading = loadingPipelines || loadingClusters;
  const [pipelines, setPipelines] = useState(_cloneDeep(pipelinesData));
  const pipelineSearchFields = ['name', 'writekey', 'staged_revisions.playbook.integration.name'];

  const [, { isAllowed: createPipelinePermission }] = useInsertPipeline();

  return (
    <MrAppLayout
      title="Pipelines"
      description="Manage your data pipelines."
      sideNav={<SideNav />}
      header={<Header icon="pipelines" title="Pipelines" />}
    >
      <MrAppMain
        loading={isLoading}
        styleNames="list"
        header={
          <>
            <MrLinkButton
              disabled={!createPipelinePermission}
              onClick={() => setModalOpen(true)}
              className="addNewButton"
            >
              <MrIcon id="plus-circle" fontSize="medium" />
              New Pipeline
            </MrLinkButton>
            <MrSearch
              placeholder="Search Pipelines, Integrations or Write Keys"
              data={pipelinesData}
              onChange={setPipelines}
              fields={pipelineSearchFields}
              localStoragePersistenceKey={currentOrg?.id + ' pipelineSearch'}
            />
            <MrLink to={routes.selectStageOrDeploy} styleNames="gray" className={s.bulkStageAndDeploy}>
              <MrIcon id={'icon-bulk-deploy'} className={s.icon} />
              Bulk Stage and Deploy
            </MrLink>
          </>
        }
      >
        <CreatePipelineModal open={modalOpen} onClose={() => setModalOpen(false)} />

        <div className={s.container}>
          {pipelinesData.length > 0 &&
            clustersData?.map(cluster => {
              if (cluster.pipelines.length > 0) {
                return (
                  <React.Fragment key={cluster.id}>
                    <PipelineSection title={cluster.name} />
                    <div className={s.list}>
                      {cluster.pipelines.map(pipeline => {
                        // an existing pipeline may be filtered out by the search input
                        const p = pipelines.find(p => p.id === pipeline.id);

                        if (!p) {
                          return null;
                        }

                        return (
                          <PipelineCardContextProvider key={pipeline.id}>
                            <PipelineCard
                              pipeline={p}
                              cluster={cluster}
                              //Delete questions mark when merged the change on the userContext
                              author={user?.email}
                              integrationsData={integrationsData}
                            />
                          </PipelineCardContextProvider>
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              }
            })}
        </div>
      </MrAppMain>
    </MrAppLayout>
  );
};

export default PipelinesPage;
