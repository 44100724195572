import React from 'react';

import { MrAppLayout, MrAppMain } from '@ion/components';
import { useTrackPage } from '@ion/api';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';
import ModalContextProvider from 'app/pipelines/ModalContext';
import EditPipelineNameModal from 'app/pipelines/modals/EditPipelineNameModal';
import ConfirmDeleteModal from 'app/pipelines/modals/ConfirmDeleteModal';
import PipelinesTable from './PipelinesTable';
import { PipelinesTableContextProvider } from 'app/pipelines/PipelinesTable/PipelinesTableContext';

export default function PipelinesPage() {
  useTrackPage('pipelines_list_page_viewed');

  return (
    <MrAppLayout
      title="Pipelines"
      description="Manage your data pipelines."
      sideNav={<SideNav />}
      header={<Header icon="pipelines" title="Pipelines" />}
    >
      <MrAppMain>
        <PipelinesTableContextProvider>
          <ModalContextProvider>
            <PipelinesTable />
            <EditPipelineNameModal />
            <ConfirmDeleteModal />
          </ModalContextProvider>
        </PipelinesTableContextProvider>
      </MrAppMain>
    </MrAppLayout>
  );
}
