import { gql } from '@apollo/client';

export default gql`
  fragment analyticsFile on analytics_files {
    id
    integrations
    pipelineId
    updatedAt
    analytics_file_settings {
      id
      host
      cdnURL
      compressFile
      impliedConsent
      cookieName
      itpSettings
      fileHostingType
      analyticsFileId
      windowVariableName
      storagePrefix
      storageType
      storageDatabase
    }
    google_tag_settings {
      id
      consentEnabled
      consentExplicitConsent
      consentSyncCategories
      tagId
    }
    analytics_file_identity_syncs {
      id
      settings
      analyticsFileId
      identitySyncId
      consentCategoryId
      identity_sync {
        id
        name
        friendlyName
      }
    }
  }
`;
