import React from 'react';

import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from '@ion/app/src/launch-darkly/featureFlags';
import PipelinesPageDeprecated from '@ion/app/src/pipelines-deprecated';

import PipelinesPage from './PipelinesPage';

export default function PipelinesPageFeatureGate() {
  return (
    <FeatureGate flagName={FEATURE_FLAGS.pipelinesListRevamp} fallback={<PipelinesPageDeprecated />}>
      <PipelinesPage />
    </FeatureGate>
  );
}
