import { isEmpty as _isEmpty } from 'lodash';

const connectedPipelines = readField => {
  const pipelines = readField({
    fieldName: 'pipelines',
    args: { where: { deletedAt: { _is_null: true } }, order_by: { name: 'asc' } },
  });

  const connected = pipelines?.reduce((acc, cur) => {
    const name = readField({
      from: cur,
      fieldName: 'name',
    });
    acc.push(name);
    return acc;
  }, []);

  return _isEmpty(connected) ? 'None' : connected.join(', ');
};

export default connectedPipelines;
