import { MrCard } from '@ion/components';
import React from 'react';
import pt from 'prop-types';
import IntegrationTableTitle from '../../integration-table-title/IntegrationTableTitle';
import styles from '../../index.module.scss';
import GoogleTagParentBody from './GoogleTagParentBody';

const GoogleTagParentContainer = ({
  stagedSyncs,
  setField,
  validateField,
  validateNumber,
  validateRequired,
  formState,
  resetError,
  setModalOpen,
}) => {
  const onClickGoogleTagDelete = () => {
    setModalOpen({ data: stagedSyncs, type: 'Identity Sync', name: 'googleGtag' });
  };

  const onClickSyncDelete = sync => {
    setModalOpen({ data: sync, type: 'Identity Sync', name: sync.name });
  };

  return (
    <MrCard
      className={styles.builderCard}
      top={
        <IntegrationTableTitle
          logoName={'googleGtag'}
          title={'Google Tag Settings'}
          onClickDelete={onClickGoogleTagDelete}
          deleteTitle='"Remove Google Tag"'
        />
      }
      bottom={
        <GoogleTagParentBody
          setField={setField}
          validateField={validateField}
          validateNumber={validateNumber}
          validateRequired={validateRequired}
          formState={formState}
          resetError={resetError}
          stagedSyncs={stagedSyncs}
          onClickSyncDelete={onClickSyncDelete}
        />
      }
    />
  );
};

GoogleTagParentContainer.propTypes = {
  formState: pt.object,
  resetError: pt.func,
  setField: pt.func,
  setModalOpen: pt.func,
  stagedSyncs: pt.array,
  validateField: pt.func,
  validateNumber: pt.func,
  validateRequired: pt.func,
};

export default GoogleTagParentContainer;
