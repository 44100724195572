import React from 'react';
import PropTypes from 'prop-types';

import MrButton from '../button/button';

export default function MrIconButton({ Icon, ...props }) {
  return <MrButton startIcon={Icon} {...props} />;
}

MrIconButton.propTypes = {
  Icon: PropTypes.element.isRequired,
  props: PropTypes.object,
};
