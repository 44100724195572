import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import TextCell from 'app/components/data-table/TextCell';
import IntegrationAvatarGroup from 'app/shared/components/integrations/IntegrationAvatarGroup';

const DEFAULT_INTEGRATION_AVATAR_GROUP_PROPS = {
  max: 5,
  size: 'small',
};

export default function IdentitySyncsCell({ identitySyncs = [] }) {
  if (isEmpty(identitySyncs)) {
    return <TextCell />;
  }

  const ids = identitySyncs.map(({ name }) => name);

  return <IntegrationAvatarGroup ids={ids} {...DEFAULT_INTEGRATION_AVATAR_GROUP_PROPS} />;
}

IdentitySyncsCell.propTypes = {
  identitySyncs: PropTypes.array,
};
