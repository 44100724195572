import actions from 'api/audit-logs/actions';
import { generateTimestamp } from 'api/audit-logs/generate-timestamp';

export function buildAuditLog({ schemaLibraryId, name, schemaLibraryVersion }) {
  const action = actions.schema_library_published;
  const description = `${name} ${schemaLibraryVersion}`;
  const debug_data = {
    schemaLibraryId,
    name,
    description: schemaLibraryVersion,
  };
  const timestamp = generateTimestamp();

  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
