import React from 'react';
import PropTypes from 'prop-types';

import { KebabMenu } from '@metarouter-private/mr-mui';
import isAllowedToWriteSchemaLibrary from '@ion/api/schema-libraries/isAllowedToWriteSchemaLibrary';

import { useBuildMenuItems } from './useBuildMenuItems';

export default function LibraryActionsKebabMenu({ row }) {
  const hasPermission = isAllowedToWriteSchemaLibrary();
  const menuItems = useBuildMenuItems(row);

  return <KebabMenu disabled={!hasPermission} items={menuItems} testId="libraryActionsKebabMenu" />;
}

LibraryActionsKebabMenu.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    assignmentsCount: PropTypes.number.isRequired,
  }).isRequired,
};
