import useLastDeployed from './useLastDeployed';
import useStatus from './useStatus';
import useDeployedRevisions from './useDeployedRevisions';
import useQueryCombinedPipeline from './useQueryCombinedPipeline';
import useNewPipelineDataModel from './useNewPipelineDataModel';

export default function useQueryPipelineWithRoost({ pipelineId, ...options }) {
  const { integrationsData, deployedPipeline, pipeline, loading, refetch } = useQueryCombinedPipeline({
    pipelineId,
    ...options,
  });

  const deployedRevisions = useDeployedRevisions({ pipeline, deployedPipeline, integrationsData });
  const status = useStatus({ pipeline, deployedPipeline, deployedRevisions });
  const lastDeployed = useLastDeployed({ deployedPipeline });

  const data = useNewPipelineDataModel({ pipeline, deployedPipeline });
  console.log('useNewPipelineDataModel ', data);

  if (!pipeline || !integrationsData) {
    return {
      data: {
        legacy: {
          lastDeployed,
          status,
          deployedRevisions: [],
          pipelineIntegrations: [],
          filter: {},
          transform: {},
        },
        pipeline: {},
      },
      loading,
      refetch,
    };
  }

  return {
    data: {
      legacy: {
        ...pipeline,
        lastDeployed,
        status,
        deployedRevisions,
        deployedPipeline: deployedPipeline?.pipeline,
        filter: deployedPipeline?.filter,
        transform: deployedPipeline?.transform,
      },
      pipeline: {},
    },
    loading,
    refetch,
  };
}
