import { useRemovePipelineIntegrations, useDeployPipeline } from '@ion/api';
import { ERRORS } from './ERRORS';
import { formatError, errorTitle } from 'app/shared/error/formatError';

export default function useDeploy({
  pipelineIntegrations,
  pipelineId,
  clusterId,
  writekey,
  setErrorMsg,
  pipelineName,
  pipelineJson,
  refetch,
  closeModal,
}) {
  const [removePipelineIntegrations] = useRemovePipelineIntegrations();
  const stagedForDelete = pipelineIntegrations.filter(
    pipelineIntegration => pipelineIntegration.stagedRevision.stagedForDelete === true
  );

  const [deploy, { loading: deploying }] = useDeployPipeline({
    onCompleted: () => {
      removePipelineIntegrations(stagedForDelete);
      refetch({ pipelineId, clusterId, writekey });
      closeModal();
    },
    onError: err => {
      setErrorMsg({
        title: errorTitle(err.errorMsg, ERRORS.DEPLOY_ERROR),
        message: formatError(err.errorMsg),
      });
    },
  });

  const deployPipeline = () => {
    deploy({
      id: pipelineId,
      name: pipelineName,
      clusterId,
      writekey,
      pipelineJSON: pipelineJson,
    });
  };

  return {
    deploying,
    deployPipeline,
  };
}
