import { Stack, Typography } from '@metarouter-private/mr-mui';
import GatedCodeEditor from 'app/components/GatedCodeEditor';
import React from 'react';
import PropTypes from 'prop-types';

const SECTION_TITLE = 'Request Body';

export default function RequestBody({ value }) {
  return (
    <Stack padding={3} spacing={2}>
      <Typography variant="h3">{SECTION_TITLE}</Typography>

      <GatedCodeEditor rows={30} name="request-drawer-body" disabled lang="json" value={value} />
    </Stack>
  );
}

RequestBody.propTypes = {
  value: PropTypes.string.isRequired,
};
