import React from 'react';
import PropTypes from 'prop-types';

import { MrText } from '@ion/components';

import IntegrationLogo from 'app/components/integrations/integration-logo';
import allowRowClick from 'app/pipeline-details/PipelineIntegrationsTable/cells/allow-row-click';

import styles from './cell.module.scss';

export default function IntegrationCell({ integration }) {
  return (
    <div className={styles.cell} {...allowRowClick}>
      <IntegrationLogo id={integration.starterkitId} className={styles.logo} {...allowRowClick} />
      <MrText testId="integrationCell" className={styles.name} {...allowRowClick}>
        {integration.name}
      </MrText>
    </div>
  );
}

IntegrationCell.propTypes = {
  integration: PropTypes.shape({
    starterkitId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
