import React from 'react';
import { Chip } from '@metarouter-private/mr-mui';
import { isNull as _isNull } from 'lodash';
import useSchemaViolationsCount from './useSchemaViolationsCount';

export default function SchemaViolationsCount() {
  const { violationsCount, chipColor } = useSchemaViolationsCount();

  if (_isNull(violationsCount)) {
    return null;
  }

  return <Chip label={violationsCount.toString()} color={chipColor} />;
}
