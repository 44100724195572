import React, { useEffect, useState } from 'react';
import pt from 'prop-types';

import BaseTable from '../../base-table/BaseTable';
import { MrInputNumber, MrInputText, MrInputTextarea, MrText, MrWithTooltip } from '@ion/components';
import { hydrateToRequest } from './hydrateToRequest';
import { hydrateFromDB } from './hydrateFromDB';
import { BRING_YOUR_OWN_SYNCS } from './consts';
import { Button, PlusCircleIcon, Trash02Icon } from '@metarouter-private/mr-mui';

const BringYourOwnSyncFieldsTable = ({
  syncName,
  formState,
  dataFields,
  requestInputName,
  validateRequired,
  resetError,
}) => {
  const inputTypes = {
    NUMBER: MrInputNumber,
    TEXT_AREA: MrInputTextarea,
    default: MrInputText,
  };

  const inputName = `sync-${syncName}-${requestInputName}`;

  const [data, setData] = useState();
  const [haveInitFormState, setHaveInitFormState] = useState(false);

  useEffect(() => {
    if (haveInitFormState) return;
    const dbData = formState[inputName]?.value;
    const initialData = hydrateFromDB(dbData, syncName);
    setData(initialData);
    setHaveInitFormState(true);
  }, [formState, syncName, inputName, haveInitFormState]);

  const createFieldSet = dataFields => {
    return dataFields.reduce((acc, field) => {
      acc[field.property] = field.default;
      return acc;
    }, {});
  };

  const generateID = data => {
    const lastElement = data[data.length - 1];
    return lastElement.id + 1;
  };

  const removeRow = id => {
    setData(data.filter(row => row.id !== id));
  };

  const handleChange = (id, field, value) => {
    setData(data.map(row => (row.id === id ? { ...row, [field]: value } : row)));
  };

  const addRow = e => {
    e.preventDefault();
    const id = generateID(data);
    const newFields = createFieldSet(dataFields);
    setData([...data, { id: id, ...newFields }]);
  };

  const renderFieldValue = (e, row, field) => {
    e.preventDefault();
    handleChange(row.id, field.property, e.target.value);
  };

  const onRemoveClick = (e, row) => {
    e.preventDefault();
    removeRow(row.id);
  };

  const isLastItem = (array, item) => array.lastIndexOf(item) === array.length - 1;

  return data ? (
    <>
      <MrText>{BRING_YOUR_OWN_SYNCS[syncName]?.DESCRIPTION || ''}</MrText>
      {data?.map((row, rowIndex) => (
        <BaseTable styleNames="greyTable" key={`${requestInputName}-${row.id}`}>
          <tbody>
            {dataFields.map(field => {
              const InputComponent = inputTypes[field.type] || inputTypes.default;
              return (
                <tr key={`${requestInputName}-${row.id}-${field.property}`}>
                  <td>
                    <MrWithTooltip message={field.description}>
                      <MrText styleNames="bold" component="span" required={field.required}>
                        {field.title}
                      </MrText>
                    </MrWithTooltip>
                  </td>
                  <td>
                    <InputComponent
                      placeholder={field.placeholder}
                      onChange={e => renderFieldValue(e, row, field)}
                      value={row[field.property]}
                      onInput={resetError}
                      name={`${inputName}-${field.property}-${rowIndex}`}
                      errorMsg={formState[`${inputName}-${field.property}-${rowIndex}`]?.errorMsg}
                      validators={field.required ? [validateRequired()] : []}
                    />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                {data.length > 1 && (
                  <Button
                    onClick={e => onRemoveClick(e, row)}
                    variant="text"
                    color="secondary"
                    startIcon={<Trash02Icon />}
                  >
                    Remove
                  </Button>
                )}
              </td>
              <td>
                {isLastItem(data, row) && (
                  <Button color="secondary" onClick={addRow} variant="text" startIcon={<PlusCircleIcon />}>
                    Add
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </BaseTable>
      ))}
      <input type="hidden" name={inputName} value={hydrateToRequest(syncName, data)} />
    </>
  ) : null;
};

BringYourOwnSyncFieldsTable.propTypes = {
  formState: pt.object.isRequired,
  validateRequired: pt.func.isRequired,
  resetError: pt.func.isRequired,
  syncName: pt.string.isRequired,
  requestInputName: pt.string.isRequired,
  dataFields: pt.array.isRequired,
};
export default BringYourOwnSyncFieldsTable;
