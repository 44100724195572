import React from 'react';

import { usePipelineSchemaLibraryTableContext } from 'app/pipelines-deprecated/card/PipelineSchemaSection/PipelineSchemaLibraryTableContext';

import PipelineSchemaTableActions from './PipelineSchemaTableActions';
import PipelineSchemaTableTitle from './PipelineSchemaTableTitle';
import pipelineSchemaTableColumns from './pipelineSchemaTableColumns';

const DEFAULT_COLUMNS = {
  columns: pipelineSchemaTableColumns,
  noDataComponent: null,
  noTableHead: true,
};

export default function usePipelineSchemaTableProps() {
  const { data, loading } = usePipelineSchemaLibraryTableContext();

  return {
    ...DEFAULT_COLUMNS,
    actions: <PipelineSchemaTableActions />,
    title: <PipelineSchemaTableTitle />,
    data,
    loading,
  };
}
