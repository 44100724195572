import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import mergePipelineIntegrations from './mergePipelineIntegrations';
import mergeUnmanagedDeployedIntegrations from './mergeUnmanagedDeployedIntegrations';

export default function useMergePipelineIntegrations({ pipelineIntegrations, deployedIntegrations }) {
  return useMemo(() => {
    if (isEmpty(pipelineIntegrations)) {
      return [];
    }

    const mergedPipelineIntegrations = mergePipelineIntegrations({ pipelineIntegrations, deployedIntegrations });
    const unmanagedDeployedIntegrations = mergeUnmanagedDeployedIntegrations({
      mergedPipelineIntegrations,
      deployedIntegrations,
    });

    return [...mergedPipelineIntegrations, ...unmanagedDeployedIntegrations];
  }, [pipelineIntegrations, deployedIntegrations]);
}
