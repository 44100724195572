import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from 'app/launch-darkly/is-feature-enabled';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

export default function useSettingsFlags() {
  const featureFlags = useFlags();

  return {
    hasConsent: isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.configurableConsentMvp }),
    hasUserManagement: isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.roUserManagementMVP }),
  };
}
