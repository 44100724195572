import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './app-layout.module.scss';
import MrLoading from '../loading/loading';
import MrPage from '../page/page';

export default function MrAppLayout({ title, description, children, header, className, loading, sideNav, ...props }) {
  return (
    <MrPage className={classnames(styles.page, className)} title={title} description={description} {...props}>
      {sideNav ?? sideNav}
      <div className={styles.content}>
        {header && <header className={classnames(styles.header, className)}>{header}</header>}
        <div className={styles.main} id="AppLayout">
          {children}
        </div>
      </div>

      {loading && <MrLoading />}
    </MrPage>
  );
}

MrAppLayout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  sideNav: PropTypes.node,
};
