/* eslint-disable filename-export/match-default-export */
/* disabling rule until refactor of analytics builder */

import React from 'react';
import propTypes from 'prop-types';

import { MrInputRadio, MrInputRadioGroup } from '@ion/components';
import { Typography, Link } from '@metarouter-private/mr-mui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';

const RADIO_GROUP_LABEL = 'How would you like to host your Analytics.js file?';

export const HOSTING_TYPES = {
  self: 'SELF',
  metaRouter: 'META_ROUTER',
};

const HOSTING_TYPE_VALUES = Object.values(HOSTING_TYPES);

export const READABLE_HOSTING_TYPE = {
  [HOSTING_TYPES.self]: 'Manual Deployment',
  [HOSTING_TYPES.metaRouter]: 'Auto-deploy to Cluster',
};
export const LEGACY_READABLE_HOSTING_TYPE = {
  [HOSTING_TYPES.self]: 'Self-hosted',
  [HOSTING_TYPES.metaRouter]: 'MetaRouter-hosted',
};

const metarouterHostingSecondaryLabel = (
  <Typography variant="body2">
    Automate your file deployments by following our{' '}
    <Link
      href="https://docs.metarouter.io/docs/deploying-your-ajs-tracking-file#metarouter-hosted-deployment"
      underline="always"
      variant="body2"
      target="_blank"
      rel="noopener"
      color="secondary"
    >
      auto-deployment documentation.
    </Link>
  </Typography>
);

const selfHostingSecondaryLabel = (
  <Typography variant="body2">
    Download your tracking file to manually deploy it.{' '}
    <Link
      href="https://docs.metarouter.io/docs/deploying-your-ajs-tracking-file#self-hosted-deployment"
      underline="always"
      variant="body2"
      target="_blank"
      rel="noopener"
      color="secondary"
    >
      See documentation.
    </Link>
    .
  </Typography>
);

export default function AnalyticsBuilderFileHosting({ value = '', onChange, resetError, errorMsg }) {
  const featureFlags = useFlags();
  const isUploadToClusterEnabled = isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.uploadAjsFileToCluster });

  return (
    <MrInputRadioGroup
      name="fileHostingType"
      label={RADIO_GROUP_LABEL}
      overrideValue={value}
      onChange={onChange}
      errorMsg={errorMsg}
      resetError={resetError}
      required
    >
      <MrInputRadio
        value={HOSTING_TYPES.self}
        label={isUploadToClusterEnabled ? 'Manual Deployment' : 'Self-hosted (Recommended)'}
        secondaryLabel={isUploadToClusterEnabled ? selfHostingSecondaryLabel : ''}
        tooltip={
          isUploadToClusterEnabled
            ? ''
            : 'We recommend that you host your own Analytics.js file in order to receive the full benefits of first-party tracking.'
        }
      />
      <MrInputRadio
        value={HOSTING_TYPES.metaRouter}
        label={isUploadToClusterEnabled ? 'Auto-deploy to Cluster' : 'Metarouter-hosted'}
        secondaryLabel={isUploadToClusterEnabled ? metarouterHostingSecondaryLabel : ''}
        tooltip={
          isUploadToClusterEnabled
            ? ''
            : 'MetaRouter will host your Analytics.js file, removing the need for you to track down your CDN and deploy your file.'
        }
      />
    </MrInputRadioGroup>
  );
}

AnalyticsBuilderFileHosting.propTypes = {
  value: propTypes.oneOf([...HOSTING_TYPE_VALUES, '']),
  onChange: propTypes.func.isRequired,
  resetError: propTypes.func.isRequired,
  errorMsg: propTypes.string,
};
