import { gql } from '@apollo/client';

export const PIPELINE_SCHEMA_LIBRARY_QUERY_NAME = 'PipelineSchemaLibrary';

export const GET_PIPELINE_SCHEMA_LIBRARY = gql`
  query ${PIPELINE_SCHEMA_LIBRARY_QUERY_NAME}($pipelineId: uuid!) {
    pipeline: pipelines_by_pk(id: $pipelineId) {
      id  
      schemaLibrary: pipeline_schema_library {
        id
        schemaLibraryVersion: schema_library_version {
          id
          status
          version
          schemaLibrary: schema_library {
            id
            name
            description
          }
        }
      }
    }
  }
`;

export function buildVariables({ pipelineId }) {
  return { pipelineId };
}
