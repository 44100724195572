export const ALLOW_ROW_EVENTS = {
  'data-tag': 'allowRowEvents',
};

export default function buildBubbleClickProps(bubbleClickEvent) {
  if (bubbleClickEvent) {
    return ALLOW_ROW_EVENTS;
  }

  return {};
}
