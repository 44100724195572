import { PIPELINE_STATUSES } from '@ion/api';

const DEFAULT_CHIP_STYLES = {
  size: 'small',
  variant: 'soft',
};

export default function buildChipProps({ status, additionalProps = {} }) {
  const CHIP_STYLES = {
    [PIPELINE_STATUSES.NotDeployed]: {
      color: 'secondary',
      ...DEFAULT_CHIP_STYLES,
    },
    [PIPELINE_STATUSES.StagedForDeploy]: {
      color: 'primary',
      ...DEFAULT_CHIP_STYLES,
    },
    [PIPELINE_STATUSES.StagedForDelete]: {
      color: 'primary',
      ...DEFAULT_CHIP_STYLES,
    },
    [PIPELINE_STATUSES.Deployed]: {
      color: 'success',
      ...DEFAULT_CHIP_STYLES,
    },
    [PIPELINE_STATUSES.Error]: {
      color: 'error',
      ...DEFAULT_CHIP_STYLES,
    },
  };

  if (!Object.values(PIPELINE_STATUSES).includes(status)) {
    throw new Error(`Unable to determine chip status: ${status}`);
  }

  return {
    ...additionalProps,
    label: status,
    ...CHIP_STYLES[status],
  };
}
