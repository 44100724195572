import { useLazyQuerySchemaLibraryVersion } from 'api';
import useDeployPipelineSchemas from './useDeployPipelineSchemas';

export default function useDeploySchemasToPipeline({
  pipelineSchemaLibraryId,
  schemaLibraryVersion,
  pipeline,
  ...options
}) {
  const [deployPipelineSchemas, response] = useDeployPipelineSchemas({
    pipelineSchemaLibraryId,
    schemaLibraryVersion,
    pipeline,
    ...options,
  });
  const [getSchemaLibraryVersion] = useLazyQuerySchemaLibraryVersion({ onCompleted: deployPipelineSchemas });

  const upsertPipelineSchemas = ({ schemaLibraryVersionId }) => {
    getSchemaLibraryVersion({ pipelineId: pipeline.id, schemaLibraryVersionId });
  };

  return [upsertPipelineSchemas, response];
}
