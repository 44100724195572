import { uniqBy, isEqual } from 'lodash';
import getDeployedPipelineVariables from './getDeployedPipelineVariables';

export default function mergePipelineVariables({ deployedIntegration, stagedRevision }) {
  const deployedPipelineVariables = getDeployedPipelineVariables({ deployedIntegration });

  const {
    pipelineVars,
    playbook: { pipeline_variables },
  } = stagedRevision;

  const uniquePipelineVariables = uniqBy(pipeline_variables, 'name');

  return uniquePipelineVariables.map(pipelineVariable => {
    const name = pipelineVariable.name;
    return {
      isChanged: !isEqual(pipelineVars[name], deployedPipelineVariables[name]),
      local: {
        [name]: pipelineVars[name],
      },
      deployed: {
        [name]: deployedPipelineVariables[name],
      },
    };
  });
}
