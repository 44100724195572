import React from 'react';
import propTypes from 'prop-types';

import { Chip } from '@metarouter-private/mr-mui';

import PipelineErrorTooltip from './PipelineErrorTooltip';
import buildChipProps from './buildChipProps';

export default function PipelineStatusChip({ status, ...additionalProps }) {
  const props = buildChipProps({ status, additionalProps });

  return (
    <PipelineErrorTooltip status={status}>
      <Chip {...props} />
    </PipelineErrorTooltip>
  );
}

PipelineStatusChip.propTypes = {
  status: propTypes.string.isRequired,
};
