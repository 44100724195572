import React from 'react';
import { useTrackPage } from '@ion/api';

import { MrTwoPaneLayout } from '@ion/components';
import RedirectFeatureGate from '../../launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

import { ConsentCategoriesTable, Documentation, ConsentCategoryModal } from './components';

export const ConsentCategoryPage = () => {
  useTrackPage('consent_settings_page_view');

  return (
    <>
      <RedirectFeatureGate flagName={FEATURE_FLAGS.configurableConsentMvp}>
        <ConsentCategoryModal />
        <MrTwoPaneLayout paneOne={<Documentation />} paneTwo={<ConsentCategoriesTable />} />
      </RedirectFeatureGate>
    </>
  );
};
