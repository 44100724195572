import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@metarouter-private/mr-mui';

import {
  MrForm,
  MrInputText,
  MrInputSelect,
  generateId,
  useForm,
  formValidators,
  MrLink,
  MrButtonList,
} from '@ion/components';
import { useInsertPipeline, useQueryClusters, useQueryPipelines } from '@ion/api';

import styles from './create-pipeline-modal.module.scss';

const { validateRequired, validateNotInArray, validateWriteKey } = formValidators;

export default function CreatePipelineForm({ onClose }) {
  const formId = generateId();
  const { formState, resetError, validateField, submitForm, setField } = useForm(formId);
  const { data: clusters } = useQueryClusters();

  const { data: pipelines } = useQueryPipelines();
  const pipelineNames = pipelines.map(p => p.name);
  const [createPipeline, { loading: savingPipeline }] = useInsertPipeline({ onCompleted: onClose });
  const selectedCluster = clusters.find(c => c.id === formState?.cluster?.value);
  const clusterWritekeys = selectedCluster?.pipelines.map(p => p?.writekey);

  const onFormSubmit = () => {
    const { data, isValid } = submitForm();
    if (isValid) {
      createPipeline({ name: data?.name, writekey: data?.writekey, clusterId: data?.cluster });
    }
  };

  return (
    <MrForm onSubmit={onFormSubmit} id={formId} className={styles.formCard}>
      <p>
        Pipelines live in your MetaRouter cluster, and connect a single data source, like a website or app, to an
        unlimited number of data Integrations.
      </p>
      <MrInputText
        onChange={setField}
        value={formState.name?.value ?? ''}
        onBlur={validateField}
        onInput={resetError}
        label="Pipeline name"
        name="name"
        validators={[validateRequired(), validateNotInArray(pipelineNames, 'Name already in use by another pipeline')]}
        errorMsg={formState.name?.errorMsg}
        labelPosition="top"
        fullWidth
      />

      <MrInputText
        onChange={setField}
        value={formState.writekey?.value ?? ''}
        onBlur={validateField}
        onInput={resetError}
        label="Writekey"
        name="writekey"
        validators={[
          validateRequired(),
          validateNotInArray(
            clusterWritekeys || [],
            `A pipeline connected to the "${selectedCluster?.name}" cluster already contains this writekey. Pipeline writekeys must be unique across clusters.`
          ),
          validateWriteKey,
        ]}
        errorMsg={formState.writekey?.errorMsg}
        labelPosition="top"
        fullWidth
        helperText="Writekey is a unique identifier for each Pipeline"
      />

      <MrInputSelect
        options={clusters.map(cluster => {
          return { text: cluster.name, value: cluster.id };
        })}
        onBlur={validateField}
        validators={[validateRequired()]}
        name="cluster"
        label="Assign to Cluster:"
        placeholder={!clusters.length ? 'No clusters available' : 'Select...'}
        value={formState.cluster?.value || ''}
        onChange={setField}
        errorMsg={formState.cluster?.errorMsg}
        disabled={!clusters.length}
        isClearable
        menuPosition="fixed"
        maxMenuHeight={250}
      />

      <MrButtonList
        styleNames="marginTop"
        buttons={[
          <Button size="large" key="create" type="submit" disabled={savingPipeline}>
            Add Pipeline
          </Button>,
          <MrLink onClick={onClose} styleNames="gray" key="cancel">
            Cancel
          </MrLink>,
        ]}
      />
    </MrForm>
  );
}

CreatePipelineForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};
