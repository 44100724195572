import React from 'react';
import PropTypes from 'prop-types';

import { startCase as _startCase } from 'lodash';
import ConfirmDelete from 'app/components/ConfirmDelete';
import { MrH } from '@ion/components';
import s from './index.module.scss';

export default function DeleteParameter({ operationToDelete, playbookState, setOperationToDelete, navState }) {
  const [section, type] = operationToDelete._path.split('.');
  return (
    <ConfirmDelete
      deleteFn={() => {
        playbookState.deleteParameter(section, operationToDelete.event, type, operationToDelete.opId);
        navState.setTransformation(null);
      }}
      closeModal={() => {
        setOperationToDelete(null);
      }}
    >
      <div className={s.header}>
        <MrH h="h2" styleNames="sans">
          Delete Parameters
        </MrH>
      </div>

      <div className={s.content}>
        <p>
          Delete <strong>{operationToDelete.outputKey || operationToDelete.expressionName}</strong> from{' '}
          {operationToDelete.event || `${_startCase(section)} Parameters`}?
        </p>
      </div>
    </ConfirmDelete>
  );
}

DeleteParameter.propTypes = {
  operationToDelete: PropTypes.object.isRequired,
  playbookState: PropTypes.object.isRequired,
  setOperationToDelete: PropTypes.func.isRequired,
  navState: PropTypes.object.isRequired,
};
