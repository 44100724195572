import auditLogMutation from 'api/audit-logs/insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const eventNameUpdatedMutation = auditLogMutation;

export function buildVariables({ id, name, description }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      eventNameId: id,
      name,
      eventNameDescription: description,
    }),
  };
}
