import { isEqual } from 'lodash';

import pipelineNotInCluster from './shared/pipelineNotInCluster';

export default function isFullyDeployed(ionPipeline, controlPipeline) {
  if (pipelineNotInCluster(controlPipeline)) {
    return false;
  }

  const ionIntegrationIds = ionPipeline.pipelineIntegrations.map(integration => integration.integration.starterkitId);
  const controlIntegrationIds = controlPipeline.pipeline.integrations.map(integration => integration.integrationName);

  const isFullyDeployed = isEqual(ionIntegrationIds, controlIntegrationIds);

  return isFullyDeployed;
}
