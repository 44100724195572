/**
 * Builds a URL for accessing AJS in a specific pipeline.
 *
 * @param {string} host - The base URL of the host.
 * @param {string} ID - The unique identifier of the pipeline.
 * @returns {string} A URL string.
 *
 */
const buildMRHostedFileURL = (host, ID) => new URL(`${host}/v1/pipelines/${ID}/file`).toString();

export default buildMRHostedFileURL;
