import React, { useState } from 'react';
import GatedCodeEditor from '@ion/app/src/components/GatedCodeEditor';
import propTypes from 'prop-types';

// TODO (Chris 2025.02.18) - AceEditor is rolled out, move UncontrolledCodeEditor back to component library
export default function UncontrolledCodeEditor(props) {
  const { defaultValue, onChange, ...remainingProps } = props;
  const [input, setInput] = useState(defaultValue || '');

  const onChangeWrapper = event => {
    setInput(event.target.value || '');
    if (onChange) {
      onChange(event);
    }
  };

  return <GatedCodeEditor {...remainingProps} value={input} onChange={onChangeWrapper} />;
}

UncontrolledCodeEditor.propTypes = {
  onChange: propTypes.func,
  ...GatedCodeEditor.propTypes,
};
