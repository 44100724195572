import React from 'react';

import { ReactDataTableTitle } from '@metarouter-private/mr-mui';
import { FEATURE_FLAGS } from '../../../launch-darkly/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';

const LABEL = 'Analytics.js File';
const ECM_STAND_ALONE_SYNC_LABEL = 'Sync Injector File';

export default function AnalyticsFileTableTitle() {
  const featureFlags = useFlags();

  const isEcmStandAloneSync = featureFlags[FEATURE_FLAGS.ecmStandAloneSync];

  const label = isEcmStandAloneSync ? ECM_STAND_ALONE_SYNC_LABEL : LABEL;

  return <ReactDataTableTitle noWrap label={label} />;
}
