import React from 'react';

import { SORTABLE_PIPELINE_FIELDS } from '@ion/api';

import TextCell from 'app/components/data-table/TextCell';
import TimestampCell from 'app/components/data-table/TimestampCell';
import IntegrationAvatarGroup from 'app/shared/components/integrations/IntegrationAvatarGroup';
import ActionsCell from './cells/ActionsCell';
import PipelineStatusChip from 'app/shared/components/pipelines/PipelineStatusChip';

export const pipelinesTableColumns = [
  {
    name: 'Pipeline Name',
    cell: row => <TextCell bubbleClickEvent text={row.name} />,
    sortable: true,
    sortField: SORTABLE_PIPELINE_FIELDS.name,
  },
  {
    name: 'Integrations',
    cell: row => (
      <IntegrationAvatarGroup ids={row.pipelineIntegrations.map(integration => integration.integration.starterkitId)} />
    ),
    sortable: true,
    sortField: SORTABLE_PIPELINE_FIELDS.integrations,
  },
  {
    name: 'Writekey',
    cell: row => <TextCell bubbleClickEvent text={row.writekey} />,
    sortable: true,
    sortField: SORTABLE_PIPELINE_FIELDS.writekey,
  },
  {
    name: 'Cluster',
    cell: row => <TextCell bubbleClickEvent text={row.cluster.name} />,
    sortable: true,
    sortField: SORTABLE_PIPELINE_FIELDS.cluster,
  },
  {
    name: 'Status',
    cell: row => <PipelineStatusChip status={row.status} />,
  },
  {
    name: 'Last Deployed',
    cell: row => <TimestampCell timestamp={row.lastDeployed} />,
  },
  {
    button: true,
    cell: row => <ActionsCell row={row} />,
  },
];
