const customStyles = {
  header: {
    style: {
      height: '68px',
      '> div:first-of-type': {
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  rows: {
    style: {
      '&:first-of-type': {
        height: '72px',
      },
      '&:last-of-type': {
        height: '54px',
      },
    },
  },
  cells: {
    style: {
      '&:first-of-type': {
        width: '128px',
        maxWidth: '128px',
      },
    },
  },
};

export default customStyles;
