import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';

import { Typography } from '@metarouter-private/mr-mui';

import buildBubbleClickProps from 'app/components/data-table/buildBubbleClickProps';

export default function TextCell({ text, bubbleClickEvent = false, ...typographyProps }) {
  let content = text;

  if (_isEmpty(text)) {
    content = '-';
  }

  const bubbleClickProps = buildBubbleClickProps(bubbleClickEvent);

  return (
    <Typography component="div" title={content} {...typographyProps} {...bubbleClickProps}>
      {content}
    </Typography>
  );
}

TextCell.propTypes = {
  text: PropTypes.string,
  bubbleClickEvent: PropTypes.bool,
};
