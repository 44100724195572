import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Form, Button } from '@metarouter-private/mr-mui';
import isAllowedToUpdateIntegration from '@ion/api/integrations/permissions/isAllowedToUpdateIntegration';

import EventSelect from './EventSelect';
import EventNameField from 'app/components/form/EventNameField';

const EVENT_NAME = 'eventName';
const EVENT_LABEL = 'event name';

const EVENT_CLONE_FROM = 'cloneFrom';

export default function NewEventForm({ navState, playbookState }) {
  const isAllowed = isAllowedToUpdateIntegration();
  const onSubmit = data => {
    playbookState.createEvent(data[EVENT_NAME], data[EVENT_CLONE_FROM]);
    navState.setEvent(data[EVENT_NAME]);
  };

  return (
    <Form onSubmit={onSubmit} validationMode="all">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <EventNameField name={EVENT_NAME} label={EVENT_LABEL} />
        </Grid>
        <Grid item xs={4}>
          <EventSelect playbookState={playbookState} name={EVENT_CLONE_FROM} />
        </Grid>
        <Grid item xs={4}>
          <Button size="large" type="submit" variant="contained" color="primary" disabled={!isAllowed}>
            Add Event
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}

NewEventForm.propTypes = {
  navState: PropTypes.shape({
    setEvent: PropTypes.func.isRequired,
  }),
  playbookState: PropTypes.shape({
    createEvent: PropTypes.func.isRequired,
  }),
};
