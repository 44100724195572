import { v4 as uuid } from 'uuid';
import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permissions from 'api/Apollo/permissions';
import mergeQueries from 'api/Apollo/merge-queries';
import { GET_SCHEMA_LIBRARIES } from 'api/schema-libraries/schema-libraries-query-builder';

import {
  mutation as insertSchemaLibraryMutation,
  buildVariables as buildInsertSchemaLibraryVariables,
} from './insert-schema-library-mutation-builder';

import {
  mutation as schemaLibraryCreatedMutation,
  buildVariables as buildSchemaLibraryCreatedVariables,
} from 'api/audit-logs/schema-library-created/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('InsertSchemaLibraryAndAudit', [
  insertSchemaLibraryMutation,
  schemaLibraryCreatedMutation,
]);

export default function useInsertSchemaLibrary(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_created');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    refetchQueries: [GET_SCHEMA_LIBRARIES],
    context: { permission: permissions.createSchemas },
  });

  const insertSchemaLibraryMutation = async ({ name, description }) => {
    const id = uuid();
    const response = await executeMutation({
      variables: {
        ...buildInsertSchemaLibraryVariables({
          id,
          name,
          description,
        }),
        ...buildSchemaLibraryCreatedVariables({
          id,
          name,
          description,
        }),
      },
    });

    if (!response.error) {
      trackWithUser();
    }
  };

  return [insertSchemaLibraryMutation, response];
}
