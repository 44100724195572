import { isEmpty, some } from 'lodash';
import PIPELINE_STATUSES from 'api/pipelines/useQueryPipelineWithRoost/PIPELINE_STATUSES';

export default function determinePipelineStatus({ pipelineIntegrations }) {
  if (isEmpty(pipelineIntegrations)) {
    return PIPELINE_STATUSES.NotDeployed;
  }

  const hasStagedForDeploy = some(pipelineIntegrations, { status: PIPELINE_STATUSES.StagedForDeploy });
  if (hasStagedForDeploy) {
    return PIPELINE_STATUSES.StagedForDeploy;
  }

  return PIPELINE_STATUSES.Deployed;
}
