import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@metarouter-private/mr-mui';
import PropTypes from 'prop-types';

const DEFAULT_PROPS = {
  component: Link,
};

export default function LinkButton({ to, children, ...buttonProps }) {
  return (
    <Button {...DEFAULT_PROPS} to={to} {...buttonProps}>
      {children}
    </Button>
  );
}

LinkButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
  ...Button.propTypes,
};
