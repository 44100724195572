import React from 'react';

import { Grid, ModalLauncher } from '@metarouter-private/mr-mui';

import CreatePipelineModal from 'app/pipelines/modals/CreatePipelineModal';
import PipelineSearchBar from './PipelineSearchBar';
import ClusterMultiSelect from './ClusterMultiSelect';
import BulkDeployButton from './BulkDeployButton';
import NewPipelineButton from './NewPipelineButton';

export default function PipelinesTableTitle() {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={true}>
        <PipelineSearchBar />
      </Grid>
      <Grid item xs={3}>
        <ClusterMultiSelect />
      </Grid>
      <Grid item>
        <BulkDeployButton />
      </Grid>
      <Grid item>
        <ModalLauncher Launcher={NewPipelineButton} Modal={CreatePipelineModal} />
      </Grid>
    </Grid>
  );
}
