import React from 'react';
import LinkButton from 'app/shared/navigation/LinkButton';

import { routes } from 'app/constants';
import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

const DEFAULT_BUTTON_PROPS = {
  variant: 'outlined',
  color: 'secondary',
  size: 'medium',
  'data-test-id': 'buildNewAjsFileButton',
};

export default function BuildNewFileButton() {
  const { pipeline } = usePipelinePageContext();

  const linkProps = {
    to: {
      pathname: routes.analyticsJSBuilder,
      state: {
        pipelineId: pipeline?.id,
        writekey: pipeline?.writekey,
        name: pipeline?.name,
        clusterId: pipeline?.clusterId,
        clusterName: pipeline?.cluster?.name,
      },
    },
  };

  return (
    <LinkButton {...DEFAULT_BUTTON_PROPS} {...linkProps}>
      Build New File
    </LinkButton>
  );
}
