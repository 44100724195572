import React from 'react';

import TimestampCell from 'app/components/data-table/TimestampCell';
import TextCell from 'app/components/data-table/TextCell';
import FormatNumberCell from 'app/components/data-table/FormatNumberCell';

import ViolationTypeCell from './ViolationTypeCell';

const schemaViolationsDetailsColumns = [
  {
    name: 'Violation Type',
    cell: row => <ViolationTypeCell type={row.type} />,
  },
  {
    name: 'Path',
    selector: row => <TextCell text={row.field} mono bubbleClickEvent />,
  },
  {
    name: 'Details',
    selector: row => <TextCell text={row.details} bubbleClickEvent />,
    minWidth: '300px',
  },
  {
    name: 'Count',
    selector: row => <FormatNumberCell number={row.count} bubbleClickEvent />,
  },
  {
    name: 'Last Seen',
    cell: row => <TimestampCell timestamp={row.lastSeen} bubbleClickEvent />,
  },
];

export default schemaViolationsDetailsColumns;
