import isConsentCategoryChanged from './isConsentCategoryChanged';

export default function mergeConsentCategories({ pipelineIntegrationConsent, deployedIntegrationConsent }) {
  const pipelineIntegrationConsentCategory = pipelineIntegrationConsent?.consentCategory;
  const isChanged = isConsentCategoryChanged({ pipelineIntegrationConsent, deployedIntegrationConsent });

  return {
    isChanged,
    local: {
      ...pipelineIntegrationConsentCategory,
    },
    deployed: {
      ...(deployedIntegrationConsent && {
        code: deployedIntegrationConsent,
      }),
    },
  };
}
