import { useCallback, useEffect } from 'react';
import { useOpen } from '@metarouter-private/mr-mui';

export default function useDrawerProps({ report, clearReport }) {
  const { open, onOpen, onClose } = useOpen();

  const onCloseWrapper = useCallback(() => {
    onClose();
    clearReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (report) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return {
    open,
    onCloseWrapper,
  };
}
