import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { routes } from 'app/constants';
import { MrButton, MrButtonList, MrH, MrLink, MrText } from '@ion/components';

export const NoIntegrations = ({ onCancel }) => {
  const history = useHistory();

  const onSubmit = () => {
    history.push(routes.newIntegration);
  };

  return (
    <>
      <div className="header">
        <MrH h="h2" sans styleNames="noMargin">
          Add New Integration
        </MrH>
      </div>
      <div className="content">
        <MrText>Looks like you don&apos;t have any integrations available, let&apos;s add one now.</MrText>

        <MrButtonList
          styleNames="marginTop"
          buttons={[
            <MrButton text={'Add Integration'} key="create" onClick={onSubmit} />,
            <MrLink onClick={onCancel} styleNames="gray" key="cancel">
              Cancel
            </MrLink>,
          ]}
        />
      </div>
    </>
  );
};
NoIntegrations.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
