import React from 'react';
import PropTypes from 'prop-types';

import { Container, Drawer, Stack, Typography, Box } from '@metarouter-private/mr-mui';

import ReportDetails from 'app/event-monitoring/event-schemas/panel/report-details';
import SchemaPanelState from 'app/event-monitoring/event-schemas/panel/state';
import GetEventSchemaDetails from 'app/event-monitoring/event-schemas/panel/GetEventSchemaDetails';
import SchemaTree from 'app/event-monitoring/event-schemas/panel/tree';
import PipelineSelect from 'app/event-monitoring/event-schemas/panel/pipeline-select';
import Export from 'app/event-monitoring/event-schemas/panel/export';
import handleArrayOf from './handle-array-of';
import useDrawerProps from './useDrawerProps';

const ReportPanel = ({ cluster, earliestSeen, timeframeHours, report, clearReport }) => {
  const { open, onCloseWrapper } = useDrawerProps({ report, clearReport });

  const state = SchemaPanelState(report);
  const properties = handleArrayOf(state?.details)?.properties;

  return (
    <Drawer anchor="right" onClose={onCloseWrapper} open={open} width="60%" data-test-id="MrRightPanel">
      <Container>
        <GetEventSchemaDetails
          cluster={cluster}
          earliestSeen={earliestSeen}
          eventName={report.eventName}
          setSchemaDetails={state.setDetails}
          setLoadingDetails={state.loadingDetails}
          filteredPipelines={state.filteredPipelines}
        />
        <Stack justifyContent="space-between" direction="row" mt={2} useFlexGap spacing={2}>
          <Typography variant="h4" component="div" title={report.eventName} noWrap>
            {report.eventName} Event Schema
          </Typography>
          <Stack direction="row" spacing={2}>
            <Box sx={{ width: 250 }}>
              <PipelineSelect
                pipelines={report.pipelines}
                setFilterPipelines={state.setFilterPipelines}
                filteredPipelines={state.filteredPipelines}
                setShow={state.setShow}
                loadingDetails={state.isLoadingDetails}
              />
            </Box>
            {state.details?.properties && <Export eventName={report.eventName} properties={state.details.properties} />}
          </Stack>
        </Stack>

        {state.show && <ReportDetails details={state} timeframeHours={timeframeHours} />}
        {state.show && properties && <SchemaTree properties={properties} />}
      </Container>
    </Drawer>
  );
};

ReportPanel.propTypes = {
  cluster: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  earliestSeen: PropTypes.string.isRequired,
  timeframeHours: PropTypes.number.isRequired,
  report: PropTypes.shape({
    combinations: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    lastSeen: PropTypes.instanceOf(Date).isRequired,
    totalCount: PropTypes.string.isRequired,
    pipelines: PropTypes.array.isRequired,
  }).isRequired,
  clearReport: PropTypes.func.isRequired,
};

export default ReportPanel;
