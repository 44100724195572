import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const SchemaEnforcementTableContext = createContext(null);

export default function SchemaEnforcementTableContextProvider({ children, pipeline }) {
  return (
    <SchemaEnforcementTableContext.Provider value={{ pipeline }}>{children}</SchemaEnforcementTableContext.Provider>
  );
}

export function useSchemaEnforcementTableContext() {
  return useContext(SchemaEnforcementTableContext);
}

SchemaEnforcementTableContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  pipeline: PropTypes.object.isRequired,
};
