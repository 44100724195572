import { useTrackWithUser, isAllowedToReadEvents } from '@ion/api';
import { useDrawerContext } from 'app/shared/contexts/drawer';
import useFeatureEnabled from 'app/launch-darkly/use-feature-enabled';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

export default function useOnRowClick() {
  const [schemaViolationEventsDrawerViewed] = useTrackWithUser('schema_violation_events_drawer_viewed');
  const { schemaViolationEventsMvpEnabled } = useFeatureEnabled(FEATURE_FLAGS.schemaViolationEventsMvp);
  const { launchDrawer } = useDrawerContext();
  const hasPermission = isAllowedToReadEvents();

  return row => {
    if (hasPermission && schemaViolationEventsMvpEnabled) {
      schemaViolationEventsDrawerViewed();
      launchDrawer({ ...row });
    }
  };
}
