import React from 'react';

import PipelineOverviewStaticTable from 'app/pipeline-details/PipelineOverviewStaticTable';

import useStatusTable from './useStatusTable';

export default function StatusTable() {
  const props = useStatusTable();

  return <PipelineOverviewStaticTable {...props} />;
}
