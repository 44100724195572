import { debounce } from 'lodash';

import { usePipelinesTableContext } from 'app/pipelines/PipelinesTable/PipelinesTableContext';

export default function usePipelineSearchBarOnChange() {
  const { setSearchTerm } = usePipelinesTableContext();

  const onChange = debounce(event => setSearchTerm(event.target.value), 300);

  return onChange;
}
