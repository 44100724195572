import useMutation from 'api/hooks/useMutation';
import { mutation, buildVariables } from './audit-log-mutation-builder';

export default function useAuditLogAJSFileUploaded(apolloOptions = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...apolloOptions,
  });

  const insertAuthLoggedInMutation = ({ writeKey }) => {
    return executeMutation({
      variables: {
        ...buildVariables({
          writeKey,
        }),
      },
    });
  };

  return [insertAuthLoggedInMutation, response];
}
