import React from 'react';
import PropTypes from 'prop-types';

import { MrText } from '@ion/components';
import allowRowClick from './allow-row-click';

const RevisionCell = row => {
  const styleNames = determineStyleNames(row);
  const textProps = {
    component: 'span',
    styleNames,
    ...allowRowClick,
  };

  if (row.stagedForDelete) {
    return <MrText {...textProps}>Staged for delete</MrText>;
  }

  return <MrText {...textProps}>Revision {row.stagedRevision}</MrText>;
};

RevisionCell.propTypes = {
  row: PropTypes.object,
};

export default RevisionCell;

export function determineStyleNames(row) {
  if (row.stagedForDelete) {
    return 'sm italic';
  }

  const isSemiBold = row.deployedRevision && row.deployedRevision !== row.stagedRevision;

  if (isSemiBold) {
    return 'sm semiBold';
  }

  return 'sm';
}
