import { isEqual } from 'lodash';
import { useFetchAllEventNames } from 'api';

export default function useValidateUniqueName(defaultValue) {
  const getAllEvents = useFetchAllEventNames();

  async function validateUniqueName(name) {
    const shouldSkipValidation = isEqual(name, defaultValue);

    if (shouldSkipValidation) {
      return true;
    }

    try {
      const { error, data } = await getAllEvents();

      if (error) {
        return 'Unable to validate at the moment';
      }

      if (data) {
        return data.includes(name) ? 'This event name is already in use' : true;
      }

      return true; // Valid name
    } catch {
      return 'Unable to validate at the moment';
    }
  }

  return validateUniqueName;
}
