import React from 'react';
import PropTypes from 'prop-types';

import { KebabMenu } from '@metarouter-private/mr-mui';
import isAllowedToWritePipeline from '@ion/api/pipelines/isAllowedToWritePipeline';

import useBuildMenuItems from './useBuildMenuItems';

export default function PipelineActionsKebabMenu({ row }) {
  const hasPermission = isAllowedToWritePipeline();
  const menuItems = useBuildMenuItems(row);

  return <KebabMenu disabled={!hasPermission} items={menuItems} testId="pipelineActionsKebabMenu" />;
}

PipelineActionsKebabMenu.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
