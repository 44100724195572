import { useState, useEffect } from 'react';

/*
  Sometimes we have to use a mutation for something that is really a
  query and should be executed like how useQuery immediately executes
  the API call. This hook takes a mutation and executes it immediately
  and if any of the arguments change.
 */
export default function useMutationAsQuery({ mutation: useMutation, options = {}, args = [] }) {
  const [loading, setLoading] = useState(true);

  const finalOptions = buildOptions({ options, setLoading });

  const [executeMutation, response] = useMutation(finalOptions);

  useEffect(() => {
    setLoading(true);
    executeMutation(...args);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [...args]);

  return { ...response, loading };
}

function buildOptions({ options, setLoading }) {
  return {
    ...options,
    onCompleted: response => {
      setLoading(false);
      options.onCompleted && options.onCompleted(response);
    },
    onError: response => {
      setLoading(false);
      options.onError && options.onError(response);
    },
  };
}
