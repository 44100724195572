import React from 'react';
import { ALLOW_ROW_EVENTS } from 'app/components/data-table/buildBubbleClickProps';
import ClickableElement from 'app/components/data-table/ClickableElement';
import violationChipColorCoding from './violationChipColorCoding';

const STATIC_PROPS = {
  variant: 'soft',
  ...ALLOW_ROW_EVENTS,
};

export default function buildViolationChipProps({ type }) {
  const color = violationChipColorCoding[type];

  return {
    ...STATIC_PROPS,
    label: <ClickableElement>{type}</ClickableElement>,
    color,
  };
}
