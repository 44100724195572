import { capitalize as _capitalize } from 'lodash';
import { toString as _toString } from 'lodash';

/**
 *
 * @param {string} error
 * @returns {string}
 */
export function formatError(error) {
  let formattedError = formatMessage(error);
  try {
    const parsedError = JSON.parse(formattedError);
    const parsedErrorDetails = getErrorDetails(parsedError);
    let errorMessage = _capitalize(parsedError.error);

    if (parsedErrorDetails) {
      errorMessage = formatErrorParts(parsedErrorDetails);
    }

    formattedError = errorMessage;
  } catch {
    return error;
  }

  return formattedError;
}

/**
 *
 * @param {string} error
 * @param {string} defaultTitle
 * @returns {string}
 */
export function errorTitle(error, defaultTitle = 'Error') {
  const formattedErrorMessage = formatMessage(error);
  try {
    const parsedError = JSON.parse(formattedErrorMessage);
    const parsedErrorDetails = getErrorDetails(parsedError);
    const errorMessage = parsedErrorDetails?.title || defaultTitle;
    return errorMessage.endsWith('.') ? errorMessage : errorMessage + '.';
  } catch (e) {
    return defaultTitle;
  }
}

/**
 * @typedef {Object} ErrorDetails
 * @property {string} [title] - The title of the error.
 * @property {string} [cause] - The cause of the error.
 * @property {string} [fix] - The suggested fix for the error.
 * @property {string} [way_out] - Additional assistance information.
 */
function formatErrorParts(details) {
  const parts = [_capitalize(details.cause), _capitalize(details.fix), _capitalize(details.way_out)].filter(Boolean); // Filter out falsy values

  return parts.map(part => (part.endsWith('.') ? part : part + '.')).join(' ');
}

/**
 *
 * @param {Object} error - The gRPC error object.
 * @param {string} error.error - The error message.
 * @param {number} error.code - The error code.
 * @param {string} error.description - The description of the error.
 * @param {ErrorDetails[]} [error.details] - An array of additional error details.
 * @returns {ErrorDetails|undefined}
 * */
function getErrorDetails(error) {
  return error?.details?.[0] ?? undefined;
}

/**
 *
 * @param {string} message
 * @returns {string}
 */
function formatMessage(message) {
  const result = _toString(message).split('response: ').pop();
  return result ?? '';
}
