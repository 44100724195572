import React from 'react';
import { Grid, IconButton, Typography, XCloseIcon } from '@metarouter-private/mr-mui';
import { useDrawerContext } from 'app/shared/contexts/drawer';
import { useViolationEventsDrawerContext } from './ViolationEventsDrawerContextProvider';

export default function ViolationEventsDrawerHeader() {
  const {
    drawer: { onClose },
  } = useDrawerContext();

  const headerText = useHeaderText();

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h4">{headerText}</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={onClose}>
          <XCloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

function useHeaderText() {
  const { loading, violationEvents } = useViolationEventsDrawerContext();
  if (loading) {
    return '';
  }
  return `Last ${violationEvents.length} events with this violation`;
}
