import React from 'react';
import pt from 'prop-types';

import { MrText, formValidators } from '@ion/components';

import GatedCodeEditor from 'app/components/GatedCodeEditor';
import { useFormContext } from 'app/context/form-context';
import s from './index.module.scss';

const { validateRequired } = formValidators;

const Expression = ({ lang = 'lua', body, opId, _path }) => {
  const { setField, validateField, formState, resetError } = useFormContext();

  return (
    <>
      <MrText styleNames="right">
        Looking for help with expressions? Check out our{' '}
        <a
          className={s.link}
          href="https://docs.metarouter.io/docs/custom-expressions"
          target="_blank"
          rel="noreferrer"
        >
          docs
        </a>
        .
      </MrText>

      {((name = `${opId}-body-${_path}`) => (
        <GatedCodeEditor
          name={name}
          value={body}
          onBlur={validateField}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          lang={lang}
          validators={[validateRequired()]}
          onInput={resetError}
        />
      ))()}

      <input name={`${opId}-lang-${_path}`} type="hidden" value={lang} />
    </>
  );
};

Expression.propTypes = {
  lang: pt.string,
  body: pt.string,
  depth: pt.number,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default Expression;
