export default {
  REQUEST_NAME: 'fpc',
  REQUEST_INPUT_NAME: 'cookieSettings',
  SINGLE_SELECT_DESCRIPTION: 'First Party Cookie',
  DESCRIPTION:
    'Use this sync injector to sync first party cookies from any domain where the tag is placed. Configure it with cookie names and optional poll delays to control when cookies are read.',
  FIELDS: [
    {
      type: 'TEXT',
      property: 'cookieName',
      title: 'Cookie Name',
      description: 'The name of the cookie that should be used to get the data',
      required: true,
      default: '',
      placeholder: 'e.g., cookieName',
    },
    {
      type: 'NUMBER',
      property: 'pollDelay',
      title: 'Poll Delay',
      description: 'The delay in milliseconds before retrying to read the cookie for a second time',
      required: false,
      default: 0,
      placeholder: 'e.g., 300',
    },
  ],
};
