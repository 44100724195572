import React from 'react';
import { Box, CircularProgress } from '@metarouter-private/mr-mui';

export default function EventsLoading() {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" height="100%" width="100%" p={2}>
      <CircularProgress />
    </Box>
  );
}
