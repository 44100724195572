import React from 'react';
import PropTypes from 'prop-types';
import { SimpleCarouselItem } from '@metarouter-private/mr-mui';
import GatedCodeEditor from 'app/components/GatedCodeEditor';

const STATIC_PROPS = {
  disabled: true,
  lang: 'json',
  rows: 30,
  name: 'event-carousel-item',
};

export default function EventCarouselItem({ event }) {
  const value = JSON.stringify(event, null, 2);
  return (
    <SimpleCarouselItem p={1} width="750px">
      <GatedCodeEditor {...STATIC_PROPS} value={value} />
    </SimpleCarouselItem>
  );
}

EventCarouselItem.propTypes = {
  event: PropTypes.object.isRequired,
};
