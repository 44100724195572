import useQuery from 'api/hooks/useQuery';
import { gql } from '@apollo/client';
import permissions from 'api/Apollo/permissions';

export const query = gql`
  query Clusters {
    clusters(where: { deletedAt: { _is_null: true } }, order_by: { createdAt: desc }) {
      id
      name
      url
      deletedAt
      metricNames
      pipelines(where: { deletedAt: { _is_null: true } }, order_by: { name: asc }) {
        id
        name
        writekey
      }
      connectedPipelines @client
    }
  }
`;

export default function useQueryClusters({ variables, ...options } = {}) {
  const { data, loading, error, isAllowed } = useQuery(query, {
    variables, // can apply any transforms or default vars here
    context: { permission: permissions.readCluster },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.clusters || [],
    loading,
    isAllowed,
    error,
  };
}
