import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../../launch-darkly/is-feature-enabled';

import GROUP_BY_OPTIONS from '../../group-by-options';

import buildIntegrationColumns from './buildIntegrationColumns';
import buildPipelineColumns from './buildPipelineColumns';
import buildEventColumns from './build-event-columns';

export const ECM_DASHBOARD_SHOW_PIPELINE_FILTERS_FLAG = 'ecmDashboardShowPipelineFilters';

const useBuildColumns = ({ groupBy }) => {
  const featureFlags = useFlags();
  const showPipelineFilters = isFeatureEnabled({ featureFlags, flagName: ECM_DASHBOARD_SHOW_PIPELINE_FILTERS_FLAG });

  const mainTableColumns = useMemo(() => {
    if (groupBy === GROUP_BY_OPTIONS.INTEGRATION) {
      return buildIntegrationColumns({ showPipelineFilters });
    }

    if (groupBy === GROUP_BY_OPTIONS.PIPELINE) {
      return buildPipelineColumns({ showPipelineFilters });
    }
  }, [showPipelineFilters, groupBy]);

  const subTableColumns = useMemo(() => {
    return buildEventColumns({ showPipelineFilters });
  }, [showPipelineFilters]);

  return {
    mainTableColumns,
    subTableColumns,
  };
};

export default useBuildColumns;
