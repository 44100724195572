import React from 'react';
import { Drawer, Stack, Typography } from '@metarouter-private/mr-mui';
import GatedCodeEditor from 'app/components/GatedCodeEditor';
import stringify from 'json-stringify-pretty-compact';
import PropTypes from 'prop-types';

export default function EventDrawer({ title, event, lang, onClose, open }) {
  const value = event ? formatEventContent(event, lang) : '';
  return (
    <Drawer anchor="right" onClose={onClose} open={open} width="60%">
      <Stack padding={3} spacing={2}>
        <Typography variant="h3">{title}</Typography>
        <GatedCodeEditor rows={30} name="event-drawer-event" disabled lang={lang} value={value} />
      </Stack>
    </Drawer>
  );
}

function formatEventContent(event, lang) {
  switch (lang) {
    case 'json':
      return stringify(JSON.parse(event));
    default:
      return event;
  }
}

EventDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
