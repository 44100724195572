import { gql } from '@apollo/client';
import buildLimitAndOffset from 'api/pagination/buildLimitAndOffset';
import eventName from 'api/event-names/eventName';

export const EVENT_NAMES_QUERY = `EventNames`;

export const GET_EVENT_NAMES = gql`
    query ${EVENT_NAMES_QUERY}(
    $limit: Int!
    $offset: Int!
    ) {
        eventNamesAggregate: event_names_aggregate {
            aggregate {
                count
            }
        }
        eventNames: event_names(limit: $limit, offset: $offset, order_by: {updatedAt: asc}) {
            ...eventName
            schemaLibraryEventSchemasAggregate: schema_library_event_schemas_aggregate(where: { schema_library_version: { schema_library: { deletedAt: {_is_null: true}}}}) {
                aggregate {
                    count
                }
            }
        }
    }
    ${eventName}
`;

export function buildVariables(pagination) {
  return {
    ...buildLimitAndOffset(pagination),
  };
}
