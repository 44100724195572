import { fromPairs, uniqBy, isEmpty } from 'lodash';

export default function getDeployedPipelineVariables({ deployedIntegration }) {
  if (isEmpty(deployedIntegration)) {
    return [];
  }

  const connectionParams = deployedIntegration.connection?.params || [];
  const endpointSchemaParams = deployedIntegration.endpointSchema?.params || [];
  const playbookParams = deployedIntegration.transformStages[0].data.playbook.params || [];

  const uniquePipelineVariables = uniqBy([...connectionParams, ...endpointSchemaParams, ...playbookParams], 'name');

  return fromPairs(uniquePipelineVariables.map(variable => [variable.name, variable.defaultValue]));
}
