import { fetchSync, localStorage, firstPartyCookie } from './customSyncs';

export const hydrateFromDB = (dbData, syncName) => {
  try {
    switch (syncName) {
      case fetchSync.REQUEST_NAME:
        return JSON.parse(dbData).map((k, index) => {
          return {
            name: k.name,
            id: index,
            cookieSuccessAge: k.fetchSettings.cookieSuccessAge,
            cookieFailureAge: k.fetchSettings.cookieFailureAge,
            url: k.fetchSettings.url,
            method: k.fetchSettings.method,
            maxTimeout: k.fetchSettings.maxTimeout,
            jsonPathIdentities: k?.jsonPathIdentities?.map(j => j.jsonPath.join('.')).toString(),
            headerIdentities: k?.headerIdentities?.map(h => h.name).toString(),
          };
        });
      case localStorage.REQUEST_NAME:
        return JSON.parse(dbData).map((k, index) => ({
          name: k,
          id: index,
        }));
      case firstPartyCookie.REQUEST_NAME:
        return JSON.parse(dbData).map((k, index) => ({
          cookieName: k.cookieName,
          pollDelay: k.pollDelay || 0,
          id: index,
        }));
      default:
        return JSON.parse(dbData);
    }
  } catch (e) {
    return [{ id: 0 }];
  }
};
