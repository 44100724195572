import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { MrIcon } from '@ion/components';
import { useStageRevisionForDelete as useUndoStageRevisionForDelete } from '@ion/api';

export default function UndoStagedForDelete(row) {
  const [undoStageRevisionForDelete] = useUndoStageRevisionForDelete();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const undoStageRevisionForDeleteCallback = useCallback(undoStageRevisionForDelete, []);

  const { stagedRevisionId } = row;

  return (
    <MrIcon
      id="icon-flip-backward"
      onClick={() => {
        undoStageRevisionForDeleteCallback({
          id: stagedRevisionId,
          stagedForDelete: false,
        });
      }}
      title="Undo staged for delete"
    />
  );
}

UndoStagedForDelete.propTypes = {
  row: PropTypes.object,
};
