import React from 'react';
import AceEditor from 'react-ace';
import buildAceEditorProps from './build-ace-editor-props';

import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-lua';
import 'ace-builds/src-noconflict/mode-yaml';

import './ace-theme.scss';

export default function BaseAceCodeEditor(props) {
  const aceEditorProps = buildAceEditorProps(props);
  return <AceEditor {...aceEditorProps} />;
}

BaseAceCodeEditor.propTypes = {
  lang: AceEditor.propTypes.mode,
  onChange: AceEditor.propTypes.onChange,
  value: AceEditor.propTypes.value,
  defaultValue: AceEditor.propTypes.defaultValue,
  options: AceEditor.propTypes.setOptions,
  rows: AceEditor.propTypes.maxLines,
  width: AceEditor.propTypes.width,
};
