import { useMemo } from 'react';
import parseDeployedIntegrations from './parseDeployedIntegrations';

export default function useDeployedRevisions({ pipeline, deployedPipeline, integrationsData }) {
  return useMemo(() => {
    if (pipeline && deployedPipeline) {
      return parseDeployedIntegrations({
        deployedIntegrations: deployedPipeline?.integrations ?? [],
        pipelineIntegrations: pipeline?.pipelineIntegrations,
        integrationsData,
      });
    }
    return [];
  }, [deployedPipeline, pipeline, integrationsData]);
}
