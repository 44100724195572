import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MrIcon } from '@ion/components';

import ConfirmUnstageDeployedIntegration from 'app/pipeline-details/ConfirmUnstageDeployedIntegration';

export default function StageForDelete(row) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { stagedRevisionId, integration } = row;

  return (
    <>
      <MrIcon id="icon-trash" onClick={() => setConfirmDelete(true)} title="Stage for delete" />
      {confirmDelete && (
        <ConfirmUnstageDeployedIntegration
          closeModal={() => setConfirmDelete(false)}
          integration={integration}
          stagedRevisionId={stagedRevisionId}
        />
      )}
    </>
  );
}

StageForDelete.propTypes = {
  row: PropTypes.object,
};
