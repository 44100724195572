import { useQuery } from './../index';
import { gql } from '@apollo/client';

import integration from './integration';
import permissions from '../Apollo/permissions';
/**
 *  Get Integrations for current org
 */
export const query = gql`
  query Integrations($limit: Int = 1) {
    integrations(where: { deletedAt: { _is_null: true } }, order_by: { createdAt: desc }) {
      ...integration
      playbooks(order_by: { revision: desc }, limit: $limit) {
        id
        revision
        playbookJson
        connectionJson
        vars
        pipeline_variables {
          id
          playbookId
          name
        }
      }
    }
  }
  ${integration}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, error, isAllowed, refetch } = useQuery(query, {
    context: { permission: permissions.readIntegration },
    variables, // can apply any transforms or default vars here
    fetchPolicy: 'cache-and-network',
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.integrations || [],
    loading,
    isAllowed,
    error,
    refetch,
  };
};
