import useTrackWithUser from 'api/hooks/useTrackWithUser';
import { useEffect } from 'react';

// if the user has not logged the feature discovery event, log it
const useLogFeatureDiscovery = (eventName, shouldLog = false) => {
  const [trackWithUser] = useTrackWithUser(eventName);

  useEffect(() => {
    const hasSeenFeatureDiscovery = localStorage.getItem(eventName);
    if (!hasSeenFeatureDiscovery && shouldLog) {
      localStorage.setItem(eventName, 'true');
      trackWithUser();
    }
  }, [eventName, shouldLog, trackWithUser]);
};

export default useLogFeatureDiscovery;
