import React from 'react';

import ActionCell from 'app/pipeline-details/PipelineIntegrationsTable/cells/ActionCell';
import RevisionCell from 'app/pipeline-details/PipelineIntegrationsTable/cells/RevisionCell';
import IntegrationCell from 'app/pipeline-details/PipelineIntegrationsTable/cells/integration/IntegrationCell';
import ConsentCategoryCell from 'app/pipeline-details/PipelineIntegrationsTable/cells/ConsentCategoryCell';
import PipelineVariablesCell from 'app/pipeline-details/PipelineIntegrationsTable/cells/PipelineVariablesCell';
import { conditionalCellStyles } from 'app/pipeline-details/PipelineIntegrationsTable/conditional-cell-styles';

export const columns = [
  {
    name: 'Integration Name',
    selector: row => row.integration.name,
    cell: row => <IntegrationCell integration={row.integration} />,
    conditionalCellStyles: conditionalCellStyles,
  },
  {
    name: 'Revision',
    cell: row => <RevisionCell {...row} />,
    conditionalCellStyles: conditionalCellStyles,
  },
  {
    name: 'Pipeline Variables',
    selector: row => <PipelineVariablesCell {...row} />,
    conditionalCellStyles: conditionalCellStyles,
  },
];

export const ActionColumn = {
  cell: row => <ActionCell {...row} />,
  button: true,
  width: '77px',
};

export const CategoryColumn = {
  name: 'Consent Category',
  selector: row => <ConsentCategoryCell {...row} />,
  conditionalCellStyles: conditionalCellStyles,
};
