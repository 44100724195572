import { useState, useEffect } from 'react';
import { lint } from '@exodus/schemasafe';

import { useFormContext } from '@metarouter-private/mr-mui';

export default function useSchemaValidator() {
  const [strictMode, setStrictMode] = useState(false);
  const { trigger, formState } = useFormContext();

  useEffect(() => {
    if (formState.dirtyFields.jsonSchema) {
      trigger('jsonSchema');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strictMode]);

  const validateSchema = jsonSchema => {
    let schema;

    try {
      schema = JSON.parse(jsonSchema);
    } catch {
      return 'Invalid JSON object';
    }

    const mode = strictMode ? 'strong' : 'lax';
    const lintErrors = lint(schema, { mode });

    if (!lintErrors.length) {
      return true;
    }

    return lintErrors.map(error => error.message).join('\n\n');
  };

  const onModeChange = event => {
    const { checked } = event.target;
    setStrictMode(checked);
  };

  return { validateSchema, onModeChange };
}
