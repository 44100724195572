import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({
  pipelineId,
  pipelineName,
  schemaLibraryVersionId,
  schemaLibraryName,
  schemaLibraryVersion,
}) {
  const action = actions.schema_library_added_to_pipeline;
  const description = `${schemaLibraryName} (${schemaLibraryVersion}) added to ${pipelineName}`;
  const debug_data = {
    schemaLibraryVersionId,
    schemaLibraryName,
    pipelineId,
    pipelineName,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
