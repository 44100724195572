import React from 'react';
import PropTypes from 'prop-types';

import { MrBack } from '@ion/components';

import { routes } from 'app/constants';

export default function BackToPipelinesButton({ pipelineId, clusterId, writekey, name }) {
  const getBackRoute = () => {
    return location.pathname === routes.analyticsJSBuilder
      ? routes.pipelines
      : { pathname: routes.analyticsJSBuilder, state: { pipelineId, clusterId, writekey, name } };
  };

  return <MrBack to={getBackRoute()} />;
}

BackToPipelinesButton.propTypes = {
  pipelineId: PropTypes.string.isRequired,
  clusterId: PropTypes.string.isRequired,
  writekey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
