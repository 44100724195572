import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftIcon } from '@metarouter-private/mr-mui';
import LinkButton from 'app/shared/navigation/LinkButton';

const DEFAULT_BUTTON_PROPS = {
  variant: 'text',
  color: 'secondary',
  size: 'large',
  startIcon: <ArrowLeftIcon />,
};

export default function BackButton({ children, to, ...props }) {
  return (
    <LinkButton {...DEFAULT_BUTTON_PROPS} to={to} {...props}>
      {children}
    </LinkButton>
  );
}

BackButton.propTypes = {
  children: PropTypes.node.isRequired,
  ...LinkButton.propTypes,
};
