import React from 'react';
import { MrAppLayout, MrAppMain } from '@ion/components';
import SideNav from 'app/components/side-nav';
import RedirectFeatureGate from 'app/launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import { useTrackPage } from '@ion/api';
import EventSchemaViolationsPageContextProvider from './EventSchemaViolationsPageContextProvider';
import EventSchemaViolationsHeader from './EventSchemaViolationsHeader';
import SchemaViolationsDetailsTableSection from './SchemaViolationsDetailsTableSection';
import BackToSchemaViolationsButton from './BackToSchemaViolationsButton';

export default function EventSchemaViolationsPage() {
  useTrackPage('schema_violation_details_viewed');
  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
      <MrAppLayout title="Event Schema Violations" sideNav={<SideNav />}>
        <MrAppMain>
          <EventSchemaViolationsPageContextProvider>
            <BackToSchemaViolationsButton />
            <EventSchemaViolationsHeader />
            <SchemaViolationsDetailsTableSection />
          </EventSchemaViolationsPageContextProvider>
        </MrAppMain>
      </MrAppLayout>
    </RedirectFeatureGate>
  );
}
