import React from 'react';
import LinkButton from 'app/shared/navigation/LinkButton';

import { routes } from 'app/constants';
import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

const DEFAULT_BUTTON_PROPS = {
  variant: 'outlined',
  color: 'secondary',
  size: 'medium',
};

export default function SchemaViolationsButton() {
  const { pipeline } = usePipelinePageContext();

  const linkProps = {
    to: {
      pathname: routes.schemaViolations,
      state: { pipeline },
    },
  };
  return (
    <LinkButton {...DEFAULT_BUTTON_PROPS} {...linkProps}>
      Schema Violations
    </LinkButton>
  );
}
