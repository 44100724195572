import React from 'react';

import { useRemovePipelineSchemaLibrary } from '@ion/api';

import { useSchemaEnforcementTableContext } from 'app/pipeline-details/SchemaEnforcementTableContextProvider';

import ConfirmRemovePipelineSchemaLibraryMessage from './ConfirmRemovePipelineSchemaLibraryMessage';

const STATIC_PROPS = {
  title: 'Remove Schema Library from Pipeline',
  confirmLabel: 'Remove',
};

export default function useConfirmRemovePipelineSchemaLibrary({ id, name, version, onClose }) {
  const { pipeline } = useSchemaEnforcementTableContext();
  const { name: pipelineName, writekey, clusterId } = pipeline || {};

  const [removePipelineSchemaLibrary] = useRemovePipelineSchemaLibrary({ onCompleted: onClose });

  const onConfirm = () => {
    removePipelineSchemaLibrary({ id, schemaLibraryName: name, pipelineName, writekey, clusterId });
  };

  return {
    ...STATIC_PROPS,
    message: <ConfirmRemovePipelineSchemaLibraryMessage name={name} version={version} />,
    onConfirm,
    onClose,
  };
}
