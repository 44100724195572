import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import { pipelinesTableColumns } from './pipelinesTableColumns';
import usePipelinesTable from './usePipelinesTable';
import PipelinesTableTitle from './PipelinesTableTitle';

export default function PipelinesTable() {
  const pipelinesTable = usePipelinesTable();

  return (
    <PageReactDataTable
      columns={pipelinesTableColumns}
      title={<PipelinesTableTitle />}
      sortServer
      {...pipelinesTable}
    />
  );
}
