import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmDelete } from '@metarouter-private/mr-mui';

import useConfirmRemovePipelineSchemaLibrary from './useConfirmRemovePipelineSchemaLibrary';

export default function ConfirmRemovePipelineSchemaLibraryModal({ id, name, version, onClose, ...modalProps }) {
  const confirmDeleteProps = useConfirmRemovePipelineSchemaLibrary({ id, name, version, onClose });
  return <ConfirmDelete {...confirmDeleteProps} {...modalProps} />;
}

ConfirmRemovePipelineSchemaLibraryModal.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
