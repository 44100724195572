import React from 'react';
import { useHistory } from 'react-router-dom';

import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import BackToPipelineOverviewButton from 'app/components/BackToPipelineOverviewButton';

import BackToPipelinesButton from './BackToPipelinesButton';

export default function BackButton() {
  const history = useHistory();
  const {
    location: {
      state: { pipeline },
    },
  } = history;

  return (
    <FeatureGate flagName={FEATURE_FLAGS.pipelinesListRevamp} fallback={<BackToPipelinesButton />}>
      <BackToPipelineOverviewButton pipelineId={pipeline?.id} />
    </FeatureGate>
  );
}
