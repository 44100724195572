import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Edit03Icon, IconButton } from '@metarouter-private/mr-mui';
import isAllowedToWriteSchemaLibrary from '@ion/api/schema-libraries/isAllowedToWriteSchemaLibrary';

import { routes } from 'app/constants';

export default function EditEventSchemasButton({ row }) {
  const hasPermission = isAllowedToWriteSchemaLibrary();

  const linkProps = {
    component: Link,
    to: {
      pathname: routes.schemaLibrary,
      state: { schemaLibrary: row },
    },
  };

  return (
    <IconButton data-test-id="editEventSchemasButton" disabled={!hasPermission} {...linkProps}>
      <Edit03Icon />
    </IconButton>
  );
}

EditEventSchemasButton.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};
