import React, { useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import pt from 'prop-types';
import cx from 'classnames';
import { routes } from 'app/constants';
import { startCase as _startCase, isEmpty as _isEmpty } from 'lodash';

import {
  useDiffPipelines,
  useDeployPipeline,
  useQueryPipelines,
  useQueryIntegrations,
  useRemovePipelineIntegrations,
} from '@ion/api';
import { MrAppLayout, MrAppMain, MrH, MrText, MrButton, MrTable, MrLinkButton, MrModal, MrIcon } from '@ion/components';
import SideNav from 'app/components/side-nav';

import { useUserContext } from '@ion/user';

import buildPipelineJson from 'app/pipeline-details/DeployModal/buildPipelineJson';
import generatePipelineDiff from './generatePipelineDiff';
import initiateBulkDeploy from './initiateBulkDeploy';

import close from '../../assets/icon-close.svg';
import arrow from 'app/playbook-editor/parameters/editor-panel/parameter/assets/icon-chevron.svg';

import s from './index.module.scss';
import PropTypes from 'prop-types';

const BulkDeployPipelines = ({ pipelines, pipelinesFilter, pipelinesTransform }) => {
  const [integrations, setIntegrations] = useState({});
  const [waiting, setWaiting] = useState(true);
  const [expandedPipeline, setExpandedPipeline] = useState('');
  const [expandedDiff, setExpandedDiff] = useState('');
  const [deploying, setDeploying] = useState(false);
  const [deployResults, setDeployResults] = useState();
  const [removePipelineIntegrations] = useRemovePipelineIntegrations();

  const { user } = useUserContext();
  const [getDiff] = useDiffPipelines();
  const { data: allPipelines, loading: loadingPipelines } = useQueryPipelines();
  const { data: allIntegrations, loading: loadingIntegrations } = useQueryIntegrations();

  let diffPipelines = [];
  if (!loadingPipelines && _isEmpty(diffPipelines)) {
    diffPipelines = allPipelines.filter(p => pipelines.includes(p.id));
  }

  if (!loadingPipelines && !deployResults && !_isEmpty(diffPipelines)) {
    let initialDeployState = [];
    diffPipelines.forEach(diffPipeline => {
      initialDeployState.push({
        pipelineId: diffPipeline.id,
        name: diffPipeline.name,
        deployStatus: 'Pending...',
        deployIcon: 'none',
      });
    });
    setDeployResults(initialDeployState);
  }

  if (!loadingPipelines && !loadingIntegrations && !_isEmpty(diffPipelines) && waiting) {
    diffPipelines.forEach(p => {
      generatePipelineDiff(
        p,
        getDiff,
        integrations,
        allIntegrations,
        setIntegrations,
        user?.email,
        setWaiting,
        diffPipelines.length
      );
    });
  }

  const [deploy, { isAllowed: deployPipelinePermission }] = useDeployPipeline({
    onCompleted: () => {},
    onError: err => {
      console.log('err', err);
    },
  });

  if (!deploying) {
    return (
      <MrAppLayout
        header={
          <div className={s.header}>
            <MrLinkButton className={s.close} to={routes.pipelines}>
              <img src={close} />
              Close
            </MrLinkButton>

            <MrText className={s.title}>
              <MrIcon id={'icon-bulk-deploy'} />
              <span className={s.titleText}>Bulk Stage and Deploy</span>
            </MrText>

            <MrButton
              testId={'confirmBulkDeploy'}
              text={`Confirm & Deploy`}
              disabled={!deployPipelinePermission || waiting}
              onClick={() => {
                initiateBulkDeploy(
                  pipelines,
                  allPipelines,
                  user?.email,
                  setDeploying,
                  buildPipelineJson,
                  deploy,
                  setDeployResults,
                  removePipelineIntegrations,
                  pipelinesFilter,
                  pipelinesTransform
                );
              }}
            />
          </div>
        }
        sideNav={<SideNav />}
        title="Pipelines"
        description="Select Pipelines to Deploy"
        icon="pipelines"
      >
        <MrAppMain loading={waiting} styleNames="list">
          <div className={s.stepList}>
            <div className={s.completedStep}>✓</div> <MrText className={s.stepText}>Revisions</MrText>
            <div className={s.completedStep}>✓</div> <MrText className={s.stepText}>Pipelines</MrText>
            <div className={s.currentStep}>3</div> <MrText className={s.currentStepText}>Confirm & Deploy</MrText>
          </div>

          <div className={s.card}>
            <div>
              <MrH h="h3">{`${waiting ? 'Loading' : 'Review'} Deploy Details`}</MrH>
            </div>
            {waiting && <div className={s.loader}></div>}
            {!waiting &&
              diffPipelines.map(p => {
                const updatedIntegrations = integrations[p.id] || [];
                const integrationDiffList = updatedIntegrations
                  .map(i => i?.displayName || _startCase(i?.name))
                  .join(', ');
                return (
                  <div className={s.pipelineDiff} key={p.id}>
                    <div>
                      <MrText className={s.pipelineName}>{p.name}</MrText>
                      <MrText className={s.pipelineDetails}>
                        {_isEmpty(updatedIntegrations)
                          ? 'Error fetching pipeline diff'
                          : `Affected Integrations: ${integrationDiffList}`}
                      </MrText>
                    </div>
                    <MrButton
                      text={_isEmpty(updatedIntegrations) ? 'Error' : 'Deploy Diff'}
                      disabled={waiting || _isEmpty(updatedIntegrations)}
                      className={s.expandPipeline}
                      styleNames="gray"
                      onClick={() => {
                        if (expandedPipeline === p.id) {
                          setExpandedPipeline('');
                        } else {
                          setExpandedPipeline(p.id);
                        }
                      }}
                    ></MrButton>

                    {expandedPipeline === p.id && (
                      <div>
                        <MrModal
                          styleNames="extraWide allowOverflow"
                          closeModal={() => {
                            setExpandedPipeline('');
                            setExpandedDiff('');
                          }}
                        >
                          <div className={s.modalCard}>
                            <MrH h="h1" styleNames="sans">{`Bulk Deploy Diff for ${p.name}`}</MrH>
                            {updatedIntegrations.map(i => {
                              return (
                                <div className={s.integrationDiff} key={i.id}>
                                  <MrH className={s[i.changeType]} h="h3" styleNames="sans">
                                    {_startCase(i.changeType)}:
                                  </MrH>
                                  <MrH h="h3" styleNames="sans">
                                    {_startCase(i.displayName) || _startCase(i.name)}
                                  </MrH>
                                  <img
                                    src={arrow}
                                    className={cx(s.toggle, { [s.open]: expandedDiff === i.id })}
                                    onClick={() => {
                                      if (expandedDiff === i.id) {
                                        setExpandedDiff('');
                                      } else {
                                        setExpandedDiff(i.id);
                                      }
                                    }}
                                  />
                                  <div className={s.lineBreak}></div>
                                  {expandedDiff === i.id && (
                                    <div className={s[`diffBorder${_startCase(i.changeType)}`]}>
                                      <div className={s.diffView}>
                                        <ReactDiffViewer
                                          leftTitle={<span className={s.diffHeading}>Currently Deployed</span>}
                                          rightTitle={<span className={s.diffHeading}>Staged for Deploy</span>}
                                          oldValue={JSON.stringify(i.current, null, 2)}
                                          newValue={JSON.stringify(i.proposed, null, 2)}
                                          splitView={true}
                                          styles={{
                                            variables: {
                                              light: {
                                                codeFoldGutterBackground: '#ebebeb',
                                                codeFoldBackground: '#ebebeb',
                                                codeFoldContentColor: '#5c6775',
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </MrModal>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </MrAppMain>
      </MrAppLayout>
    );
  }
  if (deploying) {
    return (
      <MrAppLayout
        header={
          <div className={s.header}>
            <MrLinkButton className={s.close} to={routes.pipelines}>
              <img src={close} />
              Close
            </MrLinkButton>

            <MrText className={s.title}>
              <MrIcon id={'icon-bulk-deploy'} className={s.icon} />
              <span>Bulk Stage and Deploy</span>
            </MrText>
          </div>
        }
        sideNav={<SideNav />}
        title="Pipelines"
        description="Select Pipelines to Deploy"
        icon="pipelines"
      >
        <MrAppMain loading={waiting} styleNames="list">
          <div className={s.deployedCard}>
            <MrH h="h3">{`Deploying ${pipelines.length} Pipelines`}</MrH>
            <MrTable className={s.table}>
              <tbody>
                {deployResults?.map(pipeline => {
                  return (
                    <tr key={pipeline.name}>
                      <td>{pipeline.name}</td>
                      <td>
                        <div className={s.deployStatus}>
                          <span className={s[pipeline.deployIcon]} /> {pipeline.deployStatus}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </MrTable>
          </div>
        </MrAppMain>
      </MrAppLayout>
    );
  }
};

BulkDeployPipelines.propTypes = {
  pipelines: pt.array.isRequired,
  pipelinesFilter: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.any,
      pipelineID: PropTypes.any,
    })
  ).isRequired,
  pipelinesTransform: PropTypes.arrayOf(
    PropTypes.shape({
      transform: PropTypes.any,
      pipelineID: PropTypes.any,
    })
  ).isRequired,
};

export default BulkDeployPipelines;
