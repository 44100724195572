import isRevisionChanged from './isRevisionChanged';
import getDeployedStagedRevision from './getDeployedStagedRevision';

export default function mergeStagedRevision({ stagedRevision, deployedIntegration }) {
  const { id, playbook, readyForDeploy, stagedForDelete } = stagedRevision;
  const { revision: revisionNumber } = playbook;
  const revision = revisionNumber.toString();

  const deployed = getDeployedStagedRevision({ deployedIntegration });
  const { revision: deployedRevision } = deployed;

  const revisionHasChanges = isRevisionChanged({
    revision,
    deployedRevision,
  });

  return {
    isChanged: revisionHasChanges,
    local: {
      id,
      playbook,
      readyForDeploy,
      stagedForDelete,
      revision,
    },
    deployed,
  };
}
