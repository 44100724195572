import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permission from 'api/Apollo/permissions';
import mergeQueries from 'api/Apollo/merge-queries';
import { GET_ALL_EVENT_NAMES } from 'api/event-names/all-event-names-query-builder';
import { GET_EVENT_NAMES } from 'api/event-names/event-names-query-builder';

import {
  mutation as updateEventNameMutation,
  buildVariables as buildUpdateEventNameVariables,
} from './update-event-name-mutation-builder';

import {
  eventNameUpdatedMutation,
  buildVariables as buildEventNameUpdatedVariables,
} from 'api/audit-logs/event-name-updated/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('UpdateEventNameAndAudit', [
  updateEventNameMutation,
  eventNameUpdatedMutation,
]);

export default function useUpdateEventName(options = {}) {
  const [trackWithUser] = useTrackWithUser('event_name_updated');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    refetchQueries: [{ query: GET_ALL_EVENT_NAMES }, GET_EVENT_NAMES],
    context: { permission: permission.updateEventNames },
  });

  const updateEventNameMutation = async ({ id, name, description }) => {
    const response = await executeMutation({
      variables: {
        ...buildUpdateEventNameVariables({
          id,
          name,
          description,
        }),
        ...buildEventNameUpdatedVariables({
          id,
          name,
          description,
        }),
      },
    });

    if (!response.errors) {
      trackWithUser();
    }

    return response;
  };

  return [updateEventNameMutation, response];
}
