import { useState, useEffect } from 'react';

export default function useLastDeployed({ deployedPipeline }) {
  const [lastDeployed, setLastDeployed] = useState(null);

  useEffect(() => {
    if (deployedPipeline) {
      return setLastDeployed(deployedPipeline.createdAt);
    }
    setLastDeployed('');
  }, [deployedPipeline]);

  return lastDeployed;
}
