import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { buildVariables, mutation } from './build-ajs-file-mutation-builder';
import { useUserContext } from '@ion/user';

import {
  mutation as ajsFileBuiltMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/ajs-file-built/audit-log-mutation-builder';
import mergeQueries from '../../Apollo/merge-queries';

export const combinedMutation = mergeQueries('BuildAjsFileAndAudit', [mutation, ajsFileBuiltMutation]);

export default function useBuildAjsFile(options = {}) {
  const { currentOrg } = useUserContext();
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    context: { permission: permissions.updatePipelineAJSBuilder },
  });

  const buildAjsFileMutation = ({ pipelineId, pipelineName, settingsJson }) => {
    settingsJson.clientName = currentOrg.name;
    return executeMutation({
      variables: {
        ...buildVariables({ settingsJson }),
        ...buildAuditLogVariables({ pipelineId, pipelineName }),
      },
    });
  };

  return [buildAjsFileMutation, response];
}
