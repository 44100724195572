import EventCarouselItem from './EventCarouselItem';
import { useViolationEventsDrawerContext } from '../ViolationEventsDrawerContextProvider';

export default function useViolationEventsCarouselProps() {
  const { loading, violationEvents } = useViolationEventsDrawerContext();

  return {
    loading,
    items: !loading ? buildItems(violationEvents) : null,
  };
}

export function buildItems(violationEvents = []) {
  return violationEvents.map(event => ({
    Component: EventCarouselItem,
    componentProps: { event },
  }));
}
