import TextCell from 'app/components/data-table/TextCell';
import TimestampCell from 'app/components/data-table/TimestampCell';

export default function buildStatusTableContents({ lastDeployed, cluster }) {
  return [
    {
      0: { Component: TextCell, componentProps: { text: 'Last Deployed', variant: 'body2' } },
      1: { Component: TimestampCell, componentProps: { timestamp: lastDeployed, 'data-test-id': 'lastDeployedDate' } },
    },
    {
      0: { Component: TextCell, componentProps: { text: 'Cluster', variant: 'body2' } },
      1: {
        Component: TextCell,
        componentProps: { text: cluster?.name, variant: 'body2', 'data-test-id': 'clusterName' },
      },
    },
  ];
}
