import React from 'react';
import PropTypes from 'prop-types';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import EditPipelineSchemaLibraryModal from 'app/pipeline-details/SchemaEnforcementTable/modals/EditPipelineSchemaLibraryModal';

import EditPipelineSchemaLibraryButton from './EditPipelineSchemaLibraryButton';

export default function EditAction({ row }) {
  return (
    <ModalLauncher Launcher={EditPipelineSchemaLibraryButton} Modal={EditPipelineSchemaLibraryModal} modalProps={row} />
  );
}

EditAction.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    schemaLibraryId: PropTypes.string.isRequired,
    schemaLibraryVersionId: PropTypes.string.isRequired,
  }).isRequired,
};
