import { useReducer } from 'react';

const MODAL_STATE_DEFAULT = { activeModal: null, pipeline: { cluster: {} } };

export const MODAL_ACTIONS = {
  EDIT_PIPELINE_NAME: 'EDIT_PIPELINE_NAME',
  DELETE_PIPELINE: 'DELETE_PIPELINE',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

export default function useActiveModalState() {
  const [modalState, dispatch] = useReducer(modalReducer, MODAL_STATE_DEFAULT);

  const editPipelineName = pipeline => {
    dispatch({ type: MODAL_ACTIONS.EDIT_PIPELINE_NAME, pipeline });
  };

  const deletePipeline = pipeline => {
    dispatch({ type: MODAL_ACTIONS.DELETE_PIPELINE, pipeline });
  };

  const closeModal = () => {
    dispatch({ type: MODAL_ACTIONS.CLOSE_MODAL });
  };

  return { modalState, editPipelineName, deletePipeline, closeModal };
}

function modalReducer(state, action) {
  switch (action.type) {
    case MODAL_ACTIONS.EDIT_PIPELINE_NAME:
      return {
        activeModal: MODAL_ACTIONS.EDIT_PIPELINE_NAME,
        pipeline: action.pipeline,
      };
    case MODAL_ACTIONS.DELETE_PIPELINE:
      return {
        activeModal: MODAL_ACTIONS.DELETE_PIPELINE,
        pipeline: action.pipeline,
      };
    case MODAL_ACTIONS.CLOSE_MODAL:
      return MODAL_STATE_DEFAULT;
    default:
      return state;
  }
}
