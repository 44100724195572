import React from 'react';
import PropTypes from 'prop-types';
import deepmerge from 'deepmerge';

import { Stack } from '@metarouter-private/mr-mui';
import { TimeAgo, FormattedDate } from '@metarouter-private/mr-mui';

export default function TimeAgoAndDate({ timestamp, TimeAgoProps = {}, FormattedDateProps = {}, ...stackProps }) {
  const timestampDate = new Date(timestamp);

  const timeAgoPropsWithVariant = deepmerge(
    {
      date: timestampDate,
      TypographyProps: {
        variant: 'body2',
      },
    },
    TimeAgoProps
  );

  const formattedDateWithVariant = deepmerge(
    {
      date: timestampDate,
      TypographyProps: {
        variant: 'caption',
      },
    },
    FormattedDateProps
  );

  return (
    <Stack direction="column" {...stackProps}>
      <TimeAgo {...timeAgoPropsWithVariant} />

      <FormattedDate {...formattedDateWithVariant} />
    </Stack>
  );
}

TimeAgoAndDate.propTypes = {
  timestamp: PropTypes.string.isRequired,
  TimeAgoProps: PropTypes.object,
  FormattedDateProps: PropTypes.object,
};
