import actions from 'api/audit-logs/actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ schemaLibraryId, name, description: schemaLibraryDescription }) {
  const action = actions.schema_library_updated;
  const description = `${name}`;
  const debug_data = {
    schemaLibraryId,
    name,
    description: schemaLibraryDescription,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
