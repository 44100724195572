import React from 'react';
import PropTypes from 'prop-types';

import { MrModal, MrH } from '@ion/components';

import CreatePipelineForm from './CreatePipelineForm';

import styles from './create-pipeline-modal.module.scss';

export default function CreatePipelineModal({ open, onClose }) {
  if (!open) {
    return null;
  }

  return (
    <MrModal closeModal={onClose} styleNames="allowOverflow">
      <div className={styles.header}>
        <MrH h="h3" sans styleNames="noMargin">
          New Pipeline
        </MrH>
      </div>

      <div className={styles.content}>
        <CreatePipelineForm onClose={onClose} />
      </div>
    </MrModal>
  );
}

CreatePipelineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
