import { isAllowed } from 'api/Apollo/allower';
import permissions from 'api/Apollo/permissions';

export default function isAllowedToWritePipeline() {
  const pipelineWritePermissions = [
    permissions.createPipeline,
    permissions.updatePipeline,
    permissions.deletePipeline,
    permissions.deployPipeline,
  ];

  return pipelineWritePermissions.some(permission => isAllowed(permission));
}
