/* eslint-disable filename-export/match-default-export */
/* disabling rule until refactor of analytics builder */

import React from 'react';
import propTypes from 'prop-types';
import s from './index.module.scss';

import { MrInputRadio, MrInputRadioGroup, MrWarning } from '@ion/components';

export default function AnalyticsBuilderConsent({ value, onChange, resetError, errorMsg }) {
  return (
    <div className={s.consentSection}>
      <MrInputRadioGroup
        name="impliedConsent"
        label="Consent Policy"
        overrideValue={value}
        onChange={onChange}
        resetError={resetError}
        errorMsg={errorMsg}
        required
      >
        <MrInputRadio
          value="true"
          label="Implied consent (opt-in by default)"
          tooltip="Analytics.js tracking will begin prior to a user opt-in or opt-out to tracking via your consent banner."
        />
        <MrInputRadio
          value="false"
          label="Explicit consent (opt-out by default)"
          tooltip="Analytics.js tracking will begin following a user opt-in. No data will be tracked if a user doesn't opt in or rejects tracking."
        />
      </MrInputRadioGroup>

      <MrWarning
        header="Double Check Your Setup Prior to Deploying"
        body={
          <div>
            When GDPR consent mode is activated, MetaRouter will not track any events until a user opts in to tracking
            via your consent banner. If the MetaRouter consent module is not configured correctly, or if it cannot pick
            up a consent signal from the cookie space, it will not track any data.{' '}
            <a
              className={s.warningLink}
              href="https://docs.metarouter.io/docs/compliance-module#implicit-vs-explicit-consent"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        }
        visible={value === 'false'}
      />
    </div>
  );
}

AnalyticsBuilderConsent.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func.isRequired,
  resetError: propTypes.func.isRequired,
  errorMsg: propTypes.string,
};
