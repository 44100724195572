import isEmpty from 'lodash/isEmpty';
import PIPELINE_STATUSES from './PIPELINE_STATUSES';
import isEqualPipelineIntegrations from './isEqualPipelineIntegrations';

export default function getPipelineStatus({ pipeline, deployedPipeline, deployedRevisions }) {
  if (!pipeline) {
    return PIPELINE_STATUSES.NotDeployed;
  }

  if (isEmpty(pipeline.pipelineIntegrations) && !deployedPipeline) {
    return PIPELINE_STATUSES.NotDeployed;
  }

  const isDeployedPipeline = isEqualPipelineIntegrations({
    deployedRevisions,
    pipelineIntegrations: pipeline.pipelineIntegrations,
  });

  if (isDeployedPipeline) {
    return PIPELINE_STATUSES.Deployed;
  }

  return PIPELINE_STATUSES.StagedForDeploy;
}
