import React from 'react';
import PropTypes from 'prop-types';

import { useUpdatePipeline } from '@ion/api';
import { MrDataTable } from '@ion/components';

import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import isFeatureEnabled from 'app/launch-darkly/is-feature-enabled';

import { usePipelineIntegrationModalContext } from './context/pipeline-integration-modal-context';
import { PipelineIntegrationsTableTitle } from './title/PipelineIntegrationsTableTitle';
import buildTableData, { INTEGRATION_TYPES } from './buildTableData';
import buildViewableDatatableProps from './buildViewableDataTableProps';
import { PIPELINE_MODAL_OPTIONS, PipelineModal } from './pipeline-modal/PipelineModal';
import { conditionalRowStyles } from './conditional-row-styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PipelineIntegrationsTable = ({ pipelineIntegrations, deployedRevisions }) => {
  const featureFlags = useFlags();
  const hasConsentMvp = isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.configurableConsentMvp });
  const { onOpenModal } = usePipelineIntegrationModalContext();
  const [, { isAllowed: canTakeActions }] = useUpdatePipeline();
  const tableData = buildTableData({ deployedRevisions, pipelineIntegrations });

  const onClickIntegrationName = row => {
    if (row.type === INTEGRATION_TYPES.MANAGED) {
      onOpenModal(PIPELINE_MODAL_OPTIONS.editIntegration, row);
    }
  };
  const viewableProps = buildViewableDatatableProps(canTakeActions, onClickIntegrationName, hasConsentMvp);

  return (
    <>
      <MrDataTable
        title={<PipelineIntegrationsTableTitle tableData={tableData} />}
        data={tableData}
        conditionalRowStyles={conditionalRowStyles}
        {...viewableProps}
      />
      <PipelineModal />
    </>
  );
};

PipelineIntegrationsTable.propTypes = {
  pipelineIntegrations: PropTypes.array,
  deployedRevisions: PropTypes.array.isRequired,
};

export default PipelineIntegrationsTable;
