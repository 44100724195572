import React from 'react';

import { Grid, Stack } from '@metarouter-private/mr-mui';
import { MrAppLayout, MrAppMain } from '@ion/components';

import SideNav from 'app/components/side-nav';

import StatusTable from './StatusTable';
import AnalyticsFileTable from './AnalyticsFileTable';
import SchemaEnforcementTable from './SchemaEnforcementTable';
import PipelineIntegrationTableContextProvider from './PipelineIntegrationTableContextProvider';
import PipelineIntegrations from './PipelineIntegrations';
import PipelineDetailsPageHeader from './PipelineDetailsPageHeader';
import { usePipelinePageContext } from './PipelinePageContextProvider';
import SchemaEnforcementTableContextProvider from './SchemaEnforcementTableContextProvider';

export default function PipelineDetailsContent() {
  const { loading, pipeline } = usePipelinePageContext();

  const { id, name, writekey, cluster: { id: clusterId } = {} } = pipeline;

  const schemaEnforcementTablePipeline = {
    id,
    name,
    writekey,
    clusterId,
  };

  return (
    <MrAppLayout title="Pipelines" header={<PipelineDetailsPageHeader />} sideNav={<SideNav />}>
      <MrAppMain loading={loading}>
        {!loading && (
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <StatusTable />
              </Grid>
              <Grid item xs={4}>
                <AnalyticsFileTable />
              </Grid>
              <Grid item container xs={4}>
                <SchemaEnforcementTableContextProvider pipeline={schemaEnforcementTablePipeline}>
                  <SchemaEnforcementTable />
                </SchemaEnforcementTableContextProvider>
              </Grid>
            </Grid>
            <PipelineIntegrationTableContextProvider>
              <PipelineIntegrations />
            </PipelineIntegrationTableContextProvider>
          </Stack>
        )}
      </MrAppMain>
    </MrAppLayout>
  );
}
