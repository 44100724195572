import deployState from './deployState';

export default function getDeployStatus({ cluster, deployedRevisions, pipelineIntegrations, loadingDiff }) {
  const d = deployState({
    assignedToCluster: cluster,
    deployedRevisions,
    pipelineIntegrations,
    loadingDiff,
  });

  // merge results of deploy status with health indicator data
  if (d?.action === 'resolve') {
    return {
      ...d,
      healthText: 'Missing Variables',
      healthClass: 'warn',
    };
  }

  if (deployedRevisions.length) {
    return {
      ...d,
      healthText: 'Good',
      healthClass: 'good',
    };
  }

  // default state when nothing has been deployed
  return {
    ...d,
    healthText: '——',
    healthClass: 'unknown',
  };
}
