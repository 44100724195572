import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const EventSchemaViolationsPageContext = createContext(null);

export default function EventSchemaViolationsPageContextProvider({ children }) {
  const {
    location: {
      state: { pipeline, eventName, schemaId, earliestSeen },
    },
  } = useHistory();

  return (
    <EventSchemaViolationsPageContext.Provider value={{ pipeline, eventName, schemaId, earliestSeen }}>
      {children}
    </EventSchemaViolationsPageContext.Provider>
  );
}

export function useEventSchemaViolationsPageContext() {
  return useContext(EventSchemaViolationsPageContext);
}

EventSchemaViolationsPageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
