import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@metarouter-private/mr-mui';

export default function SchemaLibraryNameCell({ name = '', version = '' }) {
  return (
    <Stack>
      <Typography variant="body2">{name}</Typography>
      <Typography variant="caption">Version {version}</Typography>
    </Stack>
  );
}

SchemaLibraryNameCell.propTypes = {
  name: PropTypes.string,
  version: PropTypes.number,
};
