import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@metarouter-private/mr-mui';

import INTEGRATION_AVATAR_LOGOS from './INTEGRATION_AVATAR_LOGOS';

export default function IntegrationAvatar({ id, ...AvatarProps }) {
  const logo = INTEGRATION_AVATAR_LOGOS[id];

  if (!logo) {
    const firstCharacter = id.charAt(0).toUpperCase();
    return <Avatar {...AvatarProps}>{firstCharacter}</Avatar>;
  }

  return <Avatar {...AvatarProps} src={logo} />;
}

IntegrationAvatar.propTypes = {
  id: PropTypes.string.isRequired,
};
