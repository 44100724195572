import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const SchemaViolationsPageContext = createContext(null);

// TODO: Replace when we create time select.
const earliestSeen = new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();

export default function SchemaViolationsPageContextProvider({ children }) {
  const {
    state: { pipeline },
  } = useLocation();

  return (
    <SchemaViolationsPageContext.Provider value={{ pipeline, earliestSeen }}>
      {children}
    </SchemaViolationsPageContext.Provider>
  );
}

export function useSchemaViolationsPageContext() {
  return useContext(SchemaViolationsPageContext);
}

SchemaViolationsPageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
