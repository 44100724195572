import { gql } from '@apollo/client';
import { isEmpty } from 'lodash';

import buildLimitAndOffset from 'api/pagination/buildLimitAndOffset';
import SORTABLE_PIPELINE_FIELDS from './config/SORTABLE_PIPELINE_FIELDS';

export const PIPELINES_QUERY = 'PipelinesList';

export const GET_PIPELINES = gql`
  query ${PIPELINES_QUERY}(
    $limit: Int!
    $offset: Int!
    $clusterIdFilter: pipelines_bool_exp!
    $searchTerm: String!
    $orderBy: [pipelines_order_by!]
  ) {
    pipelinesAggregate: pipelines_aggregate(
      where: {
        _and: [
          $clusterIdFilter,
          { deletedAt: { _is_null: true } },
          { _or: [
            { name: { _ilike: $searchTerm } }
            { writekey: { _ilike: $searchTerm } }
          ] }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    pipelines(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: {
        _and: [
          $clusterIdFilter,
          { deletedAt: { _is_null: true } },
          { _or: [
            { name: { _ilike: $searchTerm } }
            { writekey: { _ilike: $searchTerm } }
          ] }
        ]
      }
    ) {
      id
      name
      writekey
      cluster {
        id
        name
      }
      pipelineIntegrations: pipeline_integrations {
        id
        integrationId
        pipelineId
        integration {
          id
          starterkitId
          name
        }
        pipeline {
          id
          name
        }
      }
    }
  }
`;

export function buildVariables({ clusterIds, page, perPage, searchTerm = '', sort }) {
  const clusterIdFilter = !isEmpty(clusterIds) ? { clusterId: { _in: clusterIds } } : {};

  return {
    ...buildLimitAndOffset({ page, perPage }),
    searchTerm: `%${searchTerm}%`,
    clusterIdFilter,
    orderBy: buildOrderBy(sort),
  };
}

function buildOrderBy(sort) {
  switch (sort.field) {
    case SORTABLE_PIPELINE_FIELDS.integrations: {
      return {
        pipeline_integrations_aggregate: { count: sort.direction },
      };
    }
    case SORTABLE_PIPELINE_FIELDS.cluster: {
      return {
        cluster: { name: sort.direction },
      };
    }
    default: {
      return { [sort.field]: sort.direction };
    }
  }
}
