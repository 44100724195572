import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@metarouter-private/mr-mui';

export default function ConfirmDeleteMessage({ name }) {
  return (
    <Typography variant="body1" color="text.secondary">
      This pipeline is connected to the{' '}
      <Typography fontWeight="700" component="span">
        {name}
      </Typography>{' '}
      cluster. If you choose to delete this pipeline, it will also be removed from the cluster. Integrations used by
      this pipeline will not be affected.
    </Typography>
  );
}

ConfirmDeleteMessage.propTypes = {
  name: PropTypes.string,
};
