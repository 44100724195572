import React from 'react';

import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

import DeployChangesButton from './DeployChangesButton';
import AddIntegrationButton from './AddIntegrationButton';

export default function Actions() {
  return (
    <>
      <AddIntegrationButton />
      <FeatureGate flagName={FEATURE_FLAGS.pipelinesListRevamp}>
        <DeployChangesButton />
      </FeatureGate>
    </>
  );
}
