import { gql } from '@apollo/client';
import permissions from 'api/Apollo/permissions';
import useQuery from 'api/hooks/useQuery';

import pipeline from './pipeline';

export const query = gql`
  query Pipeline($pipelineId: uuid!) {
    pipelines_by_pk(id: $pipelineId) {
      ...pipeline
    }
  }
  ${pipeline}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error, refetch } = useQuery(query, {
    variables, // can apply any transforms or default vars here
    context: { permission: permissions.readPipeline },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.pipelines_by_pk,
    loading,
    isAllowed,
    error,
    refetch,
  };
};
