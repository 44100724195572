import React from 'react';

import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';
import SchemaEnforcementTableContextProvider from 'app/pipeline-details/SchemaEnforcementTableContextProvider';

import PipelineSchemaLibraryTableContextProvider from './PipelineSchemaLibraryTableContext';
import PipelineSchemaTable from './PipelineSchemaTable';

export default function PipelineSchemaSection() {
  const { pipelineLoading, pipeline } = usePipelineCardContext();

  if (pipelineLoading) {
    return null;
  }

  return (
    <SchemaEnforcementTableContextProvider pipeline={pipeline}>
      <PipelineSchemaLibraryTableContextProvider>
        <PipelineSchemaTable />
      </PipelineSchemaLibraryTableContextProvider>
    </SchemaEnforcementTableContextProvider>
  );
}
