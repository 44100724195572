import React from 'react';
import PropTypes from 'prop-types';

import { MrH } from '@ion/components';
import ConfirmDelete from 'app/components/ConfirmDelete';
import { useDeletePipeline, useRemovePipelineIntegrations } from '@ion/api';
import { globalModalStore } from '@ion/global';

import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';

import styles from './index.module.scss';

const REMOVE_DEPLOYED_INTEGRATIONS_ERROR = 'Error Removing Deployed Integrations';

export default function RemoveDeployedIntegrationsModal({
  setRemoveDeployedIntegrationsModalOpen,
  setDeployedRevisions,
  refetch,
}) {
  const { pipeline } = usePipelineCardContext();
  const { name, clusterId, writekey } = pipeline;

  const [removePipelineIntegrations] = useRemovePipelineIntegrations();
  const [removePipeline] = useDeletePipeline({
    onCompleted: () => {
      // EunHye : This can be removed when we deprecate `pipelines-deprecated`
      if (setDeployedRevisions) {
        setDeployedRevisions([]);
      }
      removePipelineIntegrations(pipeline.pipelineIntegrations);

      if (refetch) {
        refetch({ pipelineId: pipeline.id, clusterId, writekey });
      }
    },
    onError: err => {
      globalModalStore.addNotification({
        title: REMOVE_DEPLOYED_INTEGRATIONS_ERROR,
        message: err?.errorMsg.message,
      });
    },
  });

  return (
    <>
      <ConfirmDelete
        buttonClassName={styles.deployButton}
        deleteFn={() => {
          removePipeline({ clusterId, writekey });
        }}
        closeModal={() => {
          setRemoveDeployedIntegrationsModalOpen(false);
        }}
      >
        <div className={styles.header}>
          <MrH h="h2" styleNames="sans">
            Remove deployed integrations from pipeline {name}?
          </MrH>
        </div>
        <div className={styles.content}>
          <p>All deployed integrations connected to this pipeline are staged for deletion.</p>
        </div>
      </ConfirmDelete>
    </>
  );
}

RemoveDeployedIntegrationsModal.propTypes = {
  setRemoveDeployedIntegrationsModalOpen: PropTypes.func.isRequired,
  setDeployedRevisions: PropTypes.func,
  refetch: PropTypes.func,
};
