import React from 'react';

import { AceCodeEditor, MrCodeEditor } from '@ion/components';
import { editorPropTypes } from '@ion/components/src/components/code-editor/code-editor-container';
import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

export default function GatedCodeEditor(props) {
  return (
    <FeatureGate flagName={FEATURE_FLAGS.aceEditor} fallback={<MrCodeEditor {...props} />}>
      <AceCodeEditor {...props} />
    </FeatureGate>
  );
}

GatedCodeEditor.propTypes = editorPropTypes;
