import React from 'react';
import { Box, Stack } from '@metarouter-private/mr-mui';
import ViolationEventsDrawerHeader from './ViolationEventsDrawerHeader';
import ViolationEventsCarousel from './ViolationEventsCarousel/ViolationEventsCarousel';
import ViolationEventsDrawerContextPovder from './ViolationEventsDrawerContextProvider';

export default function ViolationEventsDrawerContent() {
  return (
    <ViolationEventsDrawerContextPovder>
      <Box p={2}>
        <Stack>
          <ViolationEventsDrawerHeader />
          <ViolationEventsCarousel />
        </Stack>
      </Box>
    </ViolationEventsDrawerContextPovder>
  );
}
