import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import EditAction from './EditAction';
import DeleteAction from './DeleteAction';

export default function ActionCell(row) {
  return (
    <Stack direction="row" justifyContent="flex-end" width="100%">
      <EditAction row={row} />
      <DeleteAction row={row} />
    </Stack>
  );
}

ActionCell.propTypes = {
  id: PropTypes.string,
  schemaLibraryVersionId: PropTypes.string,
  version: PropTypes.number,
  schemaLibraryId: PropTypes.string,
  name: PropTypes.string,
};
