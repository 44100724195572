import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permissions from 'api/Apollo/permissions';
import { SCHEMA_LIBRARY_WITH_VERSIONS_QUERY } from 'api/schema-libraries/useQuerySchemaLibraryWithVersions';
import mergeQueries from 'api/Apollo/merge-queries';

import {
  mutation as updateSchemaLibraryVersion,
  buildVariables as buildSchemaLibraryVersionVariables,
} from './update-schema-library-version-mutation-builder';

import {
  schemaLibraryPublishedMutation,
  buildVariables as buildSchemaLibraryPublishedVariables,
} from 'api/audit-logs/schema-library-published/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('UpdateSchemaLibraryVersionAndAudit', [
  updateSchemaLibraryVersion,
  schemaLibraryPublishedMutation,
]);

export default function useUpdateSchemaLibraryVersion(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_version_updated');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    refetchQueries: [SCHEMA_LIBRARY_WITH_VERSIONS_QUERY],
    context: { permission: permissions.updateSchemas },
  });

  const updateSchemaLibraryVersionMutation = async ({ id, status, name, version }) => {
    const response = await executeMutation({
      variables: {
        ...buildSchemaLibraryVersionVariables({
          id,
          status,
        }),
        ...buildSchemaLibraryPublishedVariables({
          id,
          name,
          version,
        }),
      },
    });

    if (!response.errors) {
      trackWithUser();
    }

    return response;
  };

  return [updateSchemaLibraryVersionMutation, response];
}
