import React from 'react';
import PropTypes from 'prop-types';
import { FormTextField, FormFieldContext } from '@metarouter-private/mr-mui';

export default function PipelineNameForm({ name }) {
  return (
    <FormFieldContext name="name" label="Pipeline Name" defaultValue={name} required>
      <FormTextField />
    </FormFieldContext>
  );
}

PipelineNameForm.propTypes = {
  name: PropTypes.string.isRequired,
};
