import { PIPELINE_STATUSES } from 'api';

import containsUnexpectedRoostError from './containsUnexpectedRoostError';
import isNotDeployed from './isNotDeployed';
import isFullyDeployed from './isFullyDeployed';

export default function determinePipelineStatus(ionPipeline, controlPipeline) {
  if (containsUnexpectedRoostError(controlPipeline)) {
    return PIPELINE_STATUSES.Error;
  }

  if (isNotDeployed(ionPipeline, controlPipeline)) {
    return PIPELINE_STATUSES.NotDeployed;
  }

  if (isFullyDeployed(ionPipeline, controlPipeline)) {
    return PIPELINE_STATUSES.Deployed;
  }

  return PIPELINE_STATUSES.StagedForDeploy;
}
