import { useCallback } from 'react';

import { useGetPipeline } from 'api/control/useControlProxy';
import useQueryIntegrations from 'api/integrations/useQueryIntegrations';
import useQueryPipeline from 'api/pipelines/useQueryPipeline';

import transformResponse from './transformResponse';
import isLoadingCombinedPipeline from './isLoadingCombinedPipeline';

export default function useQueryCombinedPipeline({ pipelineId, ...options }) {
  const {
    data: integrationsData,
    refetch: refetchIntegrations,
    loading: integrationLoading,
  } = useQueryIntegrations({
    variables: { limit: null },
  });

  const [fetchPipeline, { data: { pipeline: deployedPipeline } = {}, refetch: refetchGetPipeline, error }] =
    useGetPipeline();

  const fetchPipelineCallBack = useCallback(
    response => {
      if (response) {
        const { writekey, clusterId } = transformResponse(response);
        fetchPipeline({
          clusterId,
          writekey,
        });
      }
    },
    [fetchPipeline]
  );

  const {
    data: pipeline,
    refetch: refetchPipeline,
    loading: pipelineLoading,
  } = useQueryPipeline({
    variables: { pipelineId },
    onCompleted: fetchPipelineCallBack,
    ...options,
  });

  const loading = isLoadingCombinedPipeline({ integrationLoading, pipelineLoading, error, deployedPipeline });

  const refetch = async ({ pipelineId, clusterId, writekey }) => {
    await Promise.all([
      refetchIntegrations(),
      refetchPipeline({ pipelineId }),
      refetchGetPipeline({ clusterId, writekey }),
    ]);
  };

  return {
    integrationsData,
    deployedPipeline,
    pipeline,
    loading,
    refetch,
  };
}
