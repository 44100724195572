import React from 'react';
import PropTypes from 'prop-types';

import { MrIcon, MrLinkButton, saveFile } from '@ion/components';

import s from 'app/event-monitoring/event-schemas/panel/export.module.scss';

const Export = ({ properties, eventName }) => {
  const disabled = !properties;
  return (
    <MrLinkButton
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          saveFile({
            compressFile: false,
            mimeType: 'text/json',
            content: properties,
            filename: `${eventName}.json`,
          });
        }
      }}
      className={s.button}
    >
      <MrIcon id="icon-download" />
      Export as JSON
    </MrLinkButton>
  );
};

Export.propTypes = {
  properties: PropTypes.object.isRequired,
  eventName: PropTypes.string.isRequired,
};

export default Export;
