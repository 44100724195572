import React from 'react';

import { useQueryAnalyticsFile } from '@ion/api';

import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

import AnalyticsFileTableTitle from './AnalyticsFileTableTitle';
import BuildNewFileButton from './BuildNewFileButton';
import buildAnalyticsFileTableContents from './buildAnalyticsFileTableContents';

export default function useAnalyticsFileTable() {
  const { pipelineId } = usePipelinePageContext();

  const { data: analyticsFile } = useQueryAnalyticsFile({
    variables: { pipelineId },
  });

  return {
    numberOfColumns: 2,
    data: buildAnalyticsFileTableContents({ analyticsFile }),
    title: <AnalyticsFileTableTitle />,
    actions: <BuildNewFileButton />,
  };
}
