import React from 'react';
import PropTypes from 'prop-types';
import { routes } from 'app/constants';
import LinkButton from 'app/shared/navigation/LinkButton';
import { useSchemaViolationsPageContext } from 'app/schema-violations/SchemaViolationsPage/SchemaViolationsPageContextProvider';

export default function EventNameCell({ row: { eventName, schemaId } }) {
  const { pipeline, earliestSeen } = useSchemaViolationsPageContext();
  const linkProps = {
    to: {
      pathname: routes.eventSchemaViolations,
      state: { pipeline, eventName, schemaId, earliestSeen },
    },
  };

  return (
    <LinkButton variant="text" color="secondary" {...linkProps}>
      {eventName}
    </LinkButton>
  );
}

EventNameCell.propTypes = {
  row: PropTypes.shape({
    eventName: PropTypes.string.isRequired,
    schemaId: PropTypes.string.isRequired,
  }).isRequired,
};
