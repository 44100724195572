import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@metarouter-private/mr-mui';

import useTrackWithUser from 'api/hooks/useTrackWithUser';
import { routes } from 'app/constants';

const TITLE = 'Bulk Deploy';

export default function BulkDeployButton() {
  const [trackWithUser] = useTrackWithUser('bulk_deploy_button_clicked');
  const linkProps = {
    component: Link,
    to: {
      pathname: routes.selectStageOrDeploy,
    },
  };

  return (
    <Button
      variant="outlined"
      onClick={() => {
        trackWithUser();
      }}
      {...linkProps}
    >
      {TITLE}
    </Button>
  );
}
