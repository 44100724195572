import { useEffect, useCallback } from 'react';
import pt from 'prop-types';

import { useGetEventSchemaDetails } from '@ion/api';

export default function GetEventSchemaDetails({
  cluster,
  earliestSeen,
  eventName,
  setSchemaDetails,
  setLoadingDetails,
  filteredPipelines,
}) {
  const [getSchemaDetails] = useGetEventSchemaDetails({
    onCompleted: r => {
      setSchemaDetails(r);
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSchemaDetailsCallback = useCallback(getSchemaDetails, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setLoadingDetailsCallback = useCallback(setLoadingDetails, []);

  useEffect(() => {
    setLoadingDetailsCallback();
    getSchemaDetailsCallback({ clusterId: cluster.id, earliestSeen, eventName, writekeys: filteredPipelines });
  }, [cluster.id, earliestSeen, eventName, filteredPipelines, getSchemaDetailsCallback, setLoadingDetailsCallback]);
  return null;
}

GetEventSchemaDetails.propTypes = {
  cluster: pt.shape({
    id: pt.string,
  }).isRequired,
  earliestSeen: pt.string.isRequired,
  eventName: pt.string.isRequired,
  setSchemaDetails: pt.func.isRequired,
  setLoadingDetails: pt.func.isRequired,
  filteredPipelines: pt.arrayOf(pt.string),
};
