import { DEPLOY_ACTIONS } from './DEPLOY_STATE';

export default function determineDeployAction({
  deployStatus,
  setPipelineVariablesModalOpen,
  setRemoveDeployedIntegrationsModalOpen,
  openPipelineDiffModal,
}) {
  switch (deployStatus.action) {
    case DEPLOY_ACTIONS.OPEN_PIPELINE_VARIABLES_MODAL:
      return setPipelineVariablesModalOpen(true);
    case DEPLOY_ACTIONS.OPEN_CONFIRM_DELETE_PIPELINE_DATA_MODAL:
      return setRemoveDeployedIntegrationsModalOpen(true);
    case DEPLOY_ACTIONS.OPEN_PIPELINE_DIFF_MODAL:
      return openPipelineDiffModal();
    default:
      throw new Error(`Unknown deploy action: ${deployStatus.action}`);
  }
}
