import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import usePipelinesTableContextProps from './usePipelinesTableContextProps';

const PipelinesTableContext = createContext({});

function usePipelinesTableContext() {
  return useContext(PipelinesTableContext);
}

function PipelinesTableContextProvider({ children }) {
  const contextValue = usePipelinesTableContextProps();

  return <PipelinesTableContext.Provider value={contextValue}>{children}</PipelinesTableContext.Provider>;
}

PipelinesTableContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PipelinesTableContextProvider, usePipelinesTableContext };
