import { INTEGRATION_TYPES } from './buildTableData';

export const conditionalCellStyles = [
  {
    when: row => row.type === INTEGRATION_TYPES.UNMANAGED,
    style: {
      cursor: 'not-allowed',
    },
  },
];
