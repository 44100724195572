import React from 'react';

import { GatedConsentCategoriesContext } from 'app/context/gated-consent-categories-context.js';
import { PipelineIntegrationModalProvider } from 'app/pipeline-details/PipelineIntegrationsTable/context/pipeline-integration-modal-context';

import PipelineIntegrationsTable from 'app/pipeline-details/PipelineIntegrationsTable';
import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

export default function PipelineIntegrations() {
  const { pipeline } = usePipelinePageContext();

  return (
    <GatedConsentCategoriesContext>
      <PipelineIntegrationModalProvider>
        <PipelineIntegrationsTable
          deployedRevisions={pipeline.deployedRevisions}
          pipelineIntegrations={pipeline.pipelineIntegrations}
        />
      </PipelineIntegrationModalProvider>
    </GatedConsentCategoriesContext>
  );
}
