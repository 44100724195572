import { isEmpty as _isEmpty, every as _every, some as _some } from 'lodash';

import { isEqualPipelineIntegrations } from '@ion/api';

import { DEPLOY_ACTIONS, DEPLOY_STATE, DEPLOY_STATE_TOOLTIP } from './DEPLOY_STATE';

export default function deployState({ assignedToCluster, deployedRevisions, pipelineIntegrations, loadingDiff }) {
  const stagedRevisions = pipelineIntegrations.map(pipelineIntegration => pipelineIntegration.stagedRevision);
  const noStagedRevisions = _isEmpty(stagedRevisions);
  const noDeployedIntegrations = _isEmpty(deployedRevisions);

  const needStagedRevisions = _some(stagedRevisions, ['readyForDeploy', false]);
  const noPipelineIntegrationChanges = isEqualPipelineIntegrations({ deployedRevisions, pipelineIntegrations });

  const enabled = {
    action: DEPLOY_ACTIONS.OPEN_PIPELINE_DIFF_MODAL,
    styleName: 'blueMed',
    buttonText: DEPLOY_STATE.DEPLOY,
    buttonTooltip: DEPLOY_STATE_TOOLTIP.DEPLOY,
  };
  if (loadingDiff) {
    return {
      buttonText: DEPLOY_STATE.LOADING,
      buttonTooltip: DEPLOY_STATE_TOOLTIP.LOADING,
    };
  }

  if (!assignedToCluster) {
    return {
      disabled: true,
      buttonText: DEPLOY_STATE.DEPLOY,
      buttonTooltip: DEPLOY_STATE_TOOLTIP.NOT_ASSIGNED_TO_CLUSTER,
    };
  }

  // set 'Delete' state if there are deployed integrations, and all stagedRevisions are staged for deletion
  if (!noDeployedIntegrations && _every(stagedRevisions, ['stagedForDelete', true])) {
    return {
      styleName: 'blueMed',
      buttonText: DEPLOY_STATE.DEPLOY,
      action: DEPLOY_ACTIONS.OPEN_CONFIRM_DELETE_PIPELINE_DATA_MODAL,
      buttonTooltip: DEPLOY_STATE_TOOLTIP.STAGED_FOR_DELETE,
    };
  }

  // disable deployment if there are no staged changes
  if (noStagedRevisions && noDeployedIntegrations) {
    return { disabled: true, buttonText: DEPLOY_STATE.DEPLOY, buttonTooltip: DEPLOY_STATE_TOOLTIP.NO_STAGED_CHANGES };
  }

  // disable deployment if there are no differences from previous deployment
  if (noPipelineIntegrationChanges) {
    return { disabled: true, buttonText: DEPLOY_STATE.DEPLOYED, buttonTooltip: DEPLOY_STATE_TOOLTIP.NO_CHANGES };
  }

  // disable deployment if there are staged integrations with missing pipeline vars
  if (needStagedRevisions) {
    return {
      styleName: 'orange',
      buttonText: DEPLOY_STATE.RESOLVE,
      buttonTooltip: DEPLOY_STATE_TOOLTIP.RESOLVE,
      action: DEPLOY_ACTIONS.OPEN_PIPELINE_VARIABLES_MODAL,
    };
  }

  return enabled;
}
