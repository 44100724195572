import React from 'react';
import { Box } from '@metarouter-private/mr-mui';
import { routes } from 'app/constants';
import BackButton from 'app/shared/navigation/BackButton';
import { useEventSchemaViolationsPageContext } from './EventSchemaViolationsPageContextProvider';

export default function BackToSchemaViolationsButton() {
  const { pipeline } = useEventSchemaViolationsPageContext();
  const linkProps = {
    to: {
      pathname: routes.schemaViolations,
      state: { pipeline },
    },
  };

  return (
    <Box>
      <BackButton {...linkProps}>Back to Schema Violations</BackButton>
    </Box>
  );
}
