import TextCell from 'app/components/data-table/TextCell';
import TimestampCell from 'app/components/data-table/TimestampCell';
import IdentitySyncsCell from './IdentitySyncsCell';

export default function buildAnalyticsFileTableContents({ analyticsFile }) {
  return [
    {
      0: { Component: TextCell, componentProps: { text: 'Last Build', variant: 'body2' } },
      1: {
        Component: TimestampCell,
        componentProps: { timestamp: analyticsFile?.updatedAt, 'data-test-id': 'lastAnalyticsBuildDate' },
      },
    },
    {
      0: { Component: TextCell, componentProps: { text: 'Identity Syncs', variant: 'body2' } },
      1: { Component: IdentitySyncsCell, componentProps: { identitySyncs: analyticsFile?.identitySyncs } },
    },
  ];
}
