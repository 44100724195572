// Return a map of integration IDs to display names
export default function getDisplayNames({ pipelineIntegrations, deployedRevisions }) {
  const displayNames = {};
  const stagedRevisions = pipelineIntegrations.map(pipelineIntegration => pipelineIntegration.stagedRevision);
  deployedRevisions?.forEach(deployedRevision => {
    if (deployedRevision.starterkitId && deployedRevision.integrationId) {
      displayNames[deployedRevision.integrationId] = deployedRevision.starterkitId;
    }
  });
  stagedRevisions?.forEach(stagedRevision => {
    if (stagedRevision.playbook?.integration && stagedRevision.playbook.integration.name) {
      displayNames[stagedRevision.playbook.integration.id] = stagedRevision.playbook.integration.name;
    }
  });
  return displayNames;
}
