import React, { useState } from 'react';

import { MrErrorModal } from '@ion/components';
import { useInsertPipelineIntegration } from '@ion/api';
import PipelineIntegrationForm from 'app/pipeline-details/PipelineIntegrationsTable/pipeline-integration-form/PipelineIntegrationForm';
import { CONSENT_FORM_TYPES } from 'app/pipeline-details/PipelineIntegrationsTable/pipeline-integration-form/CONSENT_FORM_TYPES';
import { NoIntegrations } from 'app/pipeline-details/PipelineIntegrationsTable/pipeline-integration-form/NoIntegrations';
import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';
import { usePipelineIntegrationModalContext } from 'app/pipeline-details/PipelineIntegrationsTable/context/pipeline-integration-modal-context';
import { useGetAvailableIntegrations } from 'app/pipeline-details/PipelineIntegrationsTable/pipeline-integration-form/hooks/useGetAvailableIntegrations';

export const AddIntegration = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const { pipeline } = usePipelineCardContext();
  const { onCloseModal } = usePipelineIntegrationModalContext();
  const { hasAvailableIntegrations, integrationsData } = useGetAvailableIntegrations(pipeline);
  const [createPipelineIntegration] = useInsertPipelineIntegration({
    onCompleted: () => {
      onCloseModal();
    },
    onError: err => {
      setErrorMsg(err.errorMsg);
    },
  });

  const onSubmit = data => {
    const integration = integrationsData.find(integrationData => integrationData.id === data.integration);
    const consentCategoryId = data.consentCategory;
    createPipelineIntegration({
      pipeline,
      integration,
      playbookId: data.revision,
      pipelineVars: data.pipelineVars,
      readyForDeploy: true,
      ...(consentCategoryId && { consentCategoryId }),
    });
  };

  return (
    <>
      {hasAvailableIntegrations ? (
        <PipelineIntegrationForm
          pipeline={pipeline}
          onSubmit={onSubmit}
          type={CONSENT_FORM_TYPES.add}
          onCancel={onCloseModal}
        />
      ) : (
        <NoIntegrations onCancel={onCloseModal} />
      )}

      {errorMsg && (
        <MrErrorModal
          header={'Error Adding Integration to Pipeline'}
          errorMessage={errorMsg}
          closeModal={() => setErrorMsg(false)}
        />
      )}
    </>
  );
};
