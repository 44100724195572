import React from 'react';
import pt from 'prop-types';

import BaseTable from './base-table/BaseTable';
import ConsentCategoryRow from './ConsentCategoryRow';
import { MrH } from '@ion/components';
import s from './index.module.scss';

const SyncSettingsTable = ({ sync }) => (
  <div>
    <MrH h="h6" sans styleNames="sans noMargin">
      {sync.friendlyName}
    </MrH>
    <BaseTable>
      <tbody>
        <ConsentCategoryRow consentCategoryId={sync.consentCategoryId} />
        {Object.entries(sync.settings).map(([setting, value]) => (
          <tr key={setting}>
            <td className={s.setting}>{setting}</td>
            <td className={s.value}>{typeof value === 'string' ? value : JSON.stringify(value)}</td>
          </tr>
        ))}
      </tbody>
    </BaseTable>
  </div>
);

SyncSettingsTable.propTypes = {
  sync: pt.object,
};

export default SyncSettingsTable;
