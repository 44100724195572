import React from 'react';
import { useTrackPage } from '@ion/api';
import RedirectFeatureGate from 'app/launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import { MrAppLayout, MrAppMain } from '@ion/components';
import SideNav from 'app/components/side-nav';
import { Box, Stack } from '@metarouter-private/mr-mui';

import SchemaViolationsTable from './SchemaViolationsTable';
import SchemaViolationsPageContextProvider from './SchemaViolationsPageContextProvider';
import BackButton from './BackButton';

export default function SchemaViolationsPage() {
  useTrackPage('schema_violations_page_viewed');
  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
      <SchemaViolationsPageContextProvider>
        <MrAppLayout title="Schema Enforcement" sideNav={<SideNav />}>
          <MrAppMain>
            <Stack spacing={2}>
              <Box>
                <BackButton />
              </Box>
              <Box>
                <SchemaViolationsTable />
              </Box>
            </Stack>
          </MrAppMain>
        </MrAppLayout>
      </SchemaViolationsPageContextProvider>
    </RedirectFeatureGate>
  );
}
