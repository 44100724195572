import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Edit03Icon, Trash02Icon, Typography } from '@metarouter-private/mr-mui';

import { useModalContext } from 'app/pipelines/ModalContext';

export default function useBuildMenuItems(row) {
  const { deletePipeline, editPipelineName } = useModalContext();
  return [
    {
      label: (
        <Stack direction="row" spacing={1}>
          <Edit03Icon />
          <Typography>Rename Pipeline</Typography>
        </Stack>
      ),
      onClick: () => editPipelineName(row),
    },
    {
      label: (
        <Stack direction="row" spacing={1}>
          <Trash02Icon />
          <Typography>Delete</Typography>
        </Stack>
      ),
      onClick: () => deletePipeline(row),
    },
  ];
}

useBuildMenuItems.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
