const actions = {
  ajs_file_built: 'AJS_FILE_BUILT',
  ajs_file_uploaded: 'AJS_FILE_UPLOADED',
  auth_logged_in: 'AUTH_LOGGED_IN',
  auth_logged_out: 'AUTH_LOGGED_OUT',
  consent_category_created: 'CONSENT_CATEGORY_CREATED',
  consent_category_deleted: 'CONSENT_CATEGORY_DELETED',
  consent_category_updated: 'CONSENT_CATEGORY_UPDATED',
  event_name_created: 'EVENT_NAME_CREATED',
  event_name_deleted: 'EVENT_NAME_DELETED',
  event_name_updated: 'EVENT_NAME_UPDATED',
  event_schema_deleted: 'EVENT_SCHEMA_DELETED',
  integration_added_to_pipeline: 'INTEGRATION_ADDED_TO_PIPELINE',
  integration_created: 'INTEGRATION_CREATED',
  integration_deleted: 'INTEGRATION_DELETED',
  integration_removed_from_pipeline: 'INTEGRATION_REMOVED_FROM_PIPELINE',
  integration_updated: 'INTEGRATION_UPDATED',
  pipeline_created: 'PIPELINE_CREATED',
  pipeline_deleted: 'PIPELINE_DELETED',
  pipeline_deployed: 'PIPELINE_DEPLOYED',
  pipeline_integration_updated: 'PIPELINE_INTEGRATION_UPDATED',
  pipeline_updated: 'PIPELINE_UPDATED',
  pipeline_variable_updated: 'PIPELINE_VARIABLE_UPDATED',
  schema_created: 'SCHEMA_CREATED',
  schema_deleted: 'SCHEMA_DELETED',
  schema_updated: 'SCHEMA_UPDATED',
  schema_library_created: 'SCHEMA_LIBRARY_CREATED',
  schema_library_updated: 'SCHEMA_LIBRARY_UPDATED',
  schema_library_deleted: 'SCHEMA_LIBRARY_DELETED',
  schema_library_published: 'SCHEMA_LIBRARY_PUBLISHED',
  schema_library_added_to_pipeline: 'SCHEMA_LIBRARY_ADDED_TO_PIPELINE',
  schema_library_removed_from_pipeline: 'SCHEMA_LIBRARY_REMOVED_FROM_PIPELINE',
  standalone_sync_file_built: 'STANDALONE_SYNC_FILE_BUILT',
  user_deleted: 'USER_DELETED',
  user_invited: 'USER_INVITED',
  user_role_edited: 'USER_ROLE_EDITED',
};

export default actions;
