import React from 'react';
import pt from 'prop-types';

import BulkSelectPipelines from 'app/pipelines-deprecated/bulk-stage-and-deploy/bulk-select-pipelines/index.js';

const BulkSelectPipelinesPage = ({ location }) => {
  const { revisions, pipelinesList } = location.state;
  return <BulkSelectPipelines revisions={revisions} pipelinesList={pipelinesList} />;
};

BulkSelectPipelinesPage.propTypes = {
  location: pt.object,
};

export default BulkSelectPipelinesPage;
