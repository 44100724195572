import { useTrackWithUser } from '@ion/api';
import deepEqual from 'deep-equal';

// Event constants
const EVENTS = {
  IDENTITY_SYNC_ADDED: 'identity_sync_added_to_file',
  IDENTITY_SYNC_REMOVED: 'identity_sync_removed_from_file',
  IDENTITY_SYNC_UPDATED: 'identity_sync_updated_in_file',
  INTEGRATION_ADDED: 'integration_added_to_file',
  INTEGRATION_REMOVED: 'integration_removed_from_file',
  INTEGRATION_UPDATED: 'integration_updated_in_file',
  ANALYTICS_FILE_SAVED: 'analytics_file_saved',
};

const useTrackAnalyticsFileSaved = () => {
  // Trackers for different events
  const [trackIntegrationAdded] = useTrackWithUser(EVENTS.INTEGRATION_ADDED);
  const [trackIntegrationRemoved] = useTrackWithUser(EVENTS.INTEGRATION_REMOVED);
  const [trackIdentitySyncAdded] = useTrackWithUser(EVENTS.IDENTITY_SYNC_ADDED);
  const [trackIdentitySyncRemoved] = useTrackWithUser(EVENTS.IDENTITY_SYNC_REMOVED);
  const [trackIntegrationUpdated] = useTrackWithUser(EVENTS.INTEGRATION_UPDATED);
  const [trackIdentitySyncUpdated] = useTrackWithUser(EVENTS.IDENTITY_SYNC_UPDATED);
  const [trackAnalyticsFile] = useTrackWithUser(EVENTS.ANALYTICS_FILE_SAVED);

  const trackAnalyticsFileSaved = (
    stagedIntegrationsData,
    stagedIdentitySyncsData,
    previousFileData,
    pipelineId,
    pipelineName,
    analyticsFileId
  ) => {
    const previousIntegrations = previousFileData?.integrations || [];
    const previousIdentitySyncs = previousFileData?.identitySyncs || [];

    // Determine changes for integrations and identity syncs
    const integrationChanges = determineChanges(
      stagedIntegrationsData,
      previousIntegrations,
      (a, b) => a.name === b.name,
      'options'
    );
    const identitySyncChanges = determineChanges(
      stagedIdentitySyncsData,
      previousIdentitySyncs,
      (a, b) => a.name === b.name,
      'settings'
    );

    // Track changes for integrations
    integrationChanges.toAdd.forEach(integration => {
      trackIntegrationAdded({
        name: integration.name,
        pipelineId,
        pipelineName,
        analyticsFileId,
        isInitialConfig: !previousFileData,
      });
    });
    integrationChanges.toRemove.forEach(integration => {
      trackIntegrationRemoved({
        name: integration.name,
        pipelineId,
        pipelineName,
        analyticsFileId,
        isInitialConfig: !previousFileData,
      });
    });
    integrationChanges.toUpdate.forEach(integration => {
      trackIntegrationUpdated({
        name: integration.name,
        pipelineId,
        pipelineName,
        analyticsFileId,
        isInitialConfig: !previousFileData,
      });
    });

    // Track changes for identity syncs
    identitySyncChanges.toAdd.forEach(identitySync => {
      trackIdentitySyncAdded({
        name: identitySync.name,
        pipelineId,
        pipelineName,
        analyticsFileId,
        isInitialConfig: !previousFileData,
      });
    });
    identitySyncChanges.toRemove.forEach(identitySync => {
      trackIdentitySyncRemoved({
        name: identitySync.name,
        pipelineId,
        pipelineName,
        analyticsFileId,
        isInitialConfig: !previousFileData,
      });
    });
    identitySyncChanges.toUpdate.forEach(identitySync => {
      trackIdentitySyncUpdated({
        name: identitySync.name,
        pipelineId,
        pipelineName,
        analyticsFileId,
        isInitialConfig: !previousFileData,
      });
    });

    trackAnalyticsFile({
      pipelineName,
      pipelineId,
      analyticsFileId,
    });
  };

  return { trackAnalyticsFileSaved };
};

const determineChanges = (stagedData, previousData, compareFn, settingsKey) => ({
  toAdd: stagedData.filter(item => !previousData.some(prevItem => compareFn(prevItem, item))),
  toRemove: previousData.filter(item => !stagedData.some(stagedItem => compareFn(item, stagedItem))),
  toUpdate: stagedData.filter(item =>
    previousData.some(prevItem => compareFn(prevItem, item) && !deepEqual(prevItem[settingsKey], item[settingsKey]))
  ),
});

export default useTrackAnalyticsFileSaved;
