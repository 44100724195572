import React from 'react';
import PropTypes from 'prop-types';
import { ALLOW_ROW_EVENTS } from '../buildBubbleClickProps';

export default function ClickableElement({ Component = 'span', children }) {
  return <Component {...ALLOW_ROW_EVENTS}>{children}</Component>;
}

ClickableElement.propTypes = {
  Component: PropTypes.elementType,
  children: PropTypes.node.isRequired,
};
