import { useInsertDuplicateSchemaLibraryVersion, useUpdateSchemaLibraryVersion } from '@ion/api';

import {
  SCHEMA_LIBRARY_VERSION_STATUS,
  useSchemaLibraryPageContext,
} from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';

const PUBLISH_VERSION_LABEL = 'Publish Version';
const DUPLICATE_VERSION_LABEL = 'Duplicate Version';

const PUBLISH_DISABLED_MESSAGE = 'A library must contain at least one event schema entry to be published';
const DUPLICATE_DISABLED_MESSAGE = 'This library already contains a draft version';

export default function useActionButtonProps() {
  const { activeVersion, libraryName, isPublishable, containsDraftVersion } = useSchemaLibraryPageContext();
  const [insertDuplicateSchemaLibraryVersion] = useInsertDuplicateSchemaLibraryVersion();
  const [updateSchemaLibraryVersion] = useUpdateSchemaLibraryVersion();

  const isDraft = activeVersion.status === SCHEMA_LIBRARY_VERSION_STATUS.DRAFT;

  const onDuplicateVersion = () => {
    insertDuplicateSchemaLibraryVersion({ schemaLibraryVersionId: activeVersion.id });
  };

  const onPublishDraft = () => {
    updateSchemaLibraryVersion({
      id: activeVersion.id,
      status: SCHEMA_LIBRARY_VERSION_STATUS.PUBLISHED,
      name: libraryName,
      version: activeVersion.version,
    });
  };

  if (isDraft) {
    return {
      label: PUBLISH_VERSION_LABEL,
      disabled: !isPublishable,
      disabledMessage: PUBLISH_DISABLED_MESSAGE,
      onClick: onPublishDraft,
    };
  }

  return {
    label: DUPLICATE_VERSION_LABEL,
    disabled: containsDraftVersion,
    disabledMessage: DUPLICATE_DISABLED_MESSAGE,
    onClick: onDuplicateVersion,
  };
}
