import validateStagedPipeline from './validateStagedPipeline';
import getSdkConnection from './getSdkConnection';
import mapDefaultValue from './mapDefaultValue';
import { removeUiFields } from 'app/playbook-editor/revision-header/download-upload/download/remove-ui-fields';
import { cloneDeep as _cloneDeep } from 'lodash';

export default function buildPipelineJson(
  pipelineIntegrations,
  author,
  writeKey,
  pipelineFilter = undefined,
  pipelineTransform = undefined
) {
  const stagedPipelineIntegrations = pipelineIntegrations.filter(
    pipelineIntegration => !pipelineIntegration.stagedRevision.stagedForDelete
  );

  validateStagedPipeline(stagedPipelineIntegrations);
  const integrations = stagedPipelineIntegrations.map(pipelineIntegration => {
    const { pipelineIntegrationConsent } = pipelineIntegration;
    const { playbook, pipelineVars, id } = pipelineIntegration.stagedRevision;
    const {
      revision,
      vars,
      playbookJson,
      connectionJson,
      integration: { starterkitId, id: integrationId },
      pipeline_variables,
    } = playbook;
    const { endpointSchema, connectionTemplate } = connectionJson;
    const connectionType = Object.keys(connectionJson)[0] === 'endpointSchema' ? 'endpointSchema' : 'connection';

    /**
     * Playbook version gets added to JSON here in order to get the correct value from the auto-incremented
     * DB revision column, otherwise it would need to be calculated client-side before inserting the playbook.
     */
    const mutablePlaybook = _cloneDeep(playbookJson);
    mutablePlaybook.version = String(revision);

    // (EunHye 2023.02.28) - line 25 is necessary for backwards compatability
    const integrationJson = {
      ...(connectionType === 'endpointSchema' && { connection: { params: [] } }),
      [connectionType]: endpointSchema
        ? mapDefaultValue(endpointSchema, vars, pipelineVars, pipeline_variables)
        : getSdkConnection({
            integrationName: starterkitId,
            connection: connectionTemplate,
            vars,
            pipelineVars,
            pipeline_variables,
          }),
      integrationName: starterkitId,
      id: integrationId,
      revision: {
        id: id,
      },
      transformStages: [
        {
          data: {
            playbook: removeUiFields(mapDefaultValue(mutablePlaybook, vars, pipelineVars, pipeline_variables)),
          },
          name: starterkitId,
          desc: `pipeline for ${starterkitId} integration`,
        },
      ],
    };
    if (pipelineIntegrationConsent) {
      integrationJson.consent = {
        category: pipelineIntegrationConsent.consentCategory.code,
      };
    }
    return integrationJson;
  });

  const result = {
    writeKey,
    integrations,
    author,
  };

  if (pipelineFilter !== undefined) {
    result.filter = pipelineFilter;
  }

  if (pipelineTransform !== undefined) {
    result.transform = pipelineTransform;
  }

  return result;
}
