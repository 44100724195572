import { useState } from 'react';
import { useOpen } from '@ion/components';

export default function useDrawerContextValue() {
  const [drawerData, setDrawerData] = useState(null);
  const [isOpen, onOpen, onClose] = useOpen();

  const launchDrawer = data => {
    setDrawerData(data);
    onOpen();
  };

  const onCloseWrapper = () => {
    onClose();
  };

  return {
    launchDrawer,
    drawer: {
      data: drawerData,
      isOpen,
      onClose: onCloseWrapper,
    },
  };
}
