import React from 'react';
import PropTypes from 'prop-types';

import { GatedConsentCategoriesContext } from 'app/context/gated-consent-categories-context.js';
import { PipelineIntegrationModalProvider } from 'app/pipeline-details/PipelineIntegrationsTable/context/pipeline-integration-modal-context';
import PipelineIntegrationsTable from 'app/pipeline-details/PipelineIntegrationsTable';

const PipelineIntegrationsSection = ({ deployedRevisions, pipelineIntegrations }) => {
  return (
    <GatedConsentCategoriesContext>
      <PipelineIntegrationModalProvider>
        <PipelineIntegrationsTable deployedRevisions={deployedRevisions} pipelineIntegrations={pipelineIntegrations} />
      </PipelineIntegrationModalProvider>
    </GatedConsentCategoriesContext>
  );
};

PipelineIntegrationsSection.propTypes = {
  deployedRevisions: PropTypes.array.isRequired,
  pipelineIntegrations: PropTypes.array.isRequired,
};

export default PipelineIntegrationsSection;
