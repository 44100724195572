import permissions from 'api/Apollo/permissions';
import { METHOD, PATH } from 'api/control/control-api';
import { useControlProxy, normalizeResponse } from 'api/control/useControlProxy';

export default function useGetViolationEvents(options = {}) {
  const [call, response] = useControlProxy({
    permission: permissions.readEvents,
    ...options,
  });

  const getPipelineSchemaViolationsDetails = (
    clusterId,
    earliestSeen,
    writekey,
    eventName,
    type,
    field,
    details,
    schemaId,
    limit = 10
  ) => {
    return call({
      path: PATH.PipelineSchemaViolationsDetailsEvents,
      method: METHOD.GET,
      body: { earliestSeen, writeKey: writekey, eventName, type, field, details, schemaId, limit },
      clusterId,
    });
  };

  return [getPipelineSchemaViolationsDetails, normalizeResponse(response, transformResponse)];
}

function transformResponse(response) {
  if (!response) {
    return response;
  }
  return response.events;
}
