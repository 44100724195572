import React from 'react';
import PropTypes from 'prop-types';
import DrawerContext from './DrawerContext';
import useDrawerContextValue from './useDrawerContextValue';

export default function DrawerContextProvider({ children }) {
  const value = useDrawerContextValue();

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
}

DrawerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
