import React from 'react';

import PipelineOverviewStaticTable from 'app/pipeline-details/PipelineOverviewStaticTable';

import useAnalyticsFileTable from './useAnalyticsFileTable';

export default function AnalyticsFileTable() {
  const props = useAnalyticsFileTable();

  return <PipelineOverviewStaticTable {...props} />;
}
