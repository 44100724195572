import React from 'react';
import { DrawerContextProvider } from 'app/shared/contexts/drawer';
import SchemaViolationsDetailsTable from './SchemaViolationsDetailsTable';
import ViolationEventsDrawer from './ViolationEventsDrawer';

export default function SchemaViolationsDetailsTableSection() {
  return (
    <DrawerContextProvider>
      <ViolationEventsDrawer />
      <SchemaViolationsDetailsTable />
    </DrawerContextProvider>
  );
}
