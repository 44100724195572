import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import CreatePipelineSchemaLibraryModal from 'app/pipeline-details/SchemaEnforcementTable/modals/CreatePipelineSchemaLibraryModal';

import AddSchemaLibraryButton from './AddSchemaLibraryButton';
import SchemaViolationsButton from './SchemaViolationsButton';

export default function SchemaEnforcementTableActions({ pipelineSchemaLibrary }) {
  if (isEmpty(pipelineSchemaLibrary)) {
    return <ModalLauncher Launcher={AddSchemaLibraryButton} Modal={CreatePipelineSchemaLibraryModal} />;
  }

  return <SchemaViolationsButton />;
}

SchemaEnforcementTableActions.propTypes = {
  pipelineSchemaLibrary: PropTypes.object,
};
