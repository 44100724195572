import determinePipelineStatus from './determinePipelineStatus';

export default function mergeIonAndControlPipelines(ionPipelines, controlPipelines) {
  return ionPipelines.map(ionPipeline => {
    const controlPipeline = controlPipelines.find(controlPipeline => controlPipeline.pipelineId === ionPipeline.id);

    const status = determinePipelineStatus(ionPipeline, controlPipeline);

    return {
      status,
      lastDeployed: controlPipeline.pipeline?.createdAt,
      ...ionPipeline,
    };
  });
}
