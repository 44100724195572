import React from 'react';
import propTypes from 'prop-types';

import { MrIcon, MrIconButton } from '@ion/components';
import { isAllowedToInsertConsentCategory } from '@ion/api/consent-categories/insert/insert-consent-category-permission';
import useHasConsentModule from './useHasConsentModule';

const DEFAULT_BUTTON_PROPS = {
  text: 'Add Category',
  Icon: <MrIcon id="start-icon" fontSize="small" />,
};

export default function AddCategoryButton({ onAddCategory }) {
  const isAllowedToAddCategory = useIsAllowedToAddCategory();
  return <MrIconButton {...DEFAULT_BUTTON_PROPS} onClick={onAddCategory} disabled={!isAllowedToAddCategory} />;
}

export function useIsAllowedToAddCategory() {
  const hasConsentModule = useHasConsentModule();
  const hasPermissions = isAllowedToInsertConsentCategory();
  return hasPermissions && hasConsentModule;
}

AddCategoryButton.propTypes = {
  onAddCategory: propTypes.func.isRequired,
};
