import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useQueryPipelineWithRoost } from '@ion/api';

const PipelinePageContext = createContext(null);

export default function PipelinePageContextProvider({ children }) {
  const history = useHistory();
  const {
    location: {
      state: { pipelineId },
    },
  } = history;

  const {
    data: { legacy },
    refetch,
    loading,
  } = useQueryPipelineWithRoost({
    pipelineId,
  });

  return (
    <PipelinePageContext.Provider value={{ pipeline: legacy, pipelineId, refetch, loading }}>
      {children}
    </PipelinePageContext.Provider>
  );
}

export function usePipelinePageContext() {
  return useContext(PipelinePageContext);
}

PipelinePageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
