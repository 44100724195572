/* eslint-disable filename-export/match-default-export */

import { isEmpty as _isEmpty, isObject as _isObject, toString as _toString } from 'lodash';
import { CONSENT_COOKIE_NAME } from './analytics-builder-settings';

/**
 *
 * Populate analytics builder form with saved analytics file data
 *
 * @params analyticsFileData, setField, setFormDataFilled
 */

const populateAjsBuilderForm = (analyticsFileData, setField) => {
  // copy saved general settings to formData
  if (_isEmpty(analyticsFileData)) {
    setField({
      target: {
        name: 'cookieName',
        value: CONSENT_COOKIE_NAME,
      },
    });
    return;
  }
  setField({
    target: {
      name: 'fileCompression',
      value: analyticsFileData.settings.compressFile,
    },
  });
  let cdnURL = '';
  if (analyticsFileData.settings.cdnURL !== undefined) {
    cdnURL = analyticsFileData.settings.cdnURL.replace('https://', '');
  }
  setField({
    target: {
      name: 'cdnURL',
      value: cdnURL,
    },
  });

  let fileHostingType = analyticsFileData.settings.fileHostingType || '';
  setField({
    target: {
      name: 'fileHostingType',
      value: fileHostingType,
    },
  });

  let host = '';
  if (analyticsFileData.settings.host !== undefined) {
    // remove 'https://' ingestion URL
    host = analyticsFileData.settings.host.replace('https://', '');
  }

  setField({
    target: {
      name: 'host',
      value: host,
    },
  });

  let impliedConsent = '';
  if (analyticsFileData.settings.impliedConsent !== undefined) {
    impliedConsent = _toString(analyticsFileData.settings.impliedConsent);
  }

  setField({
    target: {
      name: 'impliedConsent',
      value: impliedConsent,
    },
  });

  let cookieName = CONSENT_COOKIE_NAME;
  if (analyticsFileData.settings.cookieName !== undefined) {
    cookieName = analyticsFileData.settings.cookieName;
  }

  setField({
    target: {
      name: 'cookieName',
      value: cookieName,
    },
  });

  setField({
    target: {
      name: 'httpCookie',
      value: analyticsFileData.settings.itpSettings === true,
    },
  });

  // copy saved cross-domain settings to formData
  const crossDomainSync = analyticsFileData.identitySyncs.find(s => s.name === 'crossDomain');
  if (crossDomainSync) {
    setField({
      target: {
        name: 'crossDomain',
        value: crossDomainSync.settings.syncURLs.join(', '),
      },
    });
  }

  // copy saved identity sync settings to formData
  analyticsFileData.identitySyncs.forEach(sync => {
    setField({
      target: {
        name: `sync-${sync.name}-consentCategoryId`,
        value: sync.consentCategoryId,
      },
    });
    for (const setting in sync.settings) {
      setField({
        target: {
          name: `sync-${sync.name}-${setting}`,
          value:
            // Stringify objects before submitting to formState
            _isObject(sync.settings[setting]) ? JSON.stringify(sync.settings[setting]) : sync.settings[setting],
        },
      });
    }
  });

  // copy saved google tag settings to formData

  if (analyticsFileData.google_tag_settings) {
    const { tagId, consentEnabled, consentExplicitConsent, consentSyncCategories } =
      analyticsFileData.google_tag_settings;

    setField({
      target: {
        name: `sync-parentGTag-parentTagID`,
        value: tagId,
      },
    });

    setField({
      target: {
        name: `sync-parentGTag-consentModeEnabled`,
        value: consentEnabled ? 'true' : 'false',
      },
    });

    setField({
      target: {
        name: `sync-parentGTag-explicitConsent`,
        value: consentExplicitConsent ? 'true' : 'false',
      },
    });

    if (consentSyncCategories) {
      for (const key in consentSyncCategories) {
        setField({
          target: {
            name: `sync-parentGTag-${key}ConsentMode`,
            value: consentSyncCategories[key].map(cat => ({
              label: cat,
              value: cat,
            })),
          },
        });
      }
    }
  }

  // copy saved integration settings to formData
  analyticsFileData.integrations.forEach(integration => {
    for (const option in integration.options) {
      setField({
        target: {
          name: `integration-${integration.name}-${option}`,
          value:
            // Stringify objects before submitting to formState
            _isObject(integration.options[option])
              ? JSON.stringify(integration.options[option])
              : integration.options[option],
        },
      });
    }
  });

  setField({
    target: {
      name: 'windowVariableName',
      value: analyticsFileData.settings.windowVariableName,
    },
  });

  setField({
    target: {
      name: 'storagePrefix',
      value: analyticsFileData.settings.storagePrefix,
    },
  });

  setField({
    target: {
      name: 'storageType',
      value: analyticsFileData.settings.storageType,
    },
  });

  setField({
    target: {
      name: 'storageDatabase',
      value: analyticsFileData.settings.storageDatabase,
    },
  });
};

export default populateAjsBuilderForm;
