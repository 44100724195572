import React from 'react';
import PropTypes from 'prop-types';

import TimeAgoAndDate from 'app/components/TimeAgoAndDate';
import TextCell from 'app/components/data-table/TextCell';
import buildBubbleClickProps from 'app/components/data-table/buildBubbleClickProps';

export default function TimestampCell({ timestamp, bubbleClickEvent, ...containerProps }) {
  if (!timestamp) {
    return <TextCell bubbleClickEvent />;
  }

  const TimeAgoProps = buildTypographyProps(bubbleClickEvent);
  const FormattedDateProps = buildTypographyProps(bubbleClickEvent);

  return (
    <TimeAgoAndDate
      {...containerProps}
      timestamp={timestamp}
      TimeAgoProps={TimeAgoProps}
      FormattedDateProps={FormattedDateProps}
    />
  );
}

TimestampCell.propTypes = {
  timestamp: PropTypes.string,
  bubbleClickEvent: PropTypes.bool,
};

function buildTypographyProps(bubbleClickEvent) {
  return {
    TypographyProps: {
      ...buildBubbleClickProps(bubbleClickEvent),
    },
  };
}
