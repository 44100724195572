import { useQueryClusters } from '@ion/api';
import { useMemo } from 'react';

export default function useGetClusterOptions() {
  const { data: clustersData } = useQueryClusters();

  return useMemo(
    () =>
      clustersData.map(cluster => {
        return { label: cluster.name, value: cluster.id };
      }),
    [clustersData]
  );
}
