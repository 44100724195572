import useMutation from 'api/hooks/useMutation';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import permissions from 'api/Apollo/permissions';
import mergeQueries from 'api/Apollo/merge-queries';

import {
  mutation as updateSchemaLibraryMutation,
  buildVariables as buildUpdateSchemaLibraryVariables,
} from './update-schema-library-mutation-builder';

import {
  mutation as schemaLibraryUpdatedMutation,
  buildVariables as buildSchemaLibraryUpdatedVariables,
} from 'api/audit-logs/schema-library-updated/audit-log-mutation-builder';

const combinedMutation = mergeQueries('UpdateSchemaLibraryAndAudit', [
  updateSchemaLibraryMutation,
  schemaLibraryUpdatedMutation,
]);

export default function useUpdateSchemaLibrary(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_updated');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    context: { permission: permissions.updateSchemas },
    successMsg: 'Schema Library updated',
  });

  const updateSchemaLibraryMutation = async ({ id, name, description }) => {
    const response = await executeMutation({
      variables: {
        ...buildUpdateSchemaLibraryVariables({
          id,
          name,
          description,
        }),
        ...buildSchemaLibraryUpdatedVariables({
          id,
          name,
          description,
        }),
      },
    });

    if (!response.errors) {
      trackWithUser();
    }
  };

  return [updateSchemaLibraryMutation, response];
}
