import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@metarouter-private/mr-mui';

import { PIPELINE_STATUSES } from '@ion/api';

const TOOLTIP_PROPS = {
  arrow: true,
  placement: 'top',
  title: `
    Error determining pipeline's status. If this continues, please contact your Customer Success Manager for assistance.
  `,
};

export default function PipelineErrorTooltip({ status, children }) {
  if (status === PIPELINE_STATUSES.Error) {
    return <Tooltip {...TOOLTIP_PROPS}>{children}</Tooltip>;
  }

  return children;
}

PipelineErrorTooltip.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
