import React from 'react';
import PropTypes from 'prop-types';

import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';

import isAllowedToWritePipeline from '@ion/api/pipelines/isAllowedToWritePipeline';

const TITLE = 'New Pipeline';

export default function NewPipelineButton({ onClick }) {
  const hasPermission = isAllowedToWritePipeline();

  return (
    <Button startIcon={<PlusCircleIcon />} disabled={!hasPermission} onClick={onClick}>
      {TITLE}
    </Button>
  );
}

NewPipelineButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
