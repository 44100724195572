import React from 'react';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import CreatePipelineSchemaLibraryModal from 'app/pipeline-details/SchemaEnforcementTable/modals/CreatePipelineSchemaLibraryModal';
import { usePipelineSchemaLibraryTableContext } from 'app/pipelines-deprecated/card/PipelineSchemaSection/PipelineSchemaLibraryTableContext';
import AddSchemaLibraryButton from 'app/pipeline-details/SchemaEnforcementTable/SchemaEnforcementTableActions/AddSchemaLibraryButton';

import SchemaViolationsLink from './SchemaViolationsLink';

export default function PipelineSchemaTableActions() {
  const { schemaLibraryVersionId } = usePipelineSchemaLibraryTableContext();

  if (schemaLibraryVersionId) {
    return <SchemaViolationsLink />;
  }

  return <ModalLauncher Launcher={AddSchemaLibraryButton} Modal={CreatePipelineSchemaLibraryModal} />;
}
