import React from 'react';

import { FormModal, Edit03Icon } from '@metarouter-private/mr-mui';

import PipelineNameForm from 'app/pipelines/modals/PipelineNameForm';
import useEditPipelineNameModal from './useEditPipelineNameModal';

const MODAL_CONFIG = {
  title: 'Rename Pipeline',
  helperText:
    "Updating the pipeline name will only change how it appears in the UI. This modification doesn't require deployment to the cluster and won't affect the pipeline's functionality.",
  TitleIcon: Edit03Icon,
  submitLabel: 'Save Changes',
};

export default function EditPipelineNameModal() {
  const { name, showEditModal, onClose, onSubmit } = useEditPipelineNameModal();

  return (
    <FormModal open={showEditModal} onClose={onClose} onSubmit={onSubmit} {...MODAL_CONFIG}>
      <PipelineNameForm name={name} />
    </FormModal>
  );
}
