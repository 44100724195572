import isEmpty from 'lodash/isEmpty';
import PIPELINE_STATUSES from 'api/pipelines/useQueryPipelineWithRoost/PIPELINE_STATUSES';
import { PIPELINE_INTEGRATION_TYPES } from './PIPELINE_INTEGRATION_TYPES';

export default function mergeUnmanagedDeployedIntegrations({ mergedPipelineIntegrations, deployedIntegrations }) {
  if (isEmpty(deployedIntegrations)) {
    return [];
  }

  return deployedIntegrations
    .filter(deployedIntegration => {
      const isManagedDeployedIntegration = mergedPipelineIntegrations.find(
        mergedPipelineIntegration =>
          deployedIntegration.revision.id === mergedPipelineIntegration.stagedRevision.local.id
      );
      return !isManagedDeployedIntegration;
    })
    .map(deployedIntegration => {
      const { transformStages } = deployedIntegration;
      const version = transformStages[0].data.playbook.version;

      return {
        id: deployedIntegration.id,
        isChanged: true,
        status: PIPELINE_STATUSES.StagedForDeploy,
        integration: {
          starterkitId: deployedIntegration.integrationName,
          name: deployedIntegration.integrationName,
        },
        stagedRevision: {
          isChanged: true,
          deployed: {
            revision: version,
          },
          local: {
            stagedForDelete: true,
          },
        },
        type: PIPELINE_INTEGRATION_TYPES.UNMANAGED,
      };
    });
}
