import React from 'react';

import { MrIconButton, MrIcon } from '@ion/components';

import { usePipelineIntegrationModalContext } from 'app/pipeline-details/PipelineIntegrationsTable/context/pipeline-integration-modal-context';
import { PIPELINE_MODAL_OPTIONS } from 'app/pipeline-details/PipelineIntegrationsTable/pipeline-modal/PipelineModal';

const DEFAULT_BUTTON_PROPS = {
  variant: 'outlined',
  size: 'medium',
  text: 'Add Integration',
  Icon: <MrIcon id="start-icon" fontSize="small" />,
};

export default function AddIntegrationButton() {
  const { onOpenModal } = usePipelineIntegrationModalContext();
  const onClickAddButton = () => {
    onOpenModal(PIPELINE_MODAL_OPTIONS.addIntegration);
  };

  return <MrIconButton {...DEFAULT_BUTTON_PROPS} onClick={onClickAddButton} />;
}
