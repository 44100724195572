import React from 'react';
import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useViolationEvents from './useViolationEvents';

const ViolationEventsDrawerContext = createContext(null);

export default function ViolationEventsDrawerContextProvider({ children }) {
  const { loading, violationEvents } = useViolationEvents();
  return (
    <ViolationEventsDrawerContext.Provider value={{ loading, violationEvents }}>
      {children}
    </ViolationEventsDrawerContext.Provider>
  );
}

export function useViolationEventsDrawerContext() {
  return useContext(ViolationEventsDrawerContext);
}

ViolationEventsDrawerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
