import React from 'react';
import { Drawer } from '@metarouter-private/mr-mui';
import { useDrawerContext } from 'app/shared/contexts/drawer';
import ViolationEventsDrawerContent from './ViolationEventsDrawerContent';

export default function ViolationEventsDrawer() {
  const {
    drawer: { isOpen, onClose },
  } = useDrawerContext();

  /* Usually we don't want to use fixed px widths but because the drawer
    contains a Carousel with fixed with items, we need to coordinate here.
   */
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} width="800px">
      <ViolationEventsDrawerContent />
    </Drawer>
  );
}
