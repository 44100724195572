import React from 'react';

import PipelineOverviewStaticTable from 'app/pipeline-details/PipelineOverviewStaticTable';

import useSchemaEnforcementTable from './useSchemaEnforcementTable';

export default function SchemaEnforcementTable() {
  const props = useSchemaEnforcementTable();

  return <PipelineOverviewStaticTable {...props} />;
}
