import React from 'react';
import LinkButton from 'app/shared/navigation/LinkButton';
import useSchemaViolationsButtonProps from './useSchemaViolationsButtonProps';

const SCHEMA_VIOLATIONS_BUTTON_LABEL = 'Schema Violations';

export default function SchemaViolationsButton() {
  const buttonProps = useSchemaViolationsButtonProps();

  return <LinkButton {...buttonProps}>{SCHEMA_VIOLATIONS_BUTTON_LABEL}</LinkButton>;
}
