export const FEATURE_FLAGS = {
  aceEditor: 'aceEditor',
  schemaViolationEventsMvp: 'schemaViolationEventsMvp',
  builderServerAdvancedSettings: 'builderServerAdvancedSettings',
  eventDebuggerMvp: 'eventDebuggerMvp',
  intendedSchemaMvp: 'intendedSchemaMvp',
  pipelinesListRevamp: 'pipelinesListRevamp',
  roUserManagementMVP: 'roUserMgmtMvp',
  uploadAjsFileToCluster: 'uploadAjsFileToCluster',
  splitGoogleTagSettings: 'splitGoogleTagSettings',
  configurableConsentMvp: 'configurableConsentMvp',
  ecmConfigurableConsent: 'ecmConfigurableConsent',
  ecmStandAloneSync: 'ecmStandAloneSync',
};
