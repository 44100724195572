import { gql } from '@apollo/client';
import schemaLibrary from 'api/schema-libraries/schemaLibrary';
import schemaLibraryVersion from 'api/schema-library-versions/schemaLibraryVersion';

export const mutation = gql`
  mutation InsertSchemaLibrary($schema_libraries_insert_input: schema_libraries_insert_input!) {
    schemaLibrary: insert_schema_libraries_one(
      object: $schema_libraries_insert_input
      on_conflict: { constraint: schema_libraries_name_org_id_key, update_columns: name }
    ) {
      ...schemaLibrary
      schemaLibraryVersion: schema_library_versions {
        ...schemaLibraryVersion
      }
    }
  }
  ${schemaLibrary}
  ${schemaLibraryVersion}
`;

export function buildVariables({ id, name, description }) {
  return {
    schema_libraries_insert_input: {
      name,
      description,
      schema_library_versions: {
        data: {
          id,
          status: 'DRAFT',
          version: 1,
        },
        on_conflict: {
          constraint: 'schema_library_versions_schema_library_id_version_key',
          update_columns: [],
        },
      },
    },
  };
}
