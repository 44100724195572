import React from 'react';
import PropTypes from 'prop-types';
import { routes } from 'app/constants';
import BackButton from 'app/shared/navigation/BackButton';

export default function BackToPipelineOverviewButton({ pipelineId }) {
  const linkProps = {
    to: {
      pathname: routes.pipelineDetails,
      state: { pipelineId },
    },
  };

  return <BackButton {...linkProps}>Back to Pipeline Overview</BackButton>;
}

BackToPipelineOverviewButton.propTypes = {
  pipelineId: PropTypes.string.isRequired,
};
