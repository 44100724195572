import { buildVariables as buildUpsertPipelineSchemaLibraryVariables } from 'api/pipeline-schema-libraries/upsert/upsert-pipeline-schema-library-mutation-builder';
import { buildVariables as buildAuditLogVariables } from 'api/audit-logs/schema-library-added-to-pipeline/audit-log-mutation-builder';

function buildAdditionalVariables({
  pipelineSchemaLibraryId,
  pipelineId,
  pipelineName,
  schemaLibraryVersionId,
  schemaLibraryName,
  schemaLibraryVersion,
}) {
  const auditLogVariables = buildAuditLogVariables({
    pipelineId,
    pipelineName,
    schemaLibraryVersionId,
    schemaLibraryName,
    schemaLibraryVersion,
  });

  const upsertPipelineSchemaLibraryVariables = buildUpsertPipelineSchemaLibraryVariables({
    id: pipelineSchemaLibraryId,
    pipelineId,
    schemaLibraryVersionId,
  });

  const additionalVariables = {
    ...auditLogVariables,
    ...upsertPipelineSchemaLibraryVariables,
  };

  return additionalVariables;
}

export default buildAdditionalVariables;
