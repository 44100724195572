import React from 'react';
import PropTypes from 'prop-types';

import { useQueryIntegrationPlaybooks } from '@ion/api';
import { MrH, MrSection } from '@ion/components';
import { PipelineVariableInput } from './PipelineVariableInput';

export const PipelineVariablesForm = ({ formState, setField, resetError, revisionId, integrationId }) => {
  const pipelineVariableNames = [];
  const { data: integrationPlaybooksData, loading } = useQueryIntegrationPlaybooks({
    variables: { integrationId: integrationId },
  });

  if (loading) {
    return null;
  }
  const selectedRevision = integrationPlaybooksData.playbooks.find(playbook => playbook.id === revisionId);

  selectedRevision.pipeline_variables.forEach(variable => {
    const variableName = variable.name;
    if (!pipelineVariableNames.includes(variableName)) {
      pipelineVariableNames.push(variableName);
    }
  });

  return (
    pipelineVariableNames.length > 0 && (
      <MrSection>
        <MrH h="h5" styleNames="sans smallGray">
          Pipeline Variables
        </MrH>

        {pipelineVariableNames.map(variableName => {
          const fieldName = `pipeline-variable-${variableName}`;
          const formField = formState[fieldName];
          return (
            <PipelineVariableInput
              key={fieldName}
              fieldName={fieldName}
              formField={formField}
              variableName={variableName}
              resetError={resetError}
              setField={setField}
            />
          );
        })}
      </MrSection>
    )
  );
};

PipelineVariablesForm.propTypes = {
  formState: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  revisionId: PropTypes.string.isRequired,
  integrationId: PropTypes.string.isRequired,
};
