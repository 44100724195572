import { useState } from 'react';
import useLazyQuery from 'api/hooks/useLazyQuery';
import permissions from 'api/Apollo/permissions';
import { useGetPipeline } from 'api/control/useControlProxy';
import { buildVariables, GET_PIPELINES } from './pipelines-query-builder';
import mergeIonAndControlPipelines from './utils/mergeIonAndControlPipelines';

export default function useLazyQueryPipelines({ ...options } = {}) {
  const [mergedPipelines, setMergedPipelines] = useState([]);
  const [getControlProxyPipeline] = useGetPipeline();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [executeQuery, { data: ionPipelineResponse, ...rest }] = useLazyQuery(GET_PIPELINES, {
    context: { permission: permissions.readPipeline },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
    onCompleted: mergeWithControlPipelines,
  });

  const fetchControlPipeline = async pipeline => {
    try {
      const controlDataResponse = await getControlProxyPipeline({
        clusterId: pipeline.cluster.id,
        writekey: pipeline.writekey,
      });

      return {
        pipelineId: pipeline.id,
        ...controlDataResponse,
      };
    } catch (error) {
      return {
        pipelineId: pipeline.id,
        error,
      };
    }
  };

  function mergeWithControlPipelines(ionPipelineResponse) {
    async function fetchControlPipelines() {
      try {
        const controlPipelinesResponse = await Promise.all(ionPipelineResponse.pipelines.map(fetchControlPipeline));
        const mergedPipelines = mergeIonAndControlPipelines(ionPipelineResponse.pipelines, controlPipelinesResponse);
        setMergedPipelines(mergedPipelines);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    }

    if (ionPipelineResponse) {
      fetchControlPipelines();
    }
  }

  const getPipelines = queryParams => {
    setLoading(true);
    const variables = buildVariables(queryParams);
    executeQuery({ variables });
  };

  return [
    getPipelines,
    {
      pipelines: mergedPipelines,
      pagination: ionPipelineResponse?.pipelinesAggregate.aggregate ?? { count: null },
      ...rest,
      loading,
      error,
    },
  ];
}
