import { isArrayEqual } from '@ion/mrdash';
import mapPipelineVarsFromPipelineVariables from './mapPipelineVarsFromPipelineVariables';

export default function isEqualPipelineIntegrations({ deployedRevisions, pipelineIntegrations }) {
  const deployedRevisionsComparator = deployedRevisions.map(deployedRevision => {
    return {
      integrationId: deployedRevision.integrationId,
      revision: deployedRevision.revision,
      stagedForDelete: deployedRevision.stagedForDelete,
      pipelineVars: deployedRevision.pipelineVars,
      consentCategoryCode: deployedRevision.consent?.category ?? null,
    };
  });

  const pipelineIntegrationsComparator = pipelineIntegrations.map(pipelineIntegration => {
    const { stagedRevision, pipelineIntegrationConsent } = pipelineIntegration;
    const pipelineVars = mapPipelineVarsFromPipelineVariables({
      pipeline_variables: stagedRevision.playbook.pipeline_variables,
      pipelineVars: stagedRevision.pipelineVars,
    });
    return {
      integrationId: stagedRevision.playbook.integration.id,
      revision: stagedRevision.playbook.revision,
      stagedForDelete: stagedRevision.stagedForDelete,
      pipelineVars: pipelineVars,
      consentCategoryCode: pipelineIntegrationConsent?.consentCategory.code ?? null,
    };
  });

  return isArrayEqual(deployedRevisionsComparator, pipelineIntegrationsComparator);
}
