import { useEffect } from 'react';
import { useGetPipelineSchemaViolations } from '@ion/api';
import { usePipelineCardContext } from 'app/pipelines-deprecated/card/context/pipeline-card-context';
import buildViolationsCount from './buildViolationsCount';

const earliestSeen = new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();

export default function useSchemaViolationsCount() {
  const { pipeline } = usePipelineCardContext();
  const [getPipelineSchemaViolations, { data: violations, error }] = useGetPipelineSchemaViolations();

  useEffect(() => {
    getPipelineSchemaViolations({
      earliestSeen,
      clusterId: pipeline.clusterId,
      writekey: pipeline.writekey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline.clusterId, pipeline.writekey]);

  return buildViolationsCount({ violations, error });
}
