import React, { useEffect, useState } from 'react';
import pt from 'prop-types';

import FeatureGate from 'app/launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

import { IdentitySyncFieldsTable, ConsentCategoryTable } from './index';
import { Typography } from '@metarouter-private/mr-mui';
import s from '../../index.module.scss';
import { MrIcon } from '@ion/components';

const GoogleTagParentBody = ({
  stagedSyncs,
  resetError,
  formState,
  setField,
  validateField,
  validateRequired,
  validateNumber,
  onClickSyncDelete,
}) => {
  const [parentFields, setParentFields] = useState([]);

  useEffect(() => {
    const uniqueFields = new Map();

    stagedSyncs.forEach(sync => {
      if (sync.parentFields) {
        sync.parentFields.forEach(field => {
          if (!uniqueFields.has(field.name)) {
            uniqueFields.set(field.name, field);
          }
        });
      }
    });

    setParentFields(Array.from(uniqueFields.values()));
  }, [stagedSyncs]);

  return (
    <div>
      <Typography className={s.borderBottom} variant="h4">
        Parent Fields
      </Typography>
      <IdentitySyncFieldsTable
        fields={parentFields}
        syncName="parentGTag"
        setField={setField}
        formState={formState}
        validateField={validateField}
        resetError={resetError}
        validateNumber={validateNumber}
        validateRequired={validateRequired}
      />
      <Typography className={s.borderBottom} variant="h4">
        Syncs
      </Typography>
      {stagedSyncs.map(sync => {
        return (
          <React.Fragment key={sync.name}>
            <div className={s.syncHeader}>
              <Typography sx={{ mt: '26px', mb: 2, pl: '10px' }} variant="h5">
                {sync.friendlyName}
              </Typography>
              <MrIcon
                className={s.rightIcon}
                id="icon-trash"
                onClick={() => {
                  onClickSyncDelete(sync);
                }}
                title={'delete sync'}
              />
            </div>
            <FeatureGate flagName={FEATURE_FLAGS.configurableConsentMvp}>
              <ConsentCategoryTable
                resetError={resetError}
                formState={formState}
                setField={setField}
                syncName={sync.name}
                validateField={validateField}
                validateRequired={validateRequired}
                validateNumber={validateNumber}
              />
            </FeatureGate>
            <IdentitySyncFieldsTable
              fields={sync.fields}
              syncName={sync.name}
              setField={setField}
              formState={formState}
              validateField={validateField}
              resetError={resetError}
              validateNumber={validateNumber}
              validateRequired={validateRequired}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

GoogleTagParentBody.propTypes = {
  stagedSyncs: pt.array,
  resetError: pt.func,
  formState: pt.object,
  setField: pt.func,
  syncName: pt.string,
  validateField: pt.func,
  validateRequired: pt.func,
  validateNumber: pt.func,
  onClickSyncDelete: pt.func,
};

export default GoogleTagParentBody;
