import React from 'react';

import { useQuerySchemaEnforcement } from '@ion/api';

import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

import SchemaEnforcementTableTitle from './SchemaEnforcementTableTitle';
import SchemaEnforcementTableActions from './SchemaEnforcementTableActions';
import buildSchemaEnforcementTableContents from './buildSchemaEnforcementTableContents';

export default function useSchemaEnforcementTable() {
  const { pipelineId, pipeline } = usePipelinePageContext();

  const { pipelineSchemaLibrary, violationsCount, loading } = useQuerySchemaEnforcement({
    pipelineId,
    clusterId: pipeline.cluster?.id,
    writekey: pipeline.writekey,
  });

  return {
    numberOfColumns: 3,
    data: buildSchemaEnforcementTableContents({ pipelineSchemaLibrary, violationsCount }),
    title: <SchemaEnforcementTableTitle />,
    actions: <SchemaEnforcementTableActions pipelineSchemaLibrary={pipelineSchemaLibrary} />,
    noDataComponent: '',
    loading,
  };
}
