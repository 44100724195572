import isEmpty from 'lodash/isEmpty';
import PIPELINE_STATUSES from 'api/pipelines/useQueryPipelineWithRoost/PIPELINE_STATUSES';

export default function determinePipelineIntegrationStatus({
  deployedIntegration,
  revisionHasChanges,
  pipelineVariablesHasChanges,
  consentCategoryHasChanges,
  stagedForDelete,
}) {
  const isChanged = revisionHasChanges || pipelineVariablesHasChanges || consentCategoryHasChanges || stagedForDelete;
  if (!isEmpty(deployedIntegration) && !isChanged) {
    return PIPELINE_STATUSES.Deployed;
  }

  return PIPELINE_STATUSES.StagedForDeploy;
}
