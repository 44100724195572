import { useEffect } from 'react';
import { useGetPipelineSchemaViolationsDetails } from '@ion/api';
import schemaViolationsDetailsColumns from './schemaViolationsDetailsColumns';
import { useEventSchemaViolationsPageContext } from 'app/event-schema-violations/EventSchemaViolationsPage/EventSchemaViolationsPageContextProvider';
import useOnRowClicked from './useOnRowClick';

const STATIC_PROPS = {
  pagination: true,
  columns: schemaViolationsDetailsColumns,
  highlightOnHover: true,
  pointerOnHover: true,
};

export default function useSchemaViolationsDetailsTable() {
  const { pipeline, eventName, schemaId, earliestSeen } = useEventSchemaViolationsPageContext();
  const [getPipelineSchemaViolationsDetails, { loading, data }] = useGetPipelineSchemaViolationsDetails();
  const onRowClicked = useOnRowClicked();

  useEffect(() => {
    getPipelineSchemaViolationsDetails({
      earliestSeen,
      eventName,
      schemaId,
      clusterId: pipeline.clusterId,
      writekey: pipeline.writekey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipeline.clusterId, pipeline.writekey, schemaId, eventName]);

  return {
    ...STATIC_PROPS,
    onRowClicked,
    loading,
    data,
  };
}
