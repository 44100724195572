import React from 'react';

import { usePipelinePageContext } from 'app/pipeline-details/PipelinePageContextProvider';

import buildStatusTableContents from './buildStatusTableContents';
import StatusTableTitle from './StatusTableTitle';
import StatusChip from './StatusChip';

export default function useStatusTable() {
  const {
    pipeline: { writekey, lastDeployed, status, cluster },
  } = usePipelinePageContext();

  return {
    numberOfColumns: 2,
    data: buildStatusTableContents({ lastDeployed, cluster }),
    title: <StatusTableTitle writekey={writekey} />,
    actions: <StatusChip status={status} />,
  };
}
