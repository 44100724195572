/* eslint-disable filename-export/match-default-export */
/* disabling rule until refactor of analytics builder */

import React, { useState } from 'react';
import cx from 'classnames';
import pt from 'prop-types';
import { useHistory } from 'react-router-dom';

import { MrDate, MrText, MrButton, MrDropdown, MrEditField, MrCopy, MrIcon } from '@ion/components';
import { useRemovePipeline, useUpdatePipeline } from '@ion/api';

import { routes } from 'app/constants';
import ModalContextProvider from 'app/pipelines/ModalContext';
import ConfirmDeleteModal from 'app/pipelines/modals/ConfirmDeleteModal';

import PipelineVarsModal from 'app/pipeline-details/PipelineVarsModal';
import RemoveDeployedIntegrationsModal from 'app/pipeline-details/RemoveDeployedIntegrationsModal';
import determineDeployAction from 'app/pipeline-details/PipelineIntegrationsTable/DeployChangesButton/determineDeployAction';

import s from './header.module.scss';

import arrow from './assets/icon-chevron.svg';

const PipelineCardHeader = ({
  writekey,
  pipeline,
  name,
  isOpen,
  changeOpen,
  lastDeployedDate,
  deployStatus,
  cluster,
  pipelineVariablesModalOpen,
  setPipelineVariablesModalOpen,
  setDeployedRevisions,
  openPipelineDiffModal,
}) => {
  const history = useHistory();
  const [updatePipelineName] = useUpdatePipeline();
  const [deletePipelineModalOpen, setDeletePipelineModalOpen] = useState(false);
  const [removeDeployedIntegrationsModalOpen, setRemoveDeployedIntegrationsModalOpen] = useState(false);
  const [, { isAllowed: deletePipelinePermission }] = useRemovePipeline();

  return (
    <header className={s.header} data-test-id={pipeline.writekey}>
      <div className={s.pipelineDetails}>
        <MrEditField
          className={s.editPipelineName}
          text={name}
          onSubmit={name => {
            updatePipelineName({ pipelineId: pipeline.id, name });
          }}
          inputProps={{ placeholder: 'pipeline name' }}
        >
          <div className={s.pipelineName}>{name}</div>
        </MrEditField>

        <ul className={s.pipelineMeta}>
          <li>
            <MrText styleNames="smallGray" className={s.metaText}>
              Last Deploy: <MrDate date={lastDeployedDate || 'Not Deployed'} format="MM/dd/yy" />
            </MrText>
          </li>
          <li>
            <MrText styleNames="smallGray" className={s.metaText}>
              Health:
              <span className={cx(s.circle, s[deployStatus.healthClass])}></span>
              <span className={s.healthText}>{deployStatus.healthText}</span>
            </MrText>
          </li>
          <li>
            <MrText styleNames="smallGray" className={s.metaText}>
              WriteKey:{' '}
              <span className={s.writekey}>
                {writekey} <MrCopy text={writekey} />
              </span>
            </MrText>
          </li>
        </ul>
      </div>

      <div className={s.pipelineControls}>
        {deletePipelinePermission &&
          !(deployStatus?.disabled === true && deployStatus.buttonText === 'Deploy Pipeline') && (
            <MrButton
              text={deployStatus?.buttonText}
              onClick={e => {
                e.preventDefault();
                determineDeployAction({
                  deployStatus,
                  setPipelineVariablesModalOpen,
                  setRemoveDeployedIntegrationsModalOpen,
                  openPipelineDiffModal,
                });
              }}
              tooltip={deployStatus?.buttonTooltip}
              className={s.deployButton}
              styleNames={deployStatus?.styleName}
              startIcon={deployStatus.action === 'resolve' ? null : <MrIcon id="icon-deploy" />}
              disabled={deployStatus.buttonText === 'Deployed' || deployStatus.buttonText === 'Loading...'}
            />
          )}

        <MrDropdown className={s.dropdown} testId="pipelineDropdown">
          <ul>
            {deletePipelinePermission && (
              <li
                onClick={() => {
                  setDeletePipelineModalOpen(true);
                }}
              >
                Delete
              </li>
            )}
            <li
              onClick={() => {
                history.push(routes.analyticsJSBuilder, {
                  pipelineId: pipeline.id,
                  writekey: pipeline.writekey,
                  name: pipeline.name,
                  clusterId: cluster.id,
                  clusterName: cluster.name,
                });
              }}
            >
              Build AJS File
            </li>
          </ul>
        </MrDropdown>

        <div className={s.rightPercent}>
          <img
            src={arrow}
            className={cx(s.toggle, isOpen && s.open)}
            title="View Details"
            onClick={() => {
              changeOpen(!isOpen);
            }}
          />
        </div>

        <ModalContextProvider>
          <ConfirmDeleteModal
            pipelineDeprecated={pipeline}
            openDeprecated={deletePipelineModalOpen}
            onCloseDeprecated={() => {
              setDeletePipelineModalOpen(false);
            }}
          />
        </ModalContextProvider>
        {pipelineVariablesModalOpen && (
          <PipelineVarsModal pipeline={pipeline} setPipelineVariablesModalOpen={setPipelineVariablesModalOpen} />
        )}

        {removeDeployedIntegrationsModalOpen && (
          <RemoveDeployedIntegrationsModal
            setRemoveDeployedIntegrationsModalOpen={setRemoveDeployedIntegrationsModalOpen}
            setDeployedRevisions={setDeployedRevisions}
          />
        )}
      </div>
    </header>
  );
};

PipelineCardHeader.propTypes = {
  writekey: pt.string.isRequired,
  pipeline: pt.object.isRequired,
  name: pt.string.isRequired,
  isOpen: pt.bool,
  changeOpen: pt.func,
  lastDeployedDate: pt.string,
  deployStatus: pt.object,
  cluster: pt.object,
  pipelineVariablesModalOpen: pt.bool,
  setPipelineVariablesModalOpen: pt.func,
  revisionToEdit: pt.string,
  setRevisionToEdit: pt.func,
  setDeployedRevisions: pt.func.isRequired,
  openPipelineDiffModal: pt.func.isRequired,
};

export default PipelineCardHeader;
