import isAllowedToRemovePipeline from '@ion/api/pipelines/remove/isAllowedToRemovePipeline';
import { DEPLOY_STATE } from './DEPLOY_STATE';

export default function determineDisabled({ deployStatus }) {
  const isAllowed = isAllowedToRemovePipeline();

  return (
    !isAllowed || deployStatus.buttonText === DEPLOY_STATE.DEPLOYED || deployStatus.buttonText === DEPLOY_STATE.LOADING
  );
}
