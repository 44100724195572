import React from 'react';
import PropTypes from 'prop-types';

import { MrModal, MrButton, MrLink, MrButtonList } from '@ion/components';

export default function ConfirmDelete({ children, closeModal, deleteFn, disableDelete, buttonClassName }) {
  return (
    <MrModal closeModal={closeModal}>
      <>
        {children}
        <MrButtonList
          styleNames="paddingModal"
          buttons={[
            <MrButton
              disabled={disableDelete}
              text="Delete"
              type="button"
              styleNames="red"
              className={buttonClassName}
              onClick={() => {
                closeModal();
                deleteFn();
              }}
              key="delete"
              testId="ConfirmDelete"
            />,
            <MrLink onClick={closeModal} styleNames="gray" key="cancel">
              Cancel
            </MrLink>,
          ]}
        />
      </>
    </MrModal>
  );
}

ConfirmDelete.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteFn: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool,
  buttonClassName: PropTypes.string,
};
