import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from 'app/constants';

import { useLazyQueryPipelines } from '@ion/api';

const PIPELINES_PER_PAGE = 20;
const DEFAULT_SORT = {
  field: 'name',
  direction: 'asc',
};

export default function usePipelinesTableContextProps() {
  const history = useHistory();
  const [fetchPipelines, { pipelines, pagination, loading }] = useLazyQueryPipelines();
  const [activePage, setActivePage] = useState({ page: 1, perPage: PIPELINES_PER_PAGE });
  const [selectedClusters, setSelectedClusters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [resetPagination, setResetPagination] = useState(false);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const onRowClicked = row => {
    history.push({
      pathname: routes.pipelineDetails,
      state: {
        pipelineId: row.id,
      },
    });
  };

  const updatePipelines = () => {
    fetchPipelines({
      page: activePage.page,
      perPage: activePage.perPage,
      clusterIds: selectedClusters,
      searchTerm,
      sort,
    });
  };

  useEffect(() => {
    updatePipelines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    if (activePage.page !== 1) {
      setResetPagination(!resetPagination);
    } else {
      updatePipelines();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClusters, searchTerm, sort]);

  return {
    pipelines,
    pagination,
    perPage: PIPELINES_PER_PAGE,
    loading,
    resetPagination,
    onRowClicked,
    setActivePage,
    setSelectedClusters,
    setSearchTerm,
    setSort,
  };
}
