import React from 'react';

import { MultiSelect } from '@metarouter-private/mr-mui';

import useGetClusterOptions from 'app/components/cluster-select/useGetClusterOptions';
import { usePipelinesTableContext } from 'app/pipelines/PipelinesTable/PipelinesTableContext';

export default function ClusterMultiSelect() {
  const { setSelectedClusters } = usePipelinesTableContext();
  const clusterOptions = useGetClusterOptions();

  return (
    <MultiSelect
      size="small"
      variant="outlined"
      options={clusterOptions}
      label="Cluster"
      onChange={setSelectedClusters}
      renderValue={selected => `Clusters (${selected.length})`}
    />
  );
}
