import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext, FormTextField } from '@metarouter-private/mr-mui';

import alphanumeric from 'app/shared/form/validation/alphanumeric';
import snakeCase from 'app/shared/form/validation/snakeCase';

export default function EventNameField({ name, label = 'Event Name', defaultValue, customValidateFn }) {
  const formTextFieldProps = {
    name,
    label,
    defaultValue,
    required: true,
    rules: {
      validate: {
        alphanumeric,
        snakeCase,
        ...(customValidateFn && { customValidateFn }),
      },
    },
  };

  return (
    <FormFieldContext {...formTextFieldProps}>
      <FormTextField />
    </FormFieldContext>
  );
}

EventNameField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  customValidateFn: PropTypes.func,
};
