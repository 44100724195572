import { useDeploySchemasToPipeline } from '@ion/api';

import { useSchemaEnforcementTableContext } from 'app/pipeline-details/SchemaEnforcementTableContextProvider';

export default function useDeploySchemaLibrary({ pipelineSchemaLibraryId, onClose }) {
  const { pipeline } = useSchemaEnforcementTableContext();
  const [deploySchemas] = useDeploySchemasToPipeline({ pipelineSchemaLibraryId, pipeline, onCompleted: onClose });

  return deploySchemas;
}
