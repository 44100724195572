import { useFetchSchemaNames } from 'api';

export default function useValidateUniqueName(defaultValue, disabled) {
  const getSchemaNames = useFetchSchemaNames();

  async function validateUniqueName(name) {
    const shouldSkipValidation = name === defaultValue || disabled;
    if (shouldSkipValidation) {
      return true;
    }

    try {
      const { data, error } = await getSchemaNames();

      if (error) {
        return 'Unable to validate at the moment';
      }

      if (data) {
        return data.includes(name) ? 'This schema name is already in use' : true;
      }

      return true; // Valid name
    } catch {
      return 'Unable to validate at the moment';
    }
  }

  return validateUniqueName;
}
