/* eslint-disable filename-export/match-default-export */
/* disabling rule until refactor of analytics builder */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { MrAppLayout } from '@ion/components';

import SideNav from 'app/components/side-nav';
import AnalyticsBuilder from 'app/components/analytics-builder';
import { StagedIdentitySyncsProvider } from 'app/components/analytics-builder/useStagedIdentitySyncs';

import { ConsentCategoriesContext } from 'app/context/consent-categories-context';

export default function AnalyticsJSBuilderPage({ location }) {
  const stateData = location.state;
  if (stateData === undefined) {
    return <Redirect to="/pipelines" />;
  }

  const { name, writekey, clusterName } = stateData;

  const title = `Pipelines`;
  const desc = 'Analytics.js Builder';

  return (
    <MrAppLayout title={title} description={desc} icon="pipelines" sideNav={<SideNav />}>
      <ConsentCategoriesContext>
        <StagedIdentitySyncsProvider>
          <AnalyticsBuilder name={name} writekey={writekey} clusterName={clusterName} />
        </StagedIdentitySyncsProvider>
      </ConsentCategoriesContext>
    </MrAppLayout>
  );
}

AnalyticsJSBuilderPage.propTypes = {
  location: PropTypes.object,
};
