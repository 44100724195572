import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import EditAction from 'app/pipeline-details/SchemaEnforcementTable/ActionCell/EditAction';
import DeleteAction from 'app/pipeline-details/SchemaEnforcementTable/ActionCell/DeleteAction';

const STACK_PROPS = {
  alignItems: 'center',
  direction: 'row',
};

export default function ActionsCell({ row }) {
  return (
    <Stack {...STACK_PROPS}>
      <EditAction row={row} />
      <DeleteAction row={row} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    schemaLibraryId: PropTypes.string.isRequired,
    schemaLibraryVersionId: PropTypes.string.isRequired,
  }).isRequired,
};
