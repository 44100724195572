import React from 'react';
import { isNil } from 'lodash';

import { MrModal } from '@ion/components';

import { usePipelineIntegrationModalContext } from 'app/pipeline-details/PipelineIntegrationsTable/context/pipeline-integration-modal-context';

import { AddIntegration } from './options/add-integration/AddIntegration';
import { EditIntegration } from './options/edit-integration/EditIntegration';

export const PIPELINE_MODAL_OPTIONS = {
  addIntegration: 'addIntegration',
  editIntegration: 'editIntegration',
};
export const PipelineModal = () => {
  const { isOpenModal, onCloseModal, modalContentOption } = usePipelineIntegrationModalContext();
  if (isNil(modalContentOption)) {
    return null;
  }

  const renderContent = () => {
    switch (modalContentOption) {
      case PIPELINE_MODAL_OPTIONS.addIntegration:
        return <AddIntegration />;
      case PIPELINE_MODAL_OPTIONS.editIntegration:
        return <EditIntegration />;
      default:
        throw new Error('Invalid modal option');
    }
  };

  return (
    <>
      {isOpenModal && (
        <MrModal styleNames="allowOverflow" closeModal={onCloseModal}>
          {renderContent()}
        </MrModal>
      )}
    </>
  );
};
