import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_DATA_PIPELINE_INTEGRATION_MODAL_CONTEXT = {
  selectedIntegration: null,
  isOpenModal: false,
  modalContentOption: null,
  onOpenModal: () => undefined,
  onCloseModal: () => undefined,
};
const PipelineIntegrationModalContext = createContext(DEFAULT_DATA_PIPELINE_INTEGRATION_MODAL_CONTEXT);

export const PipelineIntegrationModalProvider = ({ children }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContentOption, setModalContentOption] = useState(null);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const onOpenModal = (modalOption, integration) => {
    setIsOpenModal(true);
    setModalContentOption(modalOption);
    setSelectedIntegration(integration);
  };
  const onCloseModal = () => {
    setIsOpenModal(false);
    setModalContentOption(null);
    setSelectedIntegration(null);
  };
  return (
    <PipelineIntegrationModalContext.Provider
      value={{
        selectedIntegration,
        isOpenModal,
        modalContentOption,
        onOpenModal,
        onCloseModal,
      }}
    >
      {children}
    </PipelineIntegrationModalContext.Provider>
  );
};

export const usePipelineIntegrationModalContext = () => {
  return useContext(PipelineIntegrationModalContext);
};
PipelineIntegrationModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
