import useMergePipelineIntegrations from './useMergePipelineIntegrations';
import determinePipelineStatus from './determinePipelineStatus';

export default function useNewPipelineDataModel({ pipeline, deployedPipeline }) {
  const pipelineIntegrations = useMergePipelineIntegrations({
    pipelineIntegrations: pipeline?.pipelineIntegrations,
    deployedIntegrations: deployedPipeline?.integrations,
  });

  return {
    id: pipeline?.id,
    name: pipeline?.id,
    writekey: pipeline?.writekey,
    cluster: pipeline?.cluster,
    lastDeployed: deployedPipeline?.createdAt,
    status: determinePipelineStatus({ pipelineIntegrations }),
    pipelineIntegrations,
    filter: {
      local: {},
      deployed: deployedPipeline?.filter,
    },
    transform: {
      local: {},
      deployed: deployedPipeline?.transform,
    },
  };
}
